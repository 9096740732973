import { Component } from '@angular/core';
import {CurrentProductService} from "libs/shared-services/src/lib/current-product.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "libs/shared-services/src/lib/auth.service";
import {Product} from "libs/shared-models/src/lib/product-type";
import {environment} from "../../../environments/environment";
import { Constants } from "libs/shared-models/src/lib/utils/constants";

@Component({
  selector: 'web-foodis-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent {

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private currentProductService: CurrentProductService
    ) {}

    public ngOnInit() {

        this.route.queryParamMap.subscribe(params => {
            if (this.authService.isLoggedIn()) {
                this.router.navigateByUrl('dashboard');
            } {
                // setting the product on cookie, so then account knows the initial product
                this.currentProductService.setProduct(Product.USER_APP);

                const redirectFlow = params.get(Constants.QUERY_PARAM_REDIRECT_FLOW);
                const redirectUrl = params.get(Constants.QUERY_PARAM_REDIRECT_URL);
                if (redirectFlow || redirectUrl) {
                    if (redirectFlow) {
                        window.location.href = environment.PRODUCT_URLS.ACCOUNT + '/login' + "?" + Constants.QUERY_PARAM_REDIRECT_FLOW + "=" + redirectFlow;
                    } else if (redirectUrl) {
                        window.location.href = environment.PRODUCT_URLS.ACCOUNT + '/login' + "?" + Constants.QUERY_PARAM_REDIRECT_URL + "=" + redirectUrl;
                    }
                    
                } else {
                    window.location.href = environment.PRODUCT_URLS.ACCOUNT + '/login';
                }
            }
        });
    }
}
