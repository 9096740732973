<div class="main-container">

    <div class="title-container">
        {{ 'order_page_orders_link' | translate }}:
    </div>
    
    <!-- Entire list -->
    <ng-container *ngIf="(((ordersList$ | async)?.results?.length || 0) > 0) || (loadingApiList$ | async); else noOrders">

        <!-- Spinning loader until API responds -->
        <ng-container *ngIf="(loadingApiList$ | async)">
            <div class="loading-api-spinner">
                <fs-loading-spinner [variant]="LOADING_STYLE.CIRCLE_GRAY"></fs-loading-spinner>
            </div>
        </ng-container>

        <!-- The list of all oders-->
        <div class="orders-list">

            <!-- Each item -->
            <ng-container *ngFor="let order of (ordersList$ | async)?.results">
                <a class="order-href" [href]="'/user/order/' + order.id"> 
                    <div class="order-item">

                        <!-- Image -->
                        <div class="logo-main-container">
                            <div class="logo-image-container">
                                <!-- Image -->
                                <div class="logo-image-default">
                                    <div class="logo-icon-default">
                                        <img [src]="order?.restaurantLogoImageUrl || 'apps/user-app/src/assets/add-image-default.svg'"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <!-- Content -->
                        <div class="item-text-content">
                            
                            <!-- Content bottom -->
                            <div class="item-content-mid">
                                <div class="item-restaurant-name">
                                    {{ order.restaurantName }}
                                </div>
                            
                                <div class="item-products">
                                    <ng-container *ngFor="let product of order.items; index as productIndex">
                                        {{ product.productName }}{{ productIndex === (order.items.length - 1)  ? "" : ","  }}
                                    </ng-container>
                                </div>
                            
                            </div>
        
                            <!-- Content bottom -->
                            <div class="item-content-right">
                                
                                <div class="item-order-delivery-date">
                                    <span class="item-delivery-type">
                                        <img [src]="'apps/user-app/src/assets/' + ((order.deliveryOption === DeliveryOption.DELIVERY) ? 'delivery-type-bicycle-selected' : 'delivery-type-pick-up-hand-selected')  + '.svg'"/>
                                    </span>
                                    <span class="item-date">{{ order.createdDate | date:"dd.MMM, hh:mm"}} </span> 
                                </div>
                                <div class="item-order-slug">
                                    #{{ order.slug }}
                                </div>
                                <div class="item-price">
                                    {{ order.totalPriceCents | format_cents: true }}
                                </div>
                            </div>
                        </div>
                        

                    
                    </div>
                </a>
            </ng-container>
        </div>

    </ng-container>



    <!-- User has no orders -->
    <ng-template #noOrders>
        
        <div class="missing-orders-container">
            <!-- Image -->
            <div class="missing-orders-image">
                <img [src]="'apps/user-app/src/assets/no-orders-found.jpg'"/>
            </div>

            <!-- Texts -->
            <div class="missing-orders-text-container">
                <!-- Title -->
                <div class="missing-orders-title">
                    <!-- Image (mobile) -->
                    <div class="missing-orders-image-mobile">
                        <img [src]="'apps/user-app/src/assets/no-orders-found.jpg'"/>
                    </div>
                    <div class="mr-2">{{ "orders_list_no_orders_title" |  translate }}</div>
                    <mat-icon>history</mat-icon>
                </div>
                <!-- Body -->
                <div class="missing-orders-body">
                    {{ "orders_list_no_orders_body" |  translate }}
                </div>
                <!-- Button -->
                <div class="missing-orders-button">
                    <!-- Button - Check restaurants -->
                    <fs-button [text]="'orders_list_no_orders_button_check' | translate"
                            class="button-container"
                            [type]="'raised-primary'" [leadingIcon]="'search'" (onPress)="onPressCheckRestaurants()">
                    </fs-button>
                    <!-- Button - See Order Basket -->
                     <ng-container *ngIf="((basketItemsCount$ | async) || 0) > 0">
                        <fs-button [text]="'orders_list_no_orders_button_basket' | translate"
                            class="button-container"
                            [type]="'raised-primary'" [leadingIcon]="'fastfood'" (onPress)="onPressSeeOrderBasket()">
                        </fs-button>
                     </ng-container>
                
                </div>
            </div>
        </div>
    </ng-template>
    
</div>