import {Injectable} from '@angular/core';
import {BehaviorSubject, find, map, Observable} from "rxjs";
import {AddressResponse} from "libs/shared-models/src/lib/address-response";

@Injectable({
    providedIn: 'root'
})
export class UserAddressStateService {

    private addressList$: BehaviorSubject<AddressResponse[]> = new BehaviorSubject<AddressResponse[]>([]);

    constructor(
    ) {
    }

    public getAddressList$(): Observable<AddressResponse[]> {
        return this.addressList$.asObservable();
    }

    public getAddressList(): AddressResponse[] {
        return this.addressList$.getValue();
    }

    public setAddressList(list: AddressResponse[]): void {
        this.addressList$.next([...list]); // immutable
    }

    public getCurrentAddress$(): Observable<AddressResponse> {
        return this.getAddressList$().pipe(map((list) => list.find((item) => item.getIsDefault()) || new AddressResponse(true)))
    }

    public getCurrentAddress(): AddressResponse {
        return this.getAddressList().find((item) => item.getIsDefault()) || new AddressResponse(true);
    }

    public upsertAddress(a: AddressResponse): void {
        let currentList = [...this.getAddressList()];

        // No existing elements
        if (currentList.length === 0) {
            currentList = [...currentList, a];
            this.setAddressList(currentList);
            return;
        }

        let index = currentList.findIndex((item: AddressResponse) => {
            return item.getId() === a.getId()
        });
        // New item
        if (-1 === index) {
            currentList = [...currentList, a];
        }
        // Existing item which needs to be replaced:
        else {
            currentList = [...currentList.slice(0, index), a, ...currentList.slice(index + 1)];
        }
        this.setAddressList(currentList);
    }


    // Sets the current address
    public setCurrentAddress(a: AddressResponse): void {

        // Create immutable instance + set it as default address
        let newAddress = Object.assign(new AddressResponse(), a);
        newAddress.setIsDefault(true);

        // Set all the other addresses as default false
        let tempList = [...this.getAddressList()];
        tempList = tempList.map((item) => {
            item.setIsDefault(newAddress.getId() === item.getId())
            return item;
        })

        // Update all addresses list
        this.setAddressList(tempList);
    }

    public deleteAddress(a: AddressResponse): void {
        let tempList = [...this.getAddressList()];
        tempList = tempList.filter((item) => item.getId() !== a.getId());
        if (a.getIsDefault() && tempList.length > 0) {
            tempList[0].setIsDefault(true); // as agreed with BE, we update this on FE - if the user removes the default address, the first one in the list becomes default
        }
        this.setAddressList(tempList);
    }

    // Used on Logout
    public clearData(): void {
        this.setAddressList([]);
    }
}

