import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { PublicRestaurantResponse } from 'apps/user-app/src/app/services/full-restaurant-page/model/public-restaurant-response';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { BusinessHoursNameEnum } from 'libs/shared-models/src/lib/restaurant/business-hours';
import { BusinessHoursHelper } from 'libs/shared-models/src/lib/utils/business-hours-helper';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';

@Component({
  selector: 'web-foodis-more-restaurant-info',
  templateUrl: './more-restaurant-info.component.html',
  styleUrl: './more-restaurant-info.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class MoreRestaurantInfoModalComponent implements OnInit, AfterViewInit {

  @ViewChild('modalContent', { static: false }) modalContent: ElementRef | undefined;

  public tempData$: BehaviorSubject<PublicRestaurantResponse> = new BehaviorSubject<PublicRestaurantResponse>(new PublicRestaurantResponse());

  public mapWidthResize$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public hoursPanelOpenState = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PublicRestaurantResponse,
    private dialogRef: MatDialogRef<MoreRestaurantInfoModalComponent>,
    private toasterService: ToasterService,
    private localeService: LocaleService
  ) {

  }

  public ngOnInit() {

    // Assign the data received on Modal creation
    const newData = Object.assign(new PublicRestaurantResponse(), this.getDialogData()); // new instance
    newData.products.categories = this.getDialogData().products.categories.map((item) => {
      return {...item}
    })
    newData.addonGroups = this.getDialogData().addonGroups.map((item) => {
      return {...item}
    })
    this.tempData$.next(Object.assign(new PublicRestaurantResponse(), newData));

    // Listen to UI modal backdrop:
    this.dialogRef.backdropClick().subscribe(() => {
      this.onCancel();
    });
  }

  public ngAfterViewInit(): void {
      
    // Listen to popup resize (so it resizes the google map)
    this.listenToResize();
  }


  private getData(): PublicRestaurantResponse {
    return this.tempData$.getValue();
  }

  private setData(val: PublicRestaurantResponse) {
      this.tempData$.next(val);
  }

  // The data added on Modal creation (injected in the dialog from parent call)
  private getDialogData(): PublicRestaurantResponse {
    return this.data;
  }

  // Popup cancel
  public onCancel() {
    this.dialogRef.close();
  }

  public listenToResize() {
    const resize_ob = new ResizeObserver( (entries) => {
      // since we are observing only a single element, so we access the first element in entries array
      const rect = entries[0].contentRect;
    
      // current width & height
      const width = rect.width;
      const height = rect.height;

      this.mapWidthResize$.next(width);
    });
    
    // start observing for resize
    if (this.modalContent?.nativeElement) {
      resize_ob.observe(this.modalContent.nativeElement); // Todo some unsubscribe? unobserve
    }
  }


  /*
    Copy to clipboard
  */
  public onCopyClick() {
    const address = this.tempData$.getValue().restaurantInfo.address;
    const coppiedAddress = address.streetName + " " + address.streetNumber + ", " + address.district + " " + address.postalCode + ", " + address.city;
    // Copy the text inside the text field
    navigator.clipboard.writeText(coppiedAddress);

    this.toasterService.showInfo("",this.localeService.translate("restaurant_page_info_address_copy"));
  }

  /*
    Open External Google maps
  */
  public onExternalMapsClick() {
    const data = this.tempData$.getValue();
    const address = data.restaurantInfo.address;
    const url = "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(address.latitude + "," + address.longitude); // + data.restaurantInfo.name
    window.open(url, '_blank');
  }


  public getHours(): Observable<any[]> {
    return this.tempData$.pipe(map((data) => {
      return this.getRestructuredHours(data);
    }));
  }

  private getRestructuredHours(data: PublicRestaurantResponse) {
    const pickUpHours = data.restaurantInfo.businessHoursPickup;
    const deliveryHours = data.restaurantInfo.businessHoursDelivery;
    const days = [BusinessHoursNameEnum.MONDAY, BusinessHoursNameEnum.TUESDAY, BusinessHoursNameEnum.WEDNESDAY, BusinessHoursNameEnum.THURSDAY, BusinessHoursNameEnum.FRIDAY, BusinessHoursNameEnum.SATURDAY, BusinessHoursNameEnum.SUNDAY];
    const dataToReturn = days.map((item) => {
      return {
        day: item,
        pickupHours: (pickUpHours as any)[item],
        deliveryHours: (deliveryHours as any)[item],
      }
    })
    return dataToReturn;
  }

  public getToday(): any {
    const list = this.getRestructuredHours(this.getData());    
    return list.find((item) => BusinessHoursHelper.isToday(item.day));
  }
}