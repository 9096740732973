<div class="all-items">
    <ng-container *ngFor="let item of items; index as i">
        <div *ngIf="item.required" class="item-container"
            [ngClass]="{
                'last' : i === ((items?.length || 0) - 1)
            }">
            <!-- Left side Progress icon -->
            <div class="progress-icon-container">
                <div class="icon">
                    <img class="icon-src" 
                            [src]="'apps/user-app/src/assets/radio-timeline-' + item.status + '.png'"/>
                </div>
            </div>

            <!-- Right side Text state-->
            <div class="progress-text-container" 
                [ngClass]="{
                    'completed' : item.status === VerticalStatusItemState.COMPLETED,
                    'empty' : item.status === VerticalStatusItemState.EMPTY,
                    'progress' : item.status === VerticalStatusItemState.IN_PROGRESS
                }">
                {{ (item.status === VerticalStatusItemState.COMPLETED 
                    ? item.translationKeyCompleted 
                    : item.translationKeyDefault) 
                | translate }} 
            </div>
        </div>
    </ng-container>

    <div class="vertical-line">
    </div>
</div>
