import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { OrderCreateRequest, OrderLineItemCreateRequest } from "./order-create-request";

/*
  Similar to the request one, only with few additional attributes (id, status)
*/
export class OrderResponse extends OrderCreateRequest {
  id: string = "";
  status: OrderStatus;
  slug: string = "";
  restaurantAddress: AddressResponse = new AddressResponse();
  override items: OrderLineItemResponse[] = [];
}

/*
  This one is the same as the created request one
*/
export class OrderLineItemResponse extends OrderLineItemCreateRequest{
}

/*
  Used in the orders list page / paginated response
*/
export class OrderListResponse {
  results: OrderResponse[] = [];
  pageNumber: number = 0;
  pageSize: number = 0;
  pageTotal: number = 0;
  totalItems: number = 0;
}


export enum OrderStatus {
  CREATED = "CREATED",
  PAID = "PAID",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  ACCEPTED_BY_RESTAURANT = "ACCEPTED_BY_RESTAURANT",
  DECLINED_BY_RESTAURANT = "DECLINED_BY_RESTAURANT",
  IN_DELIVERY = "IN_DELIVERY",
  DELIVERED = "DELIVERED"
}
