import {AddonResponse} from "./addon-response";

export class AddonGroupResponse {
    id: string = "";
    name: string = "";
    nameEn: string = "";
    minSelection: number = 0;
    maxSelection: number = 1;
    addonList: AddonResponse[] = [];
}
