import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TopNavBarService } from '../../services/navbar/top-navbar.service';
import { environment } from 'apps/user-app/src/environments/environment';
import { UserAddressFacade } from '../../services/user-address/user-address.facade.service';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'web-foodis-address-page',
  templateUrl: './address-page.component.html',
  styleUrl: './address-page.component.scss',
})
export class AddressPageComponent implements OnInit {

  public currentAddress$: Observable<AddressResponse> = this.userAddressFacade.getCurrentAddress$();
  public allAddresses$: Observable<AddressResponse[]> = this.userAddressFacade.getAddresses$();

  constructor(
    private topNavBarService: TopNavBarService,
    private userAddressFacade: UserAddressFacade
  ) {

  }

  public ngOnInit(): void {

  }

  public onPressMissingAddress() {
    this.topNavBarService.setForceAddressOpen(true);
  }
}
