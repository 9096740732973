import { Component, OnInit } from '@angular/core';
import { OrderBasketService } from '../../services/order-basket/order-basket.service';
import { Observable } from 'rxjs';
import { TopNavBarService } from '../../services/navbar/top-navbar.service';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'order-basket-bottom-button',
  templateUrl: './bottom-basket-button.component.html',
  styleUrl: './bottom-basket-button.component.scss',
})

export class BottomBasketButtonComponent implements OnInit {
  public orderBasketData$ = this.orderBasketService.getState$();
  public basketItemsCount$ = this.orderBasketService.basketItemsCount$.asObservable();
  public isOrderBasketOpenInPopup$: Observable<boolean> = this.topNavBarService.isOrderBasketOpenInPopup$.asObservable();
  public hideByRoute: boolean = false;

  public constructor(
    private orderBasketService: OrderBasketService,
    private topNavBarService: TopNavBarService,
    private router: Router
  ) {

  }

  public ngOnInit(): void {    
    this.checkRoute(this.router.url);
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkRoute(event.url);
      }
    });
  }

  private checkRoute(url: string) { 
    this.hideByRoute = url.includes("/checkout");
  }

  public onOpenBasketPress() {
    this.topNavBarService.openOrderBasketInPopup();
  }
}
