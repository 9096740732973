import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { BusinessHours } from "libs/shared-models/src/lib/restaurant/business-hours";
import { DeliveryTimeRangeEnum } from "libs/shared-models/src/lib/restaurant/delivery-time-range-enum";
import { RestaurantCategoryEnum } from "libs/shared-models/src/lib/restaurant/restaurant-category-enum";
import { BusinessHoursHelper } from "libs/shared-models/src/lib/utils/business-hours-helper";

export class PublicRestaurantInfo {
    id: string = "";
    name: string = "";
    slug: string = "";
    description: string = "";
    email: string = "";
    phoneNumber: string = "";
    website: string = "";
    companyName: string = "";
    vatNumber: string = "";
    address: AddressResponse = new AddressResponse();
    categoryList: RestaurantCategoryEnum[] = [];
    hasPickup: boolean = false;
    hasOwnDelivery: boolean = false;
    hasFoodisDelivery: boolean = false;
    minOrderAmount: number = 0;
    deliveryRadiusMeters: number = 0;
    deliveryTimeRange: DeliveryTimeRangeEnum;
    businessHoursPickup: BusinessHours = new BusinessHours();
    businessHoursDelivery: BusinessHours = new BusinessHours();
    closed: boolean = true;
    mainImageUrl: string = "";
    logoImageUrl: string = "";
    timeZone: string = BusinessHoursHelper.DEFAULT_TIMEZONE_VIENNA;
}