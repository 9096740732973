<div class="top-and-middle-container">
    <div class="modal-top-bar">

        <!-- Product image -->
        <ng-container *ngIf="!!(tempData$ | async)?.product?.imageUrl">
            <div class="product-modal-image-container">
                <img class="product-modal-image-src" [src]="(tempData$ | async)?.product?.imageUrl">
            </div>    
        </ng-container>
    
        <!-- Cancel button -->
        <div class="modal-top-right-action" >
            <div class="action-item" (click)="onCancel()">
                <img class="top-right-img-src" [src]="'apps/user-app/src/assets/close-round.svg'"/>
            </div>
        </div>

        <!-- Open externally as link -->
        <a [href]="(tempData$ | async)?.product?.imageUrl || '#'" target="_blank" class="external-link-container">
            {{ 'product_details_modal_image_link' | translate }}
            <img class="pl-1" [src]="'apps/user-app/src/assets/external-link.svg'"/>
          </a>
    </div>
    
    <div class="modal-content" #modalContent>
        <!-- Title -->
        <div class="product-modal-title">
            <!-- Fallback is English in case the user has any other language than DE. But if restaurant has no English added, still go on default DE.-->
            {{ ((useGermanDescriptions$ | async) && !!(tempData$ | async)?.product?.name || !(tempData$ | async)?.product?.nameEn) ? 
                (tempData$ | async)?.product?.name : 
                (tempData$ | async)?.product?.nameEn }}
        </div>
    
        <!-- Description -->
        <div class="product-modal-description">
            {{ ((useGermanDescriptions$ | async) && !!(tempData$ | async)?.product?.description || !(tempData$ | async)?.product?.descriptionEn) ? 
                (tempData$ | async)?.product?.description : 
                (tempData$ | async)?.product?.descriptionEn }}
        </div>
    
        <!-- Price & Allergen-->
        <div class="container-price-allergen">
            <!-- Price -->
            <div class="product-modal-price">
                {{ (((tempData$ | async)?.deliveryType === DeliveryOption.DELIVERY ?  
                    (tempData$ | async)?.product?.deliveryPriceCents :  
                    (tempData$ | async)?.product?.priceCents) || 0) | format_cents: true }}
            </div>
            <!-- Allergen -->
            <div class="product-modal-allergens">
                {{ "product_details_modal_allergens" | translate }}
                <div class="action-item"
                #tooltip="matTooltip"
                [matTooltip]="getFormattedAllergens((tempData$ | async)?.product?.allergenList || [])"
                [matTooltipPosition]="'left'"                                     
                [matTooltipClass]="'custom-delivery-info-tooltip'"     
                (mousedown)="tooltip.toggle()">
                    <img class="ml-2" [src]="'apps/user-app/src/assets/info-green.svg'"/>
                </div>
                
            </div>
        </div>
    
        <!-- empty line -->
        <mat-divider></mat-divider>
    
        <!-- -->
    
        <!-- Addon group loop -->
        <div class="addon-groups-container">
            <ng-container *ngFor="let addonGroup of (tempData$ | async)?.addonGroups">
    
                <!-- Addon Group item -->
                <div class="addon-group-item" id="{{ addonGroup.id }}">
    
                    <!-- Row 1 -->
                    <div class="addon-group-row-1" [ngClass]="{'required-wiggle' : (showRequiredWiggleAnimation$ | async) === addonGroup.id}">
                        <!-- Name -->
                        <div class="addon-group-name-container">
                            <!-- name -->
                            <div class="addon-group-name">
                                {{ ((useGermanDescriptions$ | async) && addonGroup.name || !addonGroup.nameEn) ? 
                                    addonGroup.name : 
                                    addonGroup.nameEn }}
                            </div>
    
                            <!-- no. of selected -->
                            <ng-container *ngIf="addonGroup.minSelection === 0 && (numberSelectedAddons(addonGroup.id) !== 0)">
                                <div class="addon-group-selected-items">
                                    ({{ numberSelectedAddons(addonGroup.id) }} {{ "product_details_selected" | translate }})
                                </div>
                            </ng-container>
    
                            <!-- min. possible -->
                            <ng-container *ngIf="addonGroup.minSelection >= 2">
                                <div class="addon-group-max-items" [ngClass]="{'wiggle' : (showMinWiggleAnimation$ | async) === addonGroup.id}">
                                    {{ "product_details_min" | translate }} {{ addonGroup.minSelection }}
                                </div>
                            </ng-container>      

                            <!-- max. possible -->
                            <ng-container *ngIf="addonGroup.minSelection === 0 || !(addonGroup.minSelection === 1 && addonGroup.maxSelection === 1)">
                                <div class="addon-group-max-items" [ngClass]="{'wiggle' : (showMaxWiggleAnimation$ | async) === addonGroup.id}">
                                    {{ "product_details_max" | translate }} {{ addonGroup.maxSelection }}
                                </div>
                            </ng-container>                        
    
                        </div>
                        <!-- Status (required / optional )-->                    
                        <div [ngClass]="{'addon-label-required': addonGroup.minSelection > 0, 'addon-label-optional': addonGroup.minSelection === 0}">
                            {{ (addonGroup.minSelection > 0 ? 'product_details_required' : 'product_details_optional') | translate }}
                        </div>                    
                    </div>
    
                    <!-- Each addon list loop row -->
                    <div class="addons-list-container">

                        <ng-container *ngIf="addonGroup.addonList.length > 0">
                            
                            <!-- Radio group-->
                            <mat-radio-group [name]="'addon_group_' + addonGroup.id">
                                <ng-container *ngFor="let addon of addonGroup.addonList; index as i">    
                                    <!-- Main addon item -->
                                    <div class="addon-list-item" *ngIf="i < MAX_ADDONS_SHOWN_DEFAULT || (showAllAddonsMap.has(addonGroup.id) && !!showAllAddonsMap.get(addonGroup.id))" >                            
                                        
                                        <!-- Radio - Single choice-->
                                        <ng-container *ngIf="addonGroup.minSelection === 1 && addonGroup.maxSelection === 1; else multipleChoiceAddons">
                                             <div class="addon-radio-single-choice-item">
                                                <!-- Radio Option -->
                                                <div class="addon-radio-box" (mousedown)="onRadioAddonClick(addon.id, addonGroup.id)">
                                                    <mat-radio-button class="action-item" id='{{addon.id || ""}}' [checked]="isAddonSelected$(addon.id, addonGroup.id) | async" 
                                                        [value]="addon.id">
                                                        {{ ((useGermanDescriptions$ | async) && addon.name || !addon.nameEn) ? 
                                                                addon.name : 
                                                                addon.nameEn }}
                                                    </mat-radio-button>
                                                </div>
            
                                                <!-- Price & Allergen -->
                                                <div class="addon-price-allergen-container">
                                                    <!-- Price -->
                                                    <div class="addon-price" *ngIf="addon.priceCents > 0">
                                                        + {{ addon.priceCents | format_cents: true }}
                                                    </div>
                                                    <!-- Allergen -->
                                                    <div class="addon-allergen"
                                                        #tooltip="matTooltip"
                                                        [matTooltip]="getFormattedAllergens(addon.allergenList, false)"
                                                        [matTooltipPosition]="'left'"                                     
                                                        [matTooltipClass]="'custom-delivery-info-tooltip'" 
                                                        (mousedown)="tooltip.toggle()">
                                                        <img class="info-gray-container ml-2" [src]="'apps/user-app/src/assets/info-gray.svg'"/>
                                                    </div>
                                                </div>
                                             </div>
                                        </ng-container>
                
                                        <!-- Checkbox - multiple choice-->
                                        <ng-template #multipleChoiceAddons>
                                            <div class="addon-radio-single-choice-item">
                                               <!-- Checkbox Option -->
                                               <div class="addon-radio-box" (click)="onCheckboxAddonClick($event, addon.id, addonGroup.id)">
                                                    <mat-checkbox color="primary" (click)="onCheckboxAddonClick($event, addon.id, addonGroup.id)"
                                                        [checked]="isAddonSelected$(addon.id, addonGroup.id) | async"
                                                        [disabled]="((isAddonSelected$(addon.id, addonGroup.id) | async) === false && (numberSelectedAddons(addonGroup.id) === addonGroup.maxSelection))">
                                                        {{ ((useGermanDescriptions$ | async) && addon.name || !addon.nameEn) ? addon.name : addon.nameEn }}
                                                    </mat-checkbox>
                                               </div>
            
                                               <!-- Price & Allergen -->
                                               <div class="addon-price-allergen-container">
                                                   <!-- Price -->
                                                   <div class="addon-price" *ngIf="addon.priceCents > 0">
                                                       + {{ addon.priceCents | format_cents: true }}
                                                   </div>
                                                   <!-- Allergen -->
                                                   <div class="addon-allergen"
                                                        #tooltip="matTooltip"
                                                        [matTooltip]="getFormattedAllergens(addon.allergenList, false)"
                                                        [matTooltipPosition]="'left'"                                     
                                                        [matTooltipClass]="'custom-delivery-info-tooltip'"   
                                                        (mousedown)="tooltip.toggle()"    
                                                   >
                                                       <img class="info-gray-container ml-2" [src]="'apps/user-app/src/assets/info-gray.svg'"/>
                                                   </div>
                                               </div>
                                            </div>
                                        </ng-template>
                
                                    </div>
                                </ng-container>
                            </mat-radio-group>
                        </ng-container>
                        
                        
    
                        <!-- Show all list / less button -->
                        <ng-container *ngIf="addonGroup.addonList.length > MAX_ADDONS_SHOWN_DEFAULT">
                            <div class="show-all-addons" (mousedown)="onPressShowAllLess(addonGroup.id)">{{ 
                                    ((showAllAddonsMap.has(addonGroup.id) && !!showAllAddonsMap.get(addonGroup.id)) ? 
                                    "product_details_show_less" :
                                    "product_details_show_all") | translate 
                                }}
                                <mat-icon>{{ 
                                    showAllAddonsMap.has(addonGroup.id) && !!showAllAddonsMap.get(addonGroup.id) ? 
                                    'expand_less' : 
                                    'expand_more'
                                }}
                                </mat-icon>
                            </div>
                        </ng-container>
                        
    
                    </div>    
                </div>
            </ng-container>
        </div>
        
    
        <!-- Special instructions -->
        <div>
            <!-- Title -->
            <div class="special-instructions-text">
                {{ "product_details_instructions" | translate }}
            </div>
    
            <!-- Input field -->
            <div>
                <fs-textarea [label]="'product_details_instructions_details' | translate"     
                    [inputValue]="(tempData$ | async)?.specialInstructions || ''"
                    (valueUpdate)="onSpecialInstructionsUpdate($event)"           
                ></fs-textarea>
            </div>    
        </div>
    </div>    
</div>

<!-- Action buttons -->
<div class="bottom-action-buttons">
    <!-- Left quantiy -->
    <div class="bottom-action-left-quantity">
        <!-- minus -->
        <div (mousedown)="onPressQuantityMinus()">
            <img class="product-quantity-btn" [src]="'apps/user-app/src/assets/minus-round-green.svg'"/>
        </div>
        <!-- number -->
        <div class="product-quantity-number" [ngClass]="{'scale-up': showScaleUpQuantityAnimation$ | async, 'scale-down': showScaleDownQuantityAnimation$ | async}">
            {{ (tempData$ | async)?.quantity }}
        </div>
        <!-- plus -->
        <div (mousedown)="onPressQuantityPlus()">
            <img class="product-quantity-btn" [src]="'apps/user-app/src/assets/add-plus-round-circle.svg'"/>
        </div>
    </div>

    <!-- Add button -->
    <div class="bottom-action-right-add" (click)="onAddButtonPress()">
        <fs-button [text]="'product_details_add_btn' | translate" [style]="'full-width-large-height'"
        [type]="'raised-primary'"></fs-button>
    </div>
</div>
