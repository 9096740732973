import { BehaviorSubject, Observable} from "rxjs";
import { Injectable} from "@angular/core";
import { OrderCart } from "../model/order-basket.model";

@Injectable({
    providedIn: 'root',
})
export class OrderBasketState {

    private state$: BehaviorSubject<OrderCart> = new BehaviorSubject<OrderCart>(new OrderCart());

    constructor(
    ) {
    }

    public getData$(): Observable<OrderCart> {
        return this.state$.asObservable();
    }

    public getData(): OrderCart {
        return this.state$.getValue();
    }

    public setData(value: OrderCart): void {
        this.state$.next(value);
    }
}
