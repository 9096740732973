import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'libs/shared-services/src/lib/auth.service';
import { BehaviorSubject } from 'rxjs';
import { OrderListResponse } from 'libs/shared-models/src/lib/order/order-response';
import { OrdersService } from '../../services/orders/orders.service';
import { TopNavBarService } from '../../services/navbar/top-navbar.service';
import { OrderBasketService } from '../../services/order-basket/order-basket.service';
import { LOADING_STYLE } from 'libs/shared-ui/src/lib/fs-loading-spinner/fs-loading-spinner.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeliveryOption } from 'libs/shared-models/src/lib/delivery-option';

@UntilDestroy()
@Component({
  selector: 'web-foodis-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrl: './orders-list.component.scss',
})
export class OrdersListComponent implements OnInit {

  public ordersList$: BehaviorSubject<OrderListResponse> = new BehaviorSubject<OrderListResponse>(new OrderListResponse());

  public basketItemsCount$ = this.orderBasketService.basketItemsCount$.asObservable();

  public DeliveryOption: typeof DeliveryOption = DeliveryOption;
  public LOADING_STYLE: typeof LOADING_STYLE = LOADING_STYLE;

  public loadingApiList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private router: Router,
    private ordersService: OrdersService,
    private topNavBarService: TopNavBarService,
    private orderBasketService: OrderBasketService
  ) {

  }

  public ngOnInit(): void {
    if (!this.authService.isLoggedIn()) {
       // redirect to home page
      this.router.navigateByUrl("/dashboard");
    } else {

      this.loadingApiList$.next(true);
      this.ordersService.fetchOrdersListAPI$().pipe(untilDestroyed(this)).subscribe((list) => {
        this.ordersList$.next(list);
        this.loadingApiList$.next(false);
      })
    }

    this.authService.$getLoginState().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        // clear the list on Logout
        this.ordersList$.next(new OrderListResponse());
      }
    })
  }

  public onPressCheckRestaurants() {
    this.router.navigateByUrl("dashboard");
  }

  public onPressSeeOrderBasket() {
    this.topNavBarService.openOrderBasketInPopup();
  }
}
