<div class="address-text">
    <!-- Icon & title -->
    <div class="address-title">
        <div class="address-delivery-icon">
            <img [src]="'apps/user-app/src/assets/delivery-type-pick-up-hand-selected.svg'"/>
        </div>
        {{ 'checkout_pickup_address' | translate }}
    </div>

    <!-- Info reinforced -->
    <ng-container *ngIf="usage !== 'order-page'">
        <div class="pickup-info-text">{{ 'checkout_pickup_info' | translate }}</div>
    </ng-container>
    

    <!-- Restaurant name -->
    <div class="pickup-restaurant-name">{{ restaurantName }}</div>


    <!-- Street -->
    <div class="address-street pb-1">
        <div class="address-label">{{ 'checkout_delivery_street' | translate  }}: </div>
        <div class="address-content bold">
            {{ 
                (pickupAddress$ | async)?.streetName + " " +
                (pickupAddress$ | async)?.streetNumber
            }}
        </div>
    </div>

     <!-- Postal code, city -->
     <div class="flex flex-row pt-1">
        <div class="address-content">
            {{ (pickupAddress$ | async)?.postalCode }}
            {{ (pickupAddress$ | async)?.district }},
            {{ (pickupAddress$ | async)?.city }}
        </div>
    </div>

    <!-- Buttons -->
    <div class="address-tools">
        <div class="copy-btn mr-5" (mousedown)="onCopyClick()">
            <img class="copy-src" [src]="'apps/user-app/src/assets/copy.svg'"/>
            {{ "restaurant_info_modal_address_copy" | translate }}
        </div>

        <div class="external-btn"  (mousedown)="onExternalMapsClick()">
            <img class="external-src" [src]="'apps/user-app/src/assets/external-link.svg'"/>
            {{ "restaurant_info_modal_address_maps" | translate }}
        </div>
    </div>

</div>