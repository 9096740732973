import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, catchError, map, Observable, of,} from "rxjs";
import {AddressResponse} from "libs/shared-models/src/lib/address-response";
import {AuthService} from "libs/shared-services/src/lib/auth.service";
import {UserAddressStateService} from "./user-address.state.service";
import {environment} from "../../../environments/environment";
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";
import {LocalStorageService} from "libs/shared-services/src/lib/local-storage.service";
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { BaseAddressEditorComponent } from 'libs/shared-ui/src/lib/fs-base-address-editor/fs-base-address-editor.component';
import { CookieService } from 'libs/shared-services/src/lib/cookie.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class UserAddressFacade extends BaseLoadingService {

    private isAddressSearchOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private baseAddressComponent: BaseAddressEditorComponent | null = null;

    constructor(
        @Inject('env') private environment: any,
        private authService: AuthService,
        private apiService: ApiBaseService,
        private userAddressState: UserAddressStateService,
        private toasterService: ToasterService,
        private localStorageService: LocalStorageService,
        private cookieService: CookieService,
        private localeService: LocaleService
    ) {
        super();        
    }

    public initAddress() {
        this.fetchApiList();
        this.keepCurrentAddressUpdated();
    }

    /*
        Listen to any change in the entire list and update current one
     */

    private keepCurrentAddressUpdated() {
        this.getAddresses$().subscribe((list: AddressResponse[]) => {
            // Visitor mode - temporary store them in local storage
            if (!this.authService.isLoggedIn()) {
                // store them in LocalStorage
                list = list.map((item) => {
                    item.setIsLocallyStored(true);
                    return item
                });
                this.localStorageService.saveData("fs-temp-addresses", JSON.stringify(list));
            }
        })
    }

    /*
        Get Address / Set address
     */

    public getAddresses$(): Observable<AddressResponse[]> {
        return this.userAddressState.getAddressList$();
    }

    public getCurrentAddress$(): Observable<AddressResponse> {
        return this.userAddressState.getCurrentAddress$()
    }

    public getCurrentAddress(): AddressResponse {
        return this.userAddressState.getCurrentAddress();
    }

    public setCurrentAddress(a: AddressResponse) {
        return this.userAddressState.setCurrentAddress(a);
    }

    public clearDataOnLogout() {
        this.userAddressState.clearData();
    }

    /*
        Add new address
     */

    public upsertNewAddress(a: AddressResponse) {
        // store it on API
        if (this.authService.isLoggedIn()) {
            this.sendAddressToAPI$(a).subscribe((address: AddressResponse) => {
                if (!!address) {
                    this.userAddressState.upsertAddress(address);
                    this.setDefaultAddress(address);
                    this.setAddressOpen(false); // close the expanded step
                }
            })
        } else {
            // store it locally only
            let newAddress = Object.assign(new AddressResponse(), a);
            this.toasterService.showSuccess("", this.localeService.translate("user_address_saved_success"));
            this.userAddressState.upsertAddress(newAddress);
            this.setDefaultAddress(newAddress);
            this.setAddressOpen(false);
        }
    }

    /*
        Set default address
    */

    public setDefaultAddress(a: AddressResponse) {
        // Store default on API
        if (this.authService.isLoggedIn()) {
            this.setDefaultToApi$(a).subscribe((success: boolean) => {
                if (success) {
                    this.userAddressState.setCurrentAddress(a);
                    // add a small timeout just to create the visual impression of selecting the address first (for the user to see it 250ms) and then to close it.
                    this.closeAddressSection();
                }
            })
        } else {
            // store it locally only
            this.setCurrentAddress(a);
            this.closeAddressSection();
        }
    }

    private closeAddressSection() {
        setTimeout(() => {
            this.setAddressOpen(false);
        }, 200);
    }

    /*
        Delete address
    */

    public deleteAddress(a: AddressResponse) {

        // Delete with API
        if (this.authService.isLoggedIn()) {
            this.deleteAddressToApi$(a).subscribe((success: boolean) => {
                if (success) {
                    this.userAddressState.deleteAddress(a);
                }
            })
        } else {
            // delete it locally only
            this.toasterService.showSuccess("", this.localeService.translate("user_address_deleted_success"));
            this.userAddressState.deleteAddress(a);
        }
    }

    // Extended address step
    public setAddressOpen(b: boolean) {
        this.isAddressSearchOpen$.next(b);
    }

    public isAddressOpen$(): Observable<boolean> {
        return this.isAddressSearchOpen$.asObservable();
    }

    public isAddressOpen(): boolean {
        return this.isAddressSearchOpen$.getValue();
    }


    /*
        API fetch
     */

    // All addresses fetch
    private fetchApiList() {
        // Fetch from API:
        if (this.authService.isLoggedIn()) {
            this.getApiAdressListAPI();            
        } else {
            // Check if something came from Landing page:         
            let landingAddress = this.getLandingPageInitAddress();

            // Fetch them from LocalStorage if we are in the Visitor mode
            const storageData = this.localStorageService.getData("fs-temp-addresses");
            if (!!storageData) {
                let list = JSON.parse(storageData);
                list =  list.map((item: any) => Object.assign(new AddressResponse(), item));

                if (landingAddress) {
                    list = list.map((item: any) => { item.isDefault = false; return item }); // making all the others default false
                    landingAddress.isDefault = true;
                    list.push(landingAddress);
                }
                this.userAddressState.setAddressList(list);                
            } else {
                if (landingAddress) {
                    landingAddress.isDefault = true;
                    let crtList = this.userAddressState.getAddressList();
                    crtList = crtList.map((item: any) => { item.isDefault = false; return item }); // making all the others default false
                    crtList.push(landingAddress);
                    this.userAddressState.setAddressList([landingAddress]);
                }
            }
            this.setFinished();
        }
    }

    private getApiAdressListAPI() {
        this.apiService.get(environment.API_USER_ADDRESSES).subscribe({
            next: (res: any) => {
                let apiList = res.addressList.map((item: any) => Object.assign(new AddressResponse(), item));


                 // Check if something came from Landing page:         
                let landingAddress = this.getLandingPageInitAddress();
                if (landingAddress) {
                    const itAlreadyExistsInApi = apiList.find(
                        (a: AddressResponse) => 
                            a.latitude === landingAddress?.latitude && 
                            a.longitude === landingAddress.longitude)

                    // if it's not a duplicate existing in API already, then submit it to API
                    if (!itAlreadyExistsInApi) {
                        landingAddress.isDefault = true;
                        this.sendAddressToAPI$(landingAddress).subscribe((address: AddressResponse) => {
                            // add the new address to the API results list
                            apiList.push(address);
                            apiList = apiList.map((a: AddressResponse) => {
                                if (a.latitude !== landingAddress?.latitude || a.longitude !== landingAddress?.longitude) {
                                    a.isDefault = false; // make the others default (as they would be made already by API)
                                }
                                return a;
                            })

                            // update the service facade
                            this.userAddressState.setAddressList(apiList);
                            this.setFinished();

                            return;
                        });
                    }
                }

                // First check if the local stored address (in case it exists) is already stored in api or not
                // Check first if there was a local temp address as default before doing the login  (visitor mode)        
                const storageData = this.localStorageService.getData("fs-temp-addresses");
                if (storageData) {
                    let localList = JSON.parse(storageData);
                    localList =  localList.map((item: any) => Object.assign(new AddressResponse(), item));
                    const defaultAndNotSavedInApi = localList.find((a: AddressResponse) => a.isTemporary && a.isDefault);
                    if (defaultAndNotSavedInApi) {
                        const alsoStoredInApi = apiList.find(
                            (item: AddressResponse) => 
                            item.latitude === defaultAndNotSavedInApi.latitude && 
                            item.longitude === defaultAndNotSavedInApi.longitude
                        );

                        // Update it on API side too
                        if (!alsoStoredInApi) {
                            this.sendAddressToAPI$(defaultAndNotSavedInApi).subscribe((address: AddressResponse) => {
                                // add the new address to the API results list
                                apiList.push(address);

                                // update the service facade
                                this.userAddressState.setAddressList(apiList);
                                this.setFinished();

                                // clean-up the local storage for the stored addresses. Delete all, as we took what was needed (default) and sent it to api already
                                this.localStorageService.removeData("fs-temp-addresses");
                            });
                            return; // stop here
                        }
                    }
                }

                // update the service facade
                this.userAddressState.setAddressList(apiList);
                this.setFinished();
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    private getLandingPageInitAddress(): AddressResponse | null {
        const landingAddressValue = this.cookieService.getCookie('fs-temp-landing-address-value');
        const landingAddressKey = this.cookieService.getCookie('fs-temp-landing-address-key');            
        let landingAddress = null;
        if (landingAddressValue && landingAddressKey) {
            const decryptedCookie: string = CryptoJS.AES.decrypt(landingAddressValue, landingAddressKey).toString(CryptoJS.enc.Utf8);
            let list = JSON.parse(decryptedCookie);
            list =  list.map((item: any) => Object.assign(new AddressResponse(), item));
            if (list[0] && list[0].latitude && list[0].longitude) {                    
                landingAddress = list[0];
            }
            this.cookieService.deleteCookie('fs-temp-landing-address-value');
            this.cookieService.deleteCookie('fs-temp-landing-address-key');
        }
        return landingAddress;
    }


    // Add new address (api)
    private sendAddressToAPI$(a: AddressResponse): Observable<AddressResponse> {

        // Check if it's a new address or an existing one  (add vs update)
        const apiCall = a.hasGeneratedId() ?
            this.apiService.post(environment.API_USER_ADDRESSES, a) :
            this.apiService.put(environment.API_USER_UPDATE_DELETE_ADDRESS.replace("{{address_id}}", a.getId()), a)

        return apiCall.pipe(
            map((res) => {
                const apiUpdatedAddress = Object.assign(new AddressResponse(), res);
                this.toasterService.showSuccess("", this.localeService.translate("user_address_saved_success"));
                return apiUpdatedAddress;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(null);
            })
        );
    }

    // Set default (api)
    private setDefaultToApi$(a: AddressResponse): Observable<boolean> {
        const path = environment.API_USER_SET_DEFAULT_ADDRESS.replace("{{address_id}}", a.getId());
        return this.apiService.put(path, {}).pipe(
            map(() => {
                return of(true);
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }

    // Delete address (api)
    private deleteAddressToApi$(a: AddressResponse): Observable<boolean> {
        const path = environment.API_USER_UPDATE_DELETE_ADDRESS.replace("{{address_id}}", a.getId());
        return this.apiService.delete(path, {}).pipe(
            map(() => {
                this.toasterService.showSuccess("", this.localeService.translate("user_address_deleted_success"));
                return of(true);

            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }

    public setAddressBaseComponent(b: BaseAddressEditorComponent | null) {
        if (b) {
            this.baseAddressComponent = b;
        }
    }

    public forcelyOpenExtended(a: AddressResponse | null = null) {
        if (!a) {
            a = this.getCurrentAddress();
        }
        if (this.baseAddressComponent) {
            this.baseAddressComponent.onEditAddress(a);
        }
    }
}

