import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TopNavBarService } from '../../services/navbar/top-navbar.service';
import { environment } from 'apps/user-app/src/environments/environment';
import { UserAddressFacade } from '../../services/user-address/user-address.facade.service';
import { AuthService } from 'libs/shared-services/src/lib/auth.service';

@UntilDestroy()
@Component({
  selector: 'web-foodis-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.scss',
})
export class ProfilePageComponent implements OnInit {

  public selfData$ = this.topNavBarService.getSelfData$();

  constructor(
    private topNavBarService: TopNavBarService,
    private userAddressFacade: UserAddressFacade,
    private authService: AuthService,
    private router: Router
  ) {

  }

  public ngOnInit(): void {
    if (!this.authService.isLoggedIn()) {
       // redirect to home page
      this.router.navigateByUrl("/dashboard");
    }
  }

  public onPasswordResetPress() {
    window.open(environment.PRODUCT_URLS.ACCOUNT_RESET_PASSWORD,'_blank');
  }

  public onLogoutClick() {
    // clear states
    this.topNavBarService.clearSelfData();        
    this.userAddressFacade.clearDataOnLogout();
    
    // logout
    this.authService.doLogout(false);

    // redirect to home page
    this.router.navigateByUrl("/dashboard");
  }
}
