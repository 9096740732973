import { Injectable} from '@angular/core';
import { ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import { ToasterService} from "libs/shared-services/src/lib/toaster.service";
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';
import { FullRestaurantPageState } from './state/full-restaurant-page.state.';
import { Observable, map } from 'rxjs';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { PublicRestaurantResponse } from './model/public-restaurant-response';
import { environment } from 'apps/user-app/src/environments/environment';
import { haversine_distance } from 'libs/shared-models/src/lib/utils/address-functions';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { BusinessHoursCheck, BusinessHoursHelper } from 'libs/shared-models/src/lib/utils/business-hours-helper';
import { TopNavBarService } from '../navbar/top-navbar.service';
import { ModalService } from 'libs/shared-ui/src/lib/modal-service/modal-service.service';
import { LocaleTranslatePipe } from 'libs/shared-services/src/lib/locale-pipe';

/*
    This service gets the Full data about a Restaurant - which is used in both Restaurant Page + Order cart
*/

@Injectable({
    providedIn: 'root',
})
export class FullRestaurantPageService extends BaseLoadingService {


    constructor(
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private localeService: LocaleService,
        private fullPageState: FullRestaurantPageState,
        private topNavBarService: TopNavBarService,
        private modalService: ModalService
    ) {
        super();
    }

    public fetchRestaurantDataBySlug(slug: string) {
        const url = environment.API_GET_FULL_RESTAURANT_BY_SLUG.replace("{{slug}}", slug);
        this.fetchRestaurantData(url);
    }

    public fetchRestaurantDataById(id: string) {
        const url = environment.API_GET_FULL_RESTAURANT.replace("{{restaurantId}}", id);
        this.fetchRestaurantData(url);
    }

    public fetchRestaurantData(url: string) {
        this.apiService.get(url).subscribe({
            next: (res: any) => {
            if (res) {                 
                const restaurant = Object.assign(new PublicRestaurantResponse(), res);
                this.fullPageState.upsertItem(restaurant);                
            }
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    // Observables
    public getList$(): Observable<PublicRestaurantResponse[]> {
        return this.fullPageState.getData$();
    }

    public getRestaurantById$(id: string): Observable<PublicRestaurantResponse | undefined> {
        return this.fullPageState.getData$().pipe(map((list) => list.find((r) => r.restaurantInfo.id === id)));
    }

    // Direct interogation
    public getRestaurantById(id: string): PublicRestaurantResponse | undefined {
        return this.fullPageState.getData().find((r) => r.restaurantInfo.id === id);
    }

    public getRestaurantBySlug$(slug: string): Observable<PublicRestaurantResponse | undefined> {
        return this.fullPageState.getData$().pipe(map((list) => list.find((r) => r.restaurantInfo.slug === slug)));
    }


    /*
        DUPLICATED from restaurants-list-service  (to move everything in a common part)
        Distance (km) from restaurant's geo-location points to my current address points
    */
   
    public getDistanceToMyAddress(item: PublicRestaurantResponse, userAddress: AddressResponse): number {

        // Take the points from restaurant + user address
        const initialPosition = {
            lat: item.restaurantInfo.address.latitude,
            lng: item.restaurantInfo.address.longitude,
        };
        const newPosition = {
            lat: userAddress.latitude,
            lng: userAddress.longitude,
        };

        // Calculate distance
        const distance = haversine_distance(initialPosition, newPosition);
        return distance;
    }

    // TODO in the future / unify this code with the restaurant search list one
    public isOutsideBusinessHours(restaurantId: string): boolean {

        const foundRestaurant = this.getRestaurantById(restaurantId);
        if (!foundRestaurant) {
            // TODO: maybe changing this to the slug? So far, this scenario is not reached, but we could unify it
            this.fetchRestaurantDataById(restaurantId);
            return false; // as a default until the next interrogation comes and has the restaurant
        }

        const paramData = this.createHoursCheck(foundRestaurant);
        
        const currentDeliverOption = this.topNavBarService.getDeliveryOption();
        if (currentDeliverOption === "PICKUP") {
            return !BusinessHoursHelper.checkIsActiveDuringHoursPickup(paramData);
        }

        if (currentDeliverOption === "DELIVERY") {
            return !BusinessHoursHelper.checkIsActiveDuringHoursDelivery(paramData);
        }
        
        return false;        
    }

    public getNextAvailableHours(restaurantId: string): string {

        const foundRestaurant = this.getRestaurantById(restaurantId);
        if (!foundRestaurant) {
            // TODO: maybe changing this to the slug? So far, this scenario is not reached, but we could unify it
            this.fetchRestaurantDataById(restaurantId);
            return ""; // as a default until the next interrogation comes and has the restaurant
        }

        const paramData = this.createHoursCheck(foundRestaurant);

        const currentDeliverOption = this.topNavBarService.getDeliveryOption();
        if (currentDeliverOption === "PICKUP") {
            return BusinessHoursHelper.getNextAvailableDatePickup(paramData, this.localeService.getLanguageISO2());
        }
        if (currentDeliverOption === "DELIVERY") {
            return BusinessHoursHelper.getNextAvailableDateDelivery(paramData, this.localeService.getLanguageISO2());
        }
        return "";
    }

    
    private createHoursCheck(data: PublicRestaurantResponse): BusinessHoursCheck {
        const paramData: BusinessHoursCheck = {
            businessHoursPickup: data.restaurantInfo.businessHoursPickup,
            businessHoursDelivery: data.restaurantInfo.businessHoursDelivery,
            hasFoodisDelivery: data.restaurantInfo.hasFoodisDelivery,
            hasOwnDelivery: data.restaurantInfo.hasOwnDelivery,
            hasPickup: data.restaurantInfo.hasPickup,
            closed: data.restaurantInfo.closed
        }
        return paramData;
    }

    public checkIsClosed(restaurantId: string): boolean {
        const data = this.getRestaurantById(restaurantId);
        if (data && data.restaurantInfo.id) {
            const isClosed = data.restaurantInfo.closed || this.isOutsideBusinessHours(data.restaurantInfo.id);            
            return isClosed;
        }
        return false;
    }
    
	public openClosedWarningPopup() {
		const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("restaurant_page_modal_not_available_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("restaurant_page_modal_not_available_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("restaurant_page_modal_not_available_left_action"),
            preselectedButton: "left",
			oneButtonOnly: true
        }

        // open popup
        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // true                
            }
        });
	}
}