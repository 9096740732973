import { Injectable} from '@angular/core';
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';
import { ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import { ToasterService} from "libs/shared-services/src/lib/toaster.service";
import { OrderBasketState } from './state/order-basket.state';
import { OrderCart, OrderCartProductItem } from './model/order-basket.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from 'libs/shared-services/src/lib/local-storage.service';
import { ModalService } from 'libs/shared-ui/src/lib/modal-service/modal-service.service';
import { LocaleTranslatePipe } from 'libs/shared-services/src/lib/locale-pipe';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { AuthService } from 'libs/shared-services/src/lib/auth.service';
import { Router } from '@angular/router';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { DeliveryOption } from 'libs/shared-models/src/lib/delivery-option';
/*
    This service gets the basket items (initially it tries from local storage / or from API if the user is logged in)
    The other services / components use this service to add / remove items
*/
@Injectable({
    providedIn: 'root',
})
export class OrderBasketService extends BaseLoadingService {

    public basketItemsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public onBasketEmptyAction$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    
    constructor(
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private orderBasketState: OrderBasketState,
        private localStorageService: LocalStorageService,
        private modalService: ModalService,
        private localeService: LocaleService,
        private authService: AuthService,
        private router: Router
    ) {
        super();
    }

    public initService() {        
        // Fetch them from LocalStorage if we are in the Visitor mode
        const storageData = this.localStorageService.getData("fs-temp-cart", false);
        if (storageData) {
            let data = JSON.parse(storageData);
            data = Object.assign(new OrderCart(), data);
            this.setData(data);
        }

        this.keepCurrentCartUpdated();
        this.setFinished();
    }

    // State related 

    public getState$(): Observable<OrderCart> {
        return this.orderBasketState.getData$();
    }

    public getState(): OrderCart {
        return this.orderBasketState.getData();
    }

    public setData(value: OrderCart): void {
        this.orderBasketState.setData(value);
    }

    public updateDeliverySupport(restaurantId: string, hasDelivery: boolean, hasPickup: boolean, restaurantName: string, restaurantSlug: string) {       
        
        const data = this.getState();
        if (!data.restaurantId || data.restaurantId === restaurantId) {
            data.supportsDelivery = hasDelivery;
            data.supportsPickup = hasPickup;
            if (!data.restaurantId) {
                data.restaurantName = restaurantName; // just to have the friendly name in case we need for the tooltip in delivery
                data.restaurantSlug = restaurantSlug; 
            }
            this.setData(data);
        }
    }

    private keepCurrentCartUpdated() {
        this.getState$().subscribe((data: OrderCart) => {
            // TODO: add some sync with API too
            
            this.calculateAndUpdateNumberOfItems(data);
            this.localStorageService.saveData("fs-temp-cart", JSON.stringify(data), false);
        })
    }

    private calculateAndUpdateNumberOfItems(data: OrderCart) {
        let totalItems = 0;
        data.items.forEach((p) => {
            totalItems += p.quantity
        });
        this.basketItemsCount$.next(totalItems);
    }


    /*
        Interaction with other components / services. Usages:
        - Add directly from the cards in restaurant page
        - Add from Product Modal
    */    
    public addProduct(restaurantId: string, restaurantName: string, restaurantSlug: string, restaurantAddress: AddressResponse, restaurantMinOrderAmount: number, value: OrderCartProductItem, supportsDelivery: boolean, supportsPickup: boolean) {        
        const data = this.getState();

        // Make sure it's the same restaurant. Stop otherwise - add modal to ask if you're sure
        if (!!data.restaurantId && data.restaurantId !== restaurantId) {           
            this.openMismatchPopup(); 
            return;
        }

        // Check if the product already exists (identically) - so then we could just increase the quantity counter
        const identicalProduct = data.items.find((p) => this.isIdenticalProduct(p, value));

        if (identicalProduct) {
            data.items = data.items.map((p) => {
                if (this.isIdenticalProduct(p, identicalProduct)) {
                    p.quantity += value.quantity;
                }
                return p;
            })
        } else {
            // Add the product as a new one in the list
            data.items.push(value);
        }
                    
        // Also add the restaurant
        data.restaurantId = restaurantId;
        data.restaurantName = restaurantName;
        data.restaurantSlug = restaurantSlug;
        data.restaurantAddress = restaurantAddress;
        data.restaurantMinOrderAmount = restaurantMinOrderAmount;
        data.supportsDelivery = supportsDelivery;
        data.supportsPickup = supportsPickup;

        // make sure it switches to delivery / pickup in case the other one is selected and not supported
        if (data.deliveryOption === DeliveryOption.DELIVERY && !data.supportsDelivery) {
            if (data.supportsPickup) {
                data.deliveryOption = DeliveryOption.PICKUP;
            } else {
                console.warn("[Issue with delivery option] C1 - Has no delivery or pickup");
            }
        }
        if (data.deliveryOption === DeliveryOption.PICKUP && !data.supportsPickup) {
            if (data.supportsPickup) {
                data.deliveryOption = DeliveryOption.DELIVERY;
            } else {
                console.warn("[Issue with delivery option] C2 - Has no delivery or pickup");
            }
        }

        // update
        this.recalculateAndUpdate(data);
    }

    /*
        add +1 - from Cart buttons
    */
    public decreaseQuantity(product: OrderCartProductItem) {
        const data = this.getState();
        data.items = data.items.map((p) => {
            if (this.isIdenticalProduct(p, product)) {
                p.quantity -= 1; // in this case increase the quantity
            }
            return p;
        });

        // remove products which reached 0 quantity
        data.items = data.items.filter((p) => p.quantity !== 0);

        // zero products remaining => empty the cart
        if (data.items.length === 0) {
            this.emptyCart();
        }


        // update
        this.recalculateAndUpdate(data);
    }

    /*
        remove -1 - from Cart buttons
    */
    public increaseQuantity(product: OrderCartProductItem) {
        const data = this.getState();
        data.items = data.items.map((p) => {
            if (this.isIdenticalProduct(p, product)) {
                p.quantity += 1; // in this case increase the quantity
            }
            return p;
        })

        // update
        this.recalculateAndUpdate(data);
    }

    private isIdenticalProduct(p1: OrderCartProductItem, p2: OrderCartProductItem): boolean {
        // escaping the quantity because that can be different
        if (p1.productId === p2.productId) {                
            return p1.productCategoryId === p2.productCategoryId  && 
                    p1.productPrice === p2.productPrice && 
                    p1.productPriceDelivery === p2.productPriceDelivery && 
                    JSON.stringify(p1.addons) === JSON.stringify(p2.addons) && 
                    p1.specialInstructions === p2.specialInstructions
        }
        return false;
    }

    public updateProduct(restaurantId: string, value: OrderCartProductItem) {
        // TODO: change object

        // update
        //this.recalculateAndUpdate(null);
    }

    // delete it
    public emptyCart() {
        const data = this.getState();
        data.restaurantId = "";
        data.items = [];
        data.initialPrice = 0;        
        data.extraDeliveryDiscount = 0;
        data.totalPrice = 0;
        data.supportsDelivery = true;
        data.supportsPickup = true;
        data.restaurantName = "";
        data.restaurantSlug = "";
        data.restaurantAddress = new AddressResponse();
        data.restaurantMinOrderAmount = 0;
        this.setData(data);

        this.onBasketEmptyAction$.next(true);
    }
    

    // After each update (adding new product / removing / editing) we need to update the general price of the cart before setting the state
    private recalculateAndUpdate(data: OrderCart) {
        const recalculated = this.recalculateCost(data);
        this.setData(recalculated);
    }
    
    private recalculateCost(data: OrderCart) {
        let initialPricePickup = 0; // subtotal
        let initialPriceDelivery = 0; // subtotal

        data.items.forEach((product) => {
            
            let productFullSumPickup = 0;
            let productFullSumDelivery = 0;

            // product price
            productFullSumPickup += product.productPrice;
            productFullSumDelivery += product.productPriceDelivery;

            // add-ons price
            product.addons.forEach((a) => {
                productFullSumPickup += a.addonPrice;
                productFullSumDelivery += a.addonPrice;
            })

            // multiply by quantity
            productFullSumPickup = productFullSumPickup * product.quantity;
            productFullSumDelivery = productFullSumDelivery * product.quantity;

            // add it to the initial price
            initialPricePickup += productFullSumPickup;
            initialPriceDelivery += productFullSumDelivery;
        });

        data.initialPrice =  data.deliveryOption === DeliveryOption.DELIVERY ? initialPriceDelivery : initialPricePickup;

        // some condition to check the extra delivery
        if (data.deliveryOption === DeliveryOption.DELIVERY) {
            // calculating the needed delivery cost only
            const neededForDelivery = 600 // TODO: move this on API
            const differenceDeliveryPickup = initialPriceDelivery - initialPricePickup;
            const discount = data.deliveryPrice + differenceDeliveryPickup -  neededForDelivery;
            
            if (discount >= 0) {
                data.extraDeliveryDiscount = discount;
            } else {
                if (data.restaurantId) {
                    console.warn("[Delivery calculation] - Something is wrong, we get a loss: ", discount);
                }
            }
            
            data.totalPrice = data.initialPrice + data.deliveryPrice - data.extraDeliveryDiscount;
        } else {
            data.totalPrice = data.initialPrice
        }

        return data;
    }


    public setDeliveryOption(option: DeliveryOption) {
        const data = this.getState();
        data.deliveryOption = option;
        this.recalculateAndUpdate(data);
    }

    private openMismatchPopup() {
        const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("order_basket_mismatch_modal_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("order_basket_mismatch_modal_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("order_basket_mismatch_modal_left_action"),
            rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("order_basket_mismatch_modal_right_action"),
            preselectedButton: "left"
        }

        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // Empty cart
                this.emptyCart();
            } 
        });
    }    

    public handleGoToCheckout() {

        if (!this.authService.isLoggedIn()) {
            this.authService.goToLoginWithParams('checkout');
        } else {
            this.router.navigateByUrl("checkout")
        }
    }
}