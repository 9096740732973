import { Injectable} from '@angular/core';
import { ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import { ToasterService} from "libs/shared-services/src/lib/toaster.service";
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';
import { RestaurantCategoryEnum } from 'libs/shared-models/src/lib/restaurant/restaurant-category-enum';
import { environment } from '../../../environments/environment';
import { RestaurantsListState } from './state/restaurants-list.state.';
import { RestaurantSearchResponse } from './model/restaurant-search-response';
import { Observable, map } from 'rxjs';
import { RestaurantSearchItemResponse } from './model/restaurant-search-item-response';
import { BusinessHoursCheck, BusinessHoursHelper } from 'libs/shared-models/src/lib/utils/business-hours-helper'
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { haversine_distance } from 'libs/shared-models/src/lib/utils/address-functions';

/*
    This service gets the Dashboard "lite" list of restaurants (based on the search / filters)
*/
@Injectable({
    providedIn: 'root',
})
export class RestaurantsListService extends BaseLoadingService {

    private lastQuery: QueryRestaurantList;

    constructor(
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private restaurantListState: RestaurantsListState,
        private localeService: LocaleService
    ) {
        super();
    }

    public fetchRestaurantsList(q: QueryRestaurantList) {

        // don't query API again if the query didn't change
        if (JSON.stringify(q) === JSON.stringify(this.lastQuery)) {
            return; 
        }
        
        this.lastQuery = q;

        // mandatory params
        let url = environment.API_GET_RESTAURANTS_LIST;
        url += `?lat=${q.lat}&lon=${q.lon}&deliveryOption=${q.deliveryOption}&open=${q.open}&pageNumber=${q.pageNumber}&pageSize=${q.pageSize}`

        // optional params
        if (q.restaurantCategory && q?.restaurantCategory?.length > 0) {
            url += `&restaurantCategory=${q.restaurantCategory}`; // encodeURIComponent(JSON.stringify(q.restaurantCategory))
        }

        // GET
        this.apiService.get(url).subscribe({
            next: (res: any) => {
            if (res) {                 
                const resSearch = Object.assign(new RestaurantSearchResponse(), res);
                this.restaurantListState.setData(resSearch);                
            }
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    public getList$(): Observable<RestaurantSearchItemResponse[]> {
        return this.restaurantListState.getData$().pipe(map((res: RestaurantSearchResponse) => res.results));
    }

    public getItemById(id: string): RestaurantSearchItemResponse | undefined {
        return this.restaurantListState.getData().results.find((r) => r.id === id);
    }

    public getState$(): Observable<RestaurantSearchResponse> {
        return this.restaurantListState.getData$();
    }

    public isOutsideBusinessHours(item: RestaurantSearchItemResponse): boolean {
        const paramData = this.createHoursCheck(item);

        if (this.lastQuery?.deliveryOption === "PICKUP") {
            return !BusinessHoursHelper.checkIsActiveDuringHoursPickup(paramData);
        }

        if (this.lastQuery?.deliveryOption === "DELIVERY") {
            return !BusinessHoursHelper.checkIsActiveDuringHoursDelivery(paramData);
        }
        
        return false;        
    }

    public getNextAvailableHours(item: RestaurantSearchItemResponse): string {
        const paramData = this.createHoursCheck(item);
        if (this.lastQuery?.deliveryOption === "PICKUP") {
            return BusinessHoursHelper.getNextAvailableDatePickup(paramData, this.localeService.getLanguageISO2());
        }
        if (this.lastQuery?.deliveryOption === "DELIVERY") {
            return BusinessHoursHelper.getNextAvailableDateDelivery(paramData, this.localeService.getLanguageISO2());
        }
        return "";
    }

    
    private createHoursCheck(data: RestaurantSearchItemResponse): BusinessHoursCheck {
        const paramData: BusinessHoursCheck = {
            businessHoursPickup: data.businessHoursPickup,
            businessHoursDelivery: data.businessHoursDelivery,
            hasFoodisDelivery: data.hasFoodisDelivery,
            hasOwnDelivery: data.hasOwnDelivery,
            hasPickup: data.hasPickup,
            closed: data.closed
        }
        return paramData;
    }

    /*
        Distance (km) from restaurant's geo-location points to my current address points
    */
   
    public getDistanceToMyAddress(item: RestaurantSearchItemResponse, userAddress: AddressResponse): number {

        // Take the points from restaurant + user address
        const initialPosition = {
            lat: item.address.latitude,
            lng: item.address.longitude,
        };
        const newPosition = {
            lat: userAddress.latitude,
            lng: userAddress.longitude,
        };

        // Calculate distance
        const distance = haversine_distance(initialPosition, newPosition);
        return distance;
    }

}

export interface QueryRestaurantList {
    lat: number,
    lon: number,
    deliveryOption: "DELIVERY" | "PICKUP",
    open: boolean,
    pageNumber: number,
    pageSize: number,
    restaurantCategory?: RestaurantCategoryEnum[],
}
