import { Component, OnInit, input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// ES module import
import {loadStripe} from '@stripe/stripe-js/pure';
import { OrdersService } from '../../services/orders/orders.service';
import { StripeService } from '../../services/orders/stripe.service';
import { PaymentIntentResponse } from '../../models/payment-intent-response';
import { BehaviorSubject } from 'rxjs';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { environment } from 'apps/user-app/src/environments/environment';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { StripeElementLocale } from '@stripe/stripe-js';

@UntilDestroy()
@Component({
  selector: 'web-foodis-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrl: './stripe-checkout.component.scss',
})
export class StripeCheckoutComponent implements OnInit {

  // Stripe handling
  private stripe: any;
  private elements: any; // Stripe elements

  // Param from URL
  public orderId: string = "";

  // Data received from API
  public paymentIntent$: BehaviorSubject<PaymentIntentResponse | null> = new BehaviorSubject<PaymentIntentResponse | null>(null);
  
  // Show / hide action buttons & total price based on this flag
  public stripeLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private ordersService: OrdersService,
    private stripeService: StripeService,
    private localeService: LocaleService
  ) {

  }

  public ngOnInit(): void {

    // Takes the Order ID
    this.checkForRouteParams();

    // Init SDK with Publishable Key
    this.initStripe().then(() => {

      // Fetch Client Secret from API
      this.fetchClientSecret();
    })
  }

  // Setting the order id
  private checkForRouteParams() {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
			const orderId = params.get('orderid');
			if (orderId) {
        this.orderId = orderId;
			}
		});
  }

  // Loading Stripe SDK via Publishable key
  private async initStripe() {
    this.stripeLoading$.next(true);
    this.stripe = await loadStripe(environment.STRIPE_PUBLISHABLE_KEY, {
      locale: this.localeService.getLanguageISO2() as StripeElementLocale
    });
  }

  // Interrogate API to get the client secret (needed by Stripe UI rendering)
  private fetchClientSecret() {
    if (this.orderId) {
      this.stripeService.requestPaymentOrderAPI$(this.orderId).subscribe((payemntIntent: PaymentIntentResponse) => {
        // Set current data
        this.paymentIntent$.next(payemntIntent);

        // Initialize Stripe UI based on the client secret
        this.initStripeUI();
      })
    } else {
      console.warn("Missing Stripe Order ID");
    }
  }

  // This is the Stripe UI (its own) which can be rendered based on the client secrent (and other configs)
  private initStripeUI() {
    const paymentIntent = this.paymentIntent$.getValue();

    // Regular UI
    this.elements = this.stripe.elements({
      appearance: {
        theme: 'stripe'
      },
      clientSecret: paymentIntent?.client_secret
    })
  
    const paymentElement = this.elements.create("payment", {
      layout: "tabs",
      // paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna']
    });
    paymentElement.mount("#payment-element");

    // loading Stripe finished
    this.stripeLoading$.next(false);
  }

  // Confirm button
  public onConfirmClick() {
    this.handleSubmit().then(() => {
      this.ordersService.mockIsPaid(); // delete this
      //this.router.navigateByUrl('/user/order/' + this.orderId);
    })
  }

  // TODO: remove this - now is kept only for testing
  public onAutoCompleteClick() {
    window.open("https://docs.stripe.com/testing?testing-method=card-numbers", "_blank");
  }

  // Client-side submission to Stripe SDK => Which goes to Stripe API and confirms the order
  // TODO: this should be done on our API instead - so then web client asks our API for confirmation and then handles the success.
  private async handleSubmit() {
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.protocol + "//" + window.location.host + "/user/order/" + this.orderId,
      },
    });
  
    if (error.type === "card_error" || error.type === "validation_error") {
      this.toasterService.showError("Error", error.message);
    } else {
      this.toasterService.showError("Error", "An unexpected error occurred.");
    }
  }
}
