import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TopNavBarService } from '../../services/navbar/top-navbar.service';
import { environment } from 'apps/user-app/src/environments/environment';
import { AuthService } from 'libs/shared-services/src/lib/auth.service';

@UntilDestroy()
@Component({
  selector: 'web-foodis-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrl: './/payment-page.component.scss',
})
export class UserPaymentPageComponent implements OnInit {

  constructor(
    private topNavBarService: TopNavBarService,
    private authService: AuthService,
    private router: Router
  ) {

  }

  public ngOnInit(): void {
    if (!this.authService.isLoggedIn()) {
       // redirect to home page
      this.router.navigateByUrl("/dashboard");
    }
  }
}
