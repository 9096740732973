// Stripe payment intent

export class PaymentIntentResponse {
    id: string = "";
    amount: number = 0;
    amount_capturable: number = 0;
    automatic_payment_methods: AutomaticPaymentMethods = new AutomaticPaymentMethods();
    capture_method: string = "";
    client_secret: string = "";
    confirmation_method: string = "";
    created: number = 0;
    currency: string = "";
    customer: string = "";
    description: string = "";
    last_payment_error: string = "";
    livemode: boolean = false;
    metadata: any = {};
    payment_method: string = "";
}

export class AutomaticPaymentMethods {
    enabled: boolean = false;
}