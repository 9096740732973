import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { BlurBackgroundService } from "./services/background-handler/blur-background.service";
import { SharedServicesInit } from 'libs/shared-services/src/lib/shared-services-init';
import { MainServicesInitService } from './services/main-services-init.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'foodis-user-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    public allLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public baseServicesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public appServicesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public isStaging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isBlurred$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
      private sharedServicesInit: SharedServicesInit,
      private appServicesInit: MainServicesInitService,
      private blurBackgroundService: BlurBackgroundService,
      private ref: ChangeDetectorRef
    ) {
    }

    public ngOnInit() {

      this.blurBackgroundService.getBlurBackgroundState$().subscribe((changed) => {
        this.isBlurred$.next(changed);
        this.ref.detectChanges(); // keep this here. Do not access the background service observable directly into template as it doesn't work
      })
      
      // wait for base services to load first
      this.sharedServicesInit.allServicesLoaded$().subscribe((value) => {
        if (value) {
          this.baseServicesLoaded$.next(true);          
          this.loadAppServices();
        }
      });   

      combineLatest([
          this.baseServicesLoaded$,
          this.appServicesInit.allServicesLoaded$()
          ]).subscribe(
          ([baseFinished, appServices]) => {
            if (appServices) {
              this.appServicesLoaded$.next(true);
            }
            if (baseFinished && appServices) {
              this.allLoaded$.next(true);
            }
          }
      );     
      
      this.isStaging$.next(environment.DEBUG_STAGING_BADGE);
    }

    private loadAppServices() {
      this.appServicesInit.initServices();
    }
}
