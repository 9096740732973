<div class="main-side-container">
    <div class="menu-items-container">
        <ng-container *ngFor="let item of menuList | async; index as i">
            <div class="menu-item">
                <a [routerLink]="item.navRoute">
                    <user-item-side-menu [item]="item"></user-item-side-menu>
                </a>
            </div>
        </ng-container>
    </div>
</div>