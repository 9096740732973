<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
       {{ 'dashboard_modal_filter_title' | translate }}
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'dashboard_modal_filter_close' | translate }}
        </div>
    </div>
</div>

<div class="modal-content">

  <div class="flex flex-col mr-3 ml-3">

    <div class="container-open-now-clear">
        <!-- Opened now -->
        <div class="open-now-container">
          <div class="open-now-icon">
            <img [src]="'apps/user-app/src/assets/open-hours-clock.svg'"/>
          </div>
          <div class="subtitle-text">
            {{ 'dashboard_modal_filter_open_now' | translate }}
          </div>
          <!-- Toggle -->
          <div class="status-toggle-switch">
              <mat-slide-toggle 
                  [color]="'primary'" [disableRipple]="false" 
                  [checked]="(tempData$ | async)?.onlyOpen || false"
                  (change)="onOpenNowToggleClick($event)">
              </mat-slide-toggle>
          </div>
        </div> 

        <!-- Clear/Reset filters -->
        <div class="reset-filters" *ngIf="(showClearFilters$ | async) || false">
          <fs-button [text]="'dashboard_missing_restaurants_button' | translate"
                  [type]="'raised'" [leadingIcon]="'clear'" (onPress)="onPressClearFilters()">
          </fs-button>
        </div>
    </div>
    
    
    <!-- Categories -->
    <div class="categories-container">
      <div class="flex flex-row subtitle-text mb-3">
        <img class="mr-2" [src]="'apps/user-app/src/assets/food-categories-restaurant.svg'"/> {{ 'dashboard_modal_filter_categories' | translate }}
      </div>

      <div class="flex">
        <mat-chip-listbox aria-label="Category cuisine selection" [multiple]="true">
          <!-- Category item -->
          <ng-container *ngFor="let item of (tempData$ | async)?.cuisineCategories; index as i ">
            <mat-chip-option [selected]="item.selected" color="primary" [id]="'' + item" (selectionChange)="onCuisineChange($event, item)">
                {{ item.lokaKey | translate }}
            </mat-chip-option>
          </ng-container>
        </mat-chip-listbox>
      </div>
    </div>
  </div>

  <!-- Clear/Reset filters -->
  <div class="reset-filters-mobile" *ngIf="(showClearFilters$ | async) || false">
    <fs-button [text]="'dashboard_missing_restaurants_button' | translate"
            [type]="'raised'" [leadingIcon]="'clear'" (onPress)="onPressClearFilters()">
    </fs-button>
  </div>
  
  <!-- Add or Save changes button -->
  <div class="modal-bottom-action">
    <fs-button [text]="'dashboard_modal_filter_ok' | translate" [style]="'full-width-large-height'"
                [type]="'raised-primary'" (onPress)="onOKButtonClick()" [disabled]="(isButtonDisabled$() | async) || false">
    ></fs-button>
  </div>

</div>

