import { Injectable, OnDestroy} from '@angular/core';
import { Language, LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';
import { UserSelfResponse } from '../../models/user-self-response';
import { ApiBaseService } from 'libs/shared-services/src/lib/api-base.service';
import { environment } from '../../../environments/environment';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CookieService } from 'libs/shared-services/src/lib/cookie.service';
import { AuthService } from 'libs/shared-services/src/lib/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { Product } from 'libs/shared-models/src/lib/product-type';
import { DeliveryOption } from 'libs/shared-models/src/lib/delivery-option';

@Injectable({
    providedIn: 'root',
})
export class TopNavBarService extends BaseLoadingService implements OnDestroy {
    
    private userSelfData$: BehaviorSubject<UserSelfResponse> = new BehaviorSubject<UserSelfResponse>(new UserSelfResponse());
    private deliveryOption$: BehaviorSubject<DeliveryOption>;
    
    private forceOpenAddress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private mobileMenuOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);    
    public hideDeliveryButtons$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isOrderBasketOpenInPopup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private lastStoredISOLanguage: string = "";

    private routeSub: Subscription;

    constructor(        
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private cookieService: CookieService,
        private authService: AuthService,
        private localeService: LocaleService,
        private router: Router
    ) {
        super();           
    }    

    public initNavBar() {
        // delivery type
        this.checkExistingDeliveryCookie();
        
        // Check if we should show the delivery buttons (we hide in the restaurant page)
        this.checkRouteUrl(this.router.url);
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.checkRouteUrl(event.url);
            }
        });

        // self data
        if (this.authService.isLoggedIn()) {
            this.fetchSelfData();
        } else {
            this.setFinished();
        }

        // language updates
        this.listenLanguageChange();
    }


    /*
        Self user data
    */
    public fetchSelfData() {
        this.apiService.get(environment.API_USER_SELF).subscribe({
            next: (res: any) => {
                const obj =  Object.assign(new UserSelfResponse(), res);
                this.setSelfData(obj);
                this.setFinished();
                this.redirectToSelfProduct();
            },
            error: (err) => {
                this.toasterService.showWarning(this.localeService.translate("nav_bar_token_expired"), err?.error?.message);
                window.location.href = environment.PRODUCT_URLS.ACCOUNT;
            }
        });
    }

    public getSelfData$(): Observable<UserSelfResponse> {
        return this.userSelfData$.asObservable();
    }

    public getSelfData(): UserSelfResponse {
        return this.userSelfData$.getValue();
    }

    public setSelfData(data: UserSelfResponse) {
        this.userSelfData$.next(data);     
    }    
    
    public clearSelfData() {
        this.userSelfData$.next(new UserSelfResponse());
    }

    private redirectToSelfProduct() {
        const data = this.getSelfData();
        const product = data.product.toLowerCase();

        // ignore if product is the expected one (restaurant, as we're in the restaurant service / subdomain)
        if (product === Product.USER_APP) {
            return;
        } else {
            // redirect the user to the correct product
            let redirectUrl = "";
            switch (product) {
                case Product.RESTAURANT: {
                    redirectUrl = environment.PRODUCT_URLS.RESTAURANT;
                    break;
                }
                case Product.DRIVER: {
                    redirectUrl = environment.PRODUCT_URLS.DRIVER;
                    break;
                }
                case Product.ADMIN:
                case "staff": {
                    redirectUrl = environment.PRODUCT_URLS.ADMIN;
                    break;
                }
                default: {
                    redirectUrl = environment.PRODUCT_URLS.LANDING_PAGE;
                    break;
                }
            }
            // Comment this line when you need to test with localhost development - otherwise you'll always get redirected to deployed version
            window.location.href = redirectUrl;
        }
    }
    
    
    private checkRouteUrl(value: string) {
        // If the user is in the Restaurant Page or in the Checkout Page, then hide the delivey buttons 
        this.hideDeliveryButtons$.next(value.includes("/restaurant/") || value.includes("/checkout"));
    }



    /*
        Delivery vs pickup / cookie check/store
    */
    private checkExistingDeliveryCookie() {
        const option = this.cookieService.getCookie("delivery_type");
        if (!!option && Object.values(DeliveryOption).includes(option as DeliveryOption)) {
            this.setDeliveryOption(option as DeliveryOption);
        } else {
            // default
            this.setDeliveryOption(DeliveryOption.DELIVERY);
        }
    }

    public setDeliveryOption(option: DeliveryOption) {
        if (!this.deliveryOption$) {
            this.deliveryOption$ = new BehaviorSubject<DeliveryOption>(option);
        } else {
            this.deliveryOption$.next(option);            
        }
        this.cookieService.setCookie('delivery_type', option);
    }   

    public getDeliveryOption$(): Observable<DeliveryOption> {
        return this.deliveryOption$.asObservable();
    }

    public getDeliveryOption(): DeliveryOption {
        return this.deliveryOption$.getValue();
    }

    public setForceAddressOpen(value: boolean) {
        this.forceOpenAddress$.next(value);
    }

    public getForceAddressOpen$(): Observable<boolean> {
        return this.forceOpenAddress$.asObservable();
    }

    public mobileMenuVisible$(): Observable<boolean> {
        return this.mobileMenuOpen$.asObservable();
    }

    public setMobileMenuVisible(value: boolean) {
        this.mobileMenuOpen$.next(value);
    }

    public toggleOrderCart() {
        this.isOrderBasketOpenInPopup$.next(!this.isOrderBasketOpenInPopup$.getValue());
    }

    public openOrderBasketInPopup() {
        this.isOrderBasketOpenInPopup$.next(true);
    }

    public closeOrderBasketInPopup() {
        this.isOrderBasketOpenInPopup$.next(false);
    }


    /*
        Language change => Go update the API too 
    */
    private listenLanguageChange() {
        this.localeService.getLanguage$().subscribe((lang: Language) => {            
            if (!!lang?.iso2) {
                if (!this.lastStoredISOLanguage) {
                    this.lastStoredISOLanguage = lang.iso2;
                } else {
                    if (this.lastStoredISOLanguage !== lang.iso2) {
                        this.setLanguageApi(lang.iso2);
                    }
                }
            }
        })
    }

    private setLanguageApi(language: string) {
        this.apiService.patch(environment.API_USER_SELF, {
            id: this.getSelfData().id,
            userLanguage: language
        }).subscribe({
            next: (res: any) => {
                // don't show any success toast - this is more like an update for the backend to know, as the change is fully frontend
                this.setSelfData(res);                
            },
            error: (err) => {
                // don't show anything, just log it in console if it fails
                console.error("[API request failed] - Self endpoint language update")
            }
        });
    }


    public ngOnDestroy(): void {
        if (this.routeSub) {
          this.routeSub.unsubscribe();
        }
    }
}
