<div class="main-container">

    <!-- Left lateral menu -->
    <div class="left-side">
        <user-side-menu [selected]="selectedSection"></user-side-menu>
    </div>

    <!-- Content of the /user/* path components-->
    <div class="right-side">
        <router-outlet></router-outlet>
    </div>    
</div>