<div class="main-container">

    <div class="mb-5">
        {{ 'nav_bar_menu_address' | translate }}
    </div>
    
    <!-- Address exist -->
    <ng-container *ngIf="((allAddresses$ | async) || []).length > 0; else missingAddress">

        <!-- Show default address -->
        <ng-container *ngIf="(currentAddress$ | async)?.latitude && (currentAddress$ | async)?.longitude">
            <div class="default-address-container">

                <delivery-address
                    [usage]="'user-address-page'"
                ></delivery-address>

                <div class="address-map">
                    <fs-map-lite 
                        [address]="(currentAddress$ | async) || null"
                        [disableDefaultUI]="true"
                        [width]="300"
                        [height]="200">
                    </fs-map-lite>
                </div>

            </div>
        </ng-container>

    </ng-container>

    <!-- User has no address -->
    <ng-template #missingAddress>

        <!-- 
            TODO: maybe create a new reusable component for the Missing address (as it's also in Dashboard)
        --> 

        <div class="missing-address-container">
            <!-- Image -->
            <div class="missing-address-image">
                <img [src]="'apps/user-app/src/assets/missing-address.jpg'"/>
            </div>

            <!-- Texts -->
            <div class="missing-address-text-container">
                <!-- Title -->
                <div class="missing-address-title">
                    <!-- Image (mobile) -->
                    <div class="missing-address-image-mobile">
                        <img [src]="'apps/user-app/src/assets/missing-address.jpg'"/>
                    </div>
                    <div>{{ "dashboard_missing_address_title" |  translate }}</div>
                    <img class="missing-address-title-image-src" [src]="'apps/user-app/src/assets/address-pin-google-map.png'"/>
                </div>
                <!-- Body -->
                <div class="missing-address-body">
                    {{ "dashboard_missing_address_body" |  translate }}
                </div>
                <!-- Button -->
                <div class="missing-address-button">
                    <!-- Button - Create product -->
                    <fs-button [text]="'dashboard_missing_address_button' | translate"
                            [type]="'raised-primary'" [leadingIcon]="'add'" (onPress)="onPressMissingAddress()">
                    </fs-button>
                </div>
            </div>
        </div>
    </ng-template>

</div>