import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'web-foodis-user-section',
  templateUrl: './user-section.component.html',
  styleUrl: './user-section.component.scss',
})
export class UserSectionComponent implements OnInit {

  public selectedSection: string = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  public ngOnInit(): void {
    this.checkRouter();
    
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkRouter();
      }
  });
  }

  private checkRouter() {
    const path = this.router.url;
    if (path.includes('user/order')) {
      this.selectedSection = "ORDERS";
    } else if (path.includes('user/profile')) {
      this.selectedSection = "PROFILE";
    } else if (path.includes('user/payment')) {
      this.selectedSection = "PAYMENT";
    } else if (path.includes('user/address')) {
      this.selectedSection = "ADDRESS";
    }
  }
}
