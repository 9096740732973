import { Component, Input, OnInit } from '@angular/core';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';

@UntilDestroy()
@Component({
  selector: 'pick-up-address',
  templateUrl: './pick-up-address.component.html',
  styleUrls: ['./pick-up-address.component.scss'],
})
export class PickUpAddressComponent implements OnInit {

  public pickupAddress$: BehaviorSubject<AddressResponse | null> = new BehaviorSubject<AddressResponse | null>(null);

  @Input() usage: string = "default"; // ["default", "order-page"]

  @Input() restaurantName: string = "";

  @Input() set inputAddress(address: AddressResponse | undefined | null) {
    if (address) {
      this.pickupAddress$.next(address);
    }
  }

  constructor(
    private toasterService: ToasterService,
    private localeService: LocaleService
  ) {
  }

  public ngOnInit(): void {

  }

  /*
    Copy to clipboard
  */
    public onCopyClick() {
      const address = this.pickupAddress$.getValue();
      const coppiedAddress = address?.streetName + " " + address?.streetNumber + ", " + address?.district + " " + address?.postalCode + ", " + address?.city;
      // Copy the text inside the text field
      navigator.clipboard.writeText(coppiedAddress);
  
      this.toasterService.showInfo("",this.localeService.translate("restaurant_page_info_address_copy"));
    }
  
    /*
      Open External Google maps
    */
    public onExternalMapsClick() {
      const address = this.pickupAddress$.getValue();
      const url = "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(address?.latitude + "," + address?.longitude); // + data.restaurantInfo.name
      window.open(url, '_blank');
    }
  



}
