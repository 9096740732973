<!-- Staging badge -->
<ng-container *ngIf="isStaging$ | async">
    <fs-debug-badge></fs-debug-badge>
</ng-container>

<!-- Main content -->
<ng-container *ngIf="allLoaded$ | async; else loadingScreen">
    
    <!-- Fixed top nav bar-->
    <top-nav-bar></top-nav-bar>

    <!-- All the other content-->
   
    <div class="main-app-container" [ngClass]="{ 'blurred': (isBlurred$ | async) }">
        <router-outlet></router-outlet>
    </div>
    <!-- Order basket bottom button -->
    <order-basket-bottom-button></order-basket-bottom-button>
    
</ng-container>

<ng-template #loadingScreen>
    <!-- Loading -->
</ng-template>
