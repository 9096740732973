<ng-container *ngIf="(loadingApiData$ | async); else apiDataLoaded">
    <div class="loading-api-spinner">
        <fs-loading-spinner [variant]="LOADING_STYLE.CIRCLE_GRAY"></fs-loading-spinner>
    </div>
</ng-container>

<ng-template #apiDataLoaded>
    <div class="main-container">
        <div class="title-container">
            <!-- Part 1 -->
            <div class="title-container-part-1">
                <a [href]="'user/orders'">
                    <div class="orders-button">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        {{ 'order_page_orders_link' | translate }}
                    </div>
                </a>
                <span class="ml-2 mr-2"> / </span> 
                {{ 'order_page_order_title' | translate }} <span class="order-id"># {{ (orderData$ | async)?.slug }}</span>
            </div>
            
            <!-- Part 2 -->
            <div class="timestamp-container">
                <mat-icon class="timestamp-icon">access_time</mat-icon> - <span class="ml-1">{{ (orderData$ | async)?.createdDate | date:"dd.MMM, hh:mm"}} </span>
            </div>
            <div class="simulate-button" *ngIf="showSimulateButton$ | async">
                <fs-button class="simulate-button" [text]="'Simulate next step' | translate" [type]="'raised'" (onPress)="onSimulateNextStep()" [style]="'full-width'"></fs-button>
            </div>  
               
        </div>
        
        
        <!-- Main content -->
        <div class="content-container">
    
            <!-- Status and address -->
            <div class="left-side">
                <div class="status-container">
    
                    <div [ngSwitch]="(orderData$ | async)?.status" class="status-image-container">
    
                        <!-- CREATED -->
                        <div *ngSwitchCase="OrderStatus.CREATED" class="status-image-container">
                            <!-- CASH PAYMENT -->
                            <ng-container *ngIf="(orderData$ | async)?.paymentType === PaymentOption.CASH; else paymentNeeded">
                                <div class="text-icon-container pb-1">
                                    <div class="icon-container">
                                        <fs-loading-spinner [variant]="LOADING_STYLE.CIRCLE_GRAY"></fs-loading-spinner>
                                    </div>
                                    <div class="status-text">{{ "order_top_status_message_waiting_restaurant" | translate }}</div>
                                </div>
                                <img class="status-image" [src]="'apps/user-app/src/assets/order-state-waiting-restaurant.jpg'"/> 
                            </ng-container>
    
                            <!-- ONLINE PAYMENT needed -->
                            <ng-template #paymentNeeded>
                                <div class="text-icon-container">
                                    <div class="icon-container">
                                        <mat-icon class="payment-needed-mat-icon">credit_card</mat-icon>
                                    </div>
                                    <div class="status-text">
                                        {{ "order_top_status_message_not_paid_p1" | translate }}
                                        <span class="font-medium"><fs-link [text]="'order_top_status_message_not_paid_p2' | translate" [url]="'stripe/' + (orderData$ | async)?.id"></fs-link></span>
                                        {{ "order_top_status_message_not_paid_p3" | translate }}
                                    </div>
                                </div>
                                <img class="status-image" [src]="'apps/user-app/src/assets/order-state-not-paid.jpg'"/>
                            </ng-template>
                        </div>
    
                        <!-- PAID -->
                        <div *ngSwitchCase="OrderStatus.PAID" class="status-image-container">
                            <div class="text-icon-container pb-1">
                                <div class="icon-container">
                                    <fs-loading-spinner [variant]="LOADING_STYLE.CIRCLE_GRAY"></fs-loading-spinner>
                                </div>
                                <div class="status-text">{{ "order_top_status_message_waiting_restaurant" | translate }}</div>
                            </div>
                            <img class="status-image" [src]="'apps/user-app/src/assets/order-state-waiting-restaurant.jpg'"/>
                        </div>
    
                        <!-- ACCEPTED BY RESTAURANT -->
                        <div *ngSwitchCase="OrderStatus.ACCEPTED_BY_RESTAURANT" class="status-image-container">
                            <div class="text-icon-container">
                                <div class="icon-container">
                                    <img class="icon-kitchen-chef" [src]="'apps/user-app/src/assets/order-icon-kitchen-chef.png'"/>
                                </div>
                                <div class="status-text">
                                    {{ "order_top_status_message_in_preparation" | translate }}
                                </div>
                            </div>
                            <img class="status-image" [src]="'apps/user-app/src/assets/order-state-in-preparation.jpg'"/>
                        </div>
    
                        <!-- IN DELIVERY -->
                        <div *ngSwitchCase="OrderStatus.IN_DELIVERY" class="status-image-container">
                            <!-- WITH DELIVERY -->
                            <ng-container *ngIf="(orderData$ | async)?.deliveryOption === DeliveryOption.DELIVERY; else pickUpReady">
                                <div class="text-icon-container">
                                    <div class="icon-container">
                                        <img class="icon-delivery" [src]="'apps/user-app/src/assets/delivery-type-bicycle-selected.svg'"/>
                                    </div>
                                    <div class="status-text">{{ "order_top_status_message_in_delivery" | translate }}</div>
                                </div>  
                                <img class="status-image" [src]="'apps/user-app/src/assets/order-state-in-delivery.jpg'"/>
                            </ng-container>
                            <!-- PICK-UP -->
                            <ng-template #pickUpReady>
                                <div class="text-icon-container">
                                    <div class="icon-container">
                                        <img class="icon-delivery" [src]="'apps/user-app/src/assets/delivery-type-pick-up-hand-selected.svg'"/>
                                    </div>
                                    <div class="status-text">{{ "order_top_status_message_pickup_ready" | translate }}</div>
                                </div>
                                <img class="status-image" [src]="'apps/user-app/src/assets/order-state-pickup-ready.jpg'"/>
                            </ng-template>
                        </div>
    
                        <!-- DELIVERED -->
                        <div *ngSwitchCase="OrderStatus.DELIVERED" class="status-image-container">
                            <div class="text-icon-container">
                                <div class="icon-container">
                                    <img class="icon-delivery" [src]="'apps/user-app/src/assets/check-ok.svg'"/>
                                </div>
                                <div class="status-text">{{
                                    ((orderData$ | async)?.deliveryOption === DeliveryOption.DELIVERY ?  
                                        "order_top_status_message_delivered" : "order_top_status_message_pickedup") | translate }}</div>
                             </div>
                            <img class="status-image" [src]="'apps/user-app/src/assets/order-state-delivered.jpg'"/>
                        </div>
                        <div *ngSwitchDefault>
                            <div class="text-icon-container">
                                <div class="icon-container">
                                    <img class="icon-delivery" [src]="'apps/user-app/src/assets/check-ok.svg'"/>
                                </div>
                                <div class="status-text">{{ "order_top_status_message_waiting_restaurant" | translate }}</div>
                            </div>
                            <img class="status-image" [src]="'apps/user-app/src/assets/order-state-waiting-restaurant.jpg'"/>
                        </div>
                      </div>
                </div>
    
                <div class="steps-address-container">
    
                    <!-- Steps list & status -->
                    <div class="steps-container">
                        <vertical-status-progress 
                            [items]="verticalStepsList$ | async">
                        </vertical-status-progress>
                    </div>
    
                    <div class="address-container">
    
                        <!-- Delivery address -->
                        <ng-container *ngIf="((orderData$ | async)?.deliveryOption) === DeliveryOption.DELIVERY">
                            <delivery-address
                                [usage]="'order-page'"
                                [inputAddress]="(orderData$ | async)?.userAddress">
                            </delivery-address>
                        </ng-container>
                       
                        <!-- Pick-up address -->
                        <ng-container *ngIf="((orderData$ | async)?.deliveryOption) === DeliveryOption.PICKUP">
                            <pick-up-address
                                [inputAddress]="(orderData$ | async)?.restaurantAddress"
                                [restaurantName]="((orderData$ | async)?.restaurantName) || ''"
                                [usage]="'order-page'">
                            </pick-up-address>
                        </ng-container>
                    </div>
                </div>
            </div>
        
            <!-- Basket view -->
            <div class="right-side">
                <!-- Basket -->
                <order-basket 
                    [isOrderPageMode]="true"
                    [isCheckoutMode]="true"
                    [orderPageData]="(transformedCartData$ | async)">
                </order-basket>
    
                 <!-- Payment details -->
                 <div class="payment-details">
    
                    <!-- Not paid, online payment needed -->
                    <ng-container *ngIf="
                        (orderData$ | async)?.status === OrderStatus.CREATED &&
                        (orderData$ | async)?.paymentType === PaymentOption.ONLINE">
                        <fs-button 
                            class="mt-4 mb-2"
                            [text]="'order_page_pay_online_button' | translate" 
                            [style]="'full-width-large-height-no-margin'"
                            [type]="'raised-primary'"
                            (onPress)="onPressPayOnline()">
                        </fs-button>
                    </ng-container>
    
                    <!-- CASH selected, pay at pickup location -->
                    <ng-container *ngIf="(orderData$ | async)?.paymentType === PaymentOption.CASH">
                        <div class="pay-location">
                            <div class="payment-option-logo">
                                <img class="pt-2" [src]="'apps/user-app/src/assets/checkout-cash-selected.svg'">
                            </div>
                            <span class="paid-online-text">{{ "order_page_pay_location" | translate }}</span>
                        </div>
                    </ng-container>
    
                    <!-- Already paid online -->
                    <ng-container *ngIf="
                        (orderData$ | async)?.paymentType === PaymentOption.ONLINE &&
                            ((orderData$ | async)?.status === OrderStatus.PAID ||
                            (orderData$ | async)?.status === OrderStatus.ACCEPTED_BY_RESTAURANT ||
                            (orderData$ | async)?.status === OrderStatus.IN_DELIVERY ||
                            (orderData$ | async)?.status === OrderStatus.DELIVERED)">
                        <div class="paid-online">
                            <div class="payment-option-logo">
                                <img [src]="'apps/user-app/src/assets/checkout-online-selected.svg' ">
                            </div>
                            <span class="paid-online-text">{{ "order_page_paid_online" | translate }}</span>
                            <span class="paid-online-with">{{ "order_page_paid_with" | translate }}</span>
                            <img class="paid-online-stripe" [src]="'apps/user-app/src/assets/checkout-stripe.svg'"/>
                        </div>
                    </ng-container>
                 </div>
            </div>
        </div>
    
    </div>
</ng-template>
