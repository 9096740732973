import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserSelfResponse } from "../../models/user-self-response";
import { AuthService } from "libs/shared-services/src/lib/auth.service";
import { BehaviorSubject, Observable, combineLatest, debounce, interval, map } from "rxjs";
import { CuisineCategory, DashboardService } from '../../services/dashboard/dashboard-service';
import { QueryRestaurantList, RestaurantsListService } from '../../services/restaurants-list/restaurants-list.service';
import { RestaurantSearchItemResponse } from '../../services/restaurants-list/model/restaurant-search-item-response';
import { UserAddressFacade } from '../../services/user-address/user-address.facade.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeliveryOption, TopNavBarService } from '../../services/navbar/top-navbar.service';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { RestaurantCategoryEnum } from 'libs/shared-models/src/lib/restaurant/restaurant-category-enum';
import { ModalService } from 'libs/shared-ui/src/lib/modal-service/modal-service.service';
import { CustomLocalFilters } from './modals/filters-modal/model/custom-local-filters';
import { FiltersModalComponent } from './modals/filters-modal/filters-modal.component';

@UntilDestroy()
@Component({
  selector: 'web-foodis-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

    private userData: UserSelfResponse = new UserSelfResponse();
    private token: string = "";

    private clearButtonVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // Small functionality

    public categoriesList$: Observable<CuisineCategory[]> = this.dashboardService.getAllCuisinesList$();

    public searchResults$: Observable<RestaurantSearchItemResponse[]> = this.restaurantsListService.getList$();
    public userAddress$: Observable<AddressResponse> = this.userAddressFacade.getCurrentAddress$();

    public hasActivatedFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public deliveryOption$: Observable<DeliveryOption> = this.topNavBarService.getDeliveryOption$();
    public DeliveryOption: typeof DeliveryOption = DeliveryOption;

    @ViewChild('restaurantsSearchField') searchField: ElementRef | undefined;

    constructor(
        private authService: AuthService,
        private dashboardService: DashboardService,
        private restaurantsListService: RestaurantsListService,
        private userAddressFacade: UserAddressFacade,
        private topNavBarService: TopNavBarService,
        private modalService: ModalService
    ) {
    }

    public ngOnInit() {

        combineLatest([
            this.userAddressFacade.getCurrentAddress$(),
            this.topNavBarService.getDeliveryOption$(),
            this.dashboardService.getAllCuisinesList$(),
            this.dashboardService.getOpenNowFilter$()
        ])
        .pipe(untilDestroyed(this))
        .pipe(debounce(() => interval(0))) // some behaviour subjects are triggered in parallel at the same time and we don't want to catch it twice
        .subscribe(
            ([address, deliveryOption, cuisineCategories, openOnly]) => {

            // address 
            const lon: number = address.longitude;
            const lat: number = address.latitude;           
            
            // delivery
            const delivery: DeliveryOption = deliveryOption;

            // cuisine categories
            const categories = cuisineCategories.filter((c) => c.selected);

            // open only
            const open = openOnly;

            // check if it has filters enabled - so the values are not the default ones anymore
            const hasFiltersActivated = (open !== this.dashboardService.DEFAULT_SHOW_OPEN) || (categories.length > 0);            
            this.hasActivatedFilters$.next(hasFiltersActivated);

            // create the API model to be sent and call api 
            if (lon && lat && delivery) {
                const query: QueryRestaurantList = {
                    lon: lon,
                    lat: lat,
                    deliveryOption: delivery,
                    open: open,
                    pageNumber: 1,
                    pageSize: 100  
                }    

                if (categories.length > 0) {
                    query.restaurantCategory = categories.map((c) => c.id) as RestaurantCategoryEnum[];
                }
                
                // API call to get the restaurants list
                this.restaurantsListService.fetchRestaurantsList(query);
            }
        });   
    }

    public getToken() {
        return this.token;
    }

    public getData() {
        return this.userData;
    }

    public inputValueChanged() {
        if (this.searchField?.nativeElement.value) {
            this.clearButtonVisibility$.next(true);
        } else {
            this.clearButtonVisibility$.next(false);
        }
    }

    /*
       Clear button
    */
    public isClearButtonVisible$(): Observable<boolean> {
        return this.clearButtonVisibility$.asObservable();
    }

    public onClearClick() {
        if (this.searchField) {
            this.searchField.nativeElement.value = "";
        }
        this.clearButtonVisibility$.next(false);
    }

    /*
        Missing address
    */
    public onPressMissingAddress() {
        this.topNavBarService.setForceAddressOpen(true);
    }

    /*
        Cuisine category
    */
    public onPressCuisineCategory(c: CuisineCategory) {
        this.dashboardService.cuisineCategoryClick(c);
    }

    public onPressClearFilters() {
        // clear cuisine category filters:
        this.onPressClearCategoryFilters();

        // clear the open filter
        this.dashboardService.setOpenNowFilter(this.dashboardService.DEFAULT_SHOW_OPEN);
    }
    
    public onPressClearCategoryFilters() {
        // clear cuisine category filters:
        this.dashboardService.clearCuisineCategoryFilter();
    }

    public numberSelectedCuisines$(): Observable<number> {
        return this.categoriesList$.pipe(map((list) => list.reduce(
            (accumulator, currentValue) => accumulator + (currentValue.selected ? 1 : 0), 0)));
    }

    /*
        Filters button
    */
    public onFiltersClick() {
        const filtersData = new CustomLocalFilters();
        filtersData.onlyOpen = this.dashboardService.getOpenNowFilter();
        filtersData.cuisineCategories = this.dashboardService.getAllCuisinesList();
        this.openFiltersModal(filtersData);
    }

    private openFiltersModal(modalData: CustomLocalFilters) {
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.data = modalData;
        modalConfig.width = "700px";
        modalConfig.disableClose = true;
        this.modalService.openCustomDialog$(FiltersModalComponent, modalConfig).subscribe((callbackResponse) => {   
            if (callbackResponse && callbackResponse.data) {
                const data: CustomLocalFilters = callbackResponse.data;
                this.dashboardService.onModalFiltersUpdate(data);
            }
        })
    }

    /*
        Business hours (outside or restaurant closed)
    */
    public isOutsideBusinessHours(item: RestaurantSearchItemResponse): boolean {
        return this.restaurantsListService.isOutsideBusinessHours(item);
    }
    public getNextAvailableHours(item: RestaurantSearchItemResponse): string {
        return this.restaurantsListService.getNextAvailableHours(item);
    }

    /*
        Distance (km) from restaurant's geo-location points to my current address points
    */
   
    public distanceToMyAddress(item: RestaurantSearchItemResponse): number {
        const userAddress = this.userAddressFacade.getCurrentAddress();
        return this.restaurantsListService.getDistanceToMyAddress(item, userAddress);
    }

    /*
        Buttons to switch the delivery option
    */
    public onPressTryPickup() {
        this.topNavBarService.setDeliveryOption(DeliveryOption.PICKUP);
    }

    public onPressTryDelivery() {
        this.topNavBarService.setDeliveryOption(DeliveryOption.DELIVERY);
    }
}
