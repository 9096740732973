import { Component, EventEmitter, Output } from '@angular/core';
import {Product} from "libs/shared-models/src/lib/product-type";
import {CurrentProductService} from "libs/shared-services/src/lib/current-product.service";
import {Router} from "@angular/router";

@Component({
  selector: 'logo-user-app',
  templateUrl: './logo-user-app.component.html',
  styleUrls: ['./logo-user-app.component.scss'],
})
export class LogoUserAppComponent {

    private product: Product = Product.USER_APP;

    @Output() logoPress: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    constructor(
        private currentProductService: CurrentProductService,
        private router: Router
    ) {
    }

    public ngOnInit() {
    }

    public getProductType(): Product {
        return this.product;
    }

    public pressed() {
        this.logoPress.emit(true);
        this.router.navigateByUrl("dashboard");
    }
}
