import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { OrderResponse, OrderStatus } from 'libs/shared-models/src/lib/order/order-response';
import { OrdersService } from '../../services/orders/orders.service';
import { OrderCart } from '../../services/order-basket/model/order-basket.model';
import { VerticalStatusItem, VerticalStatusItemState } from '../../components/vertical-status-progress/vertical-status-progress.component';
import { PaymentOption } from '../checkout/checkout-page.component';
import { LOADING_STYLE } from 'libs/shared-ui/src/lib/fs-loading-spinner/fs-loading-spinner.component';
import { DeliveryOption } from 'libs/shared-models/src/lib/delivery-option';

@UntilDestroy()
@Component({
  selector: 'web-foodis-order-page',
  templateUrl: './order-page.component.html',
  styleUrl: './order-page.component.scss',
})
export class OrderPageComponent implements OnInit {

  public orderData$: BehaviorSubject<OrderResponse | null> = new BehaviorSubject<OrderResponse | null>(null);

  public transformedCartData$: BehaviorSubject<OrderCart | null> = new BehaviorSubject<OrderCart | null>(null);

  public DeliveryOption: typeof DeliveryOption = DeliveryOption;
  public PaymentOption: typeof PaymentOption = PaymentOption; 
  public OrderStatus: typeof OrderStatus = OrderStatus;
  public LOADING_STYLE: typeof LOADING_STYLE = LOADING_STYLE;
  public loadingApiData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  

  // bottom vertical steps
  public verticalStepsList$: BehaviorSubject<VerticalStatusItem[]> = new BehaviorSubject<VerticalStatusItem[]>([]);
  
  constructor(
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    private router: Router
  ) {

  }

  public ngOnInit(): void {
     this.checkForRouteParams();
  }

  private checkForRouteParams() {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
			const orderId = params.get('orderid');
			if (orderId) {

        this.loadingApiData$.next(true);

        this.ordersService.getOrderById$(orderId).pipe(untilDestroyed(this)).subscribe((data) => {
          this.orderData$.next(data);

          // transform it also for the order basket used in the view
          if (data) {
            const cartData = this.ordersService.transformOrderToCart(data);
            this.transformedCartData$.next(cartData);

            // Vertical steps data
            this.setVerticalSteps();

            // remove loading
            this.loadingApiData$.next(false);
          }
        })
			}
		});
  }

  private setVerticalSteps() {
    const order = this.orderData$.getValue();

  
    let steps = [];

    // delivery
    if (order?.deliveryOption === DeliveryOption.DELIVERY) {
      
      steps = this.getDeliverySteps();

      if (order?.status === OrderStatus.CREATED) {
        steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.ORDER_PAYMENT, VerticalStatusItemState.IN_PROGRESS);
      }

      if (order?.status === OrderStatus.PAID) {
        steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.RESTAURANT_CONFIRMATION, VerticalStatusItemState.IN_PROGRESS);
      }

      if (order?.status === OrderStatus.ACCEPTED_BY_RESTAURANT ) {
        steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.FOOD_IN_PREPARATION, VerticalStatusItemState.IN_PROGRESS);
      }

      if (order?.status === OrderStatus.IN_DELIVERY ) {
        steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.IN_DELIVERY, VerticalStatusItemState.IN_PROGRESS);
      }

      if (order?.status === OrderStatus.DELIVERED ) {
        steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.DELIVERED, VerticalStatusItemState.COMPLETED);
      }

    } else {

      // pick-up
      steps = this.getPickupSteps();

      // cash
      if (order?.paymentType === PaymentOption.CASH) {
        steps[0].required = false;
        steps[1].required = true;

        if (order?.status === OrderStatus.CREATED) {
          steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.RESTAURANT_CONFIRMATION, VerticalStatusItemState.IN_PROGRESS);
        }
      } else {
        // online
        steps[0].required = true;
        steps[1].required = false;

        if (order?.status === OrderStatus.CREATED) {
          steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.ORDER_PAYMENT, VerticalStatusItemState.IN_PROGRESS);
        }
      }

      if (order?.status === OrderStatus.PAID) {
        steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.RESTAURANT_CONFIRMATION, VerticalStatusItemState.IN_PROGRESS);
      }


      if (order?.status === OrderStatus.ACCEPTED_BY_RESTAURANT) {
        steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.FOOD_IN_PREPARATION, VerticalStatusItemState.IN_PROGRESS);
      }

      if (order?.status === OrderStatus.IN_DELIVERY) {
        steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.ORDER_PICKED_UP, VerticalStatusItemState.IN_PROGRESS);
      }

      if (order?.status === OrderStatus.DELIVERED) {
        steps = this.setCurrentStep(steps, VERTICAL_ITEM_NAME.ORDER_PICKED_UP, VerticalStatusItemState.COMPLETED);
      }
    }

    this.verticalStepsList$.next(steps);
  }

  private getDeliverySteps(): VerticalStatusItem[] {
    return this.getSpecificSteps(INIT_VERTICAL_ITEMS_DELIVERY);
  }

  private getPickupSteps(): VerticalStatusItem[] {
    return this.getSpecificSteps(INIT_VERTICAL_ITEMS_PICKUP);
  }

  private getSpecificSteps(list: any[]) {
    let steps: VerticalStatusItem[] = [];

    list.forEach((item) => {
      const step = new VerticalStatusItem();
      step.id = item.id;
      step.order = item.order;
      step.required = item.required;
      step.translationKeyDefault = item.translationKeyDefault;
      step.translationKeyCompleted = item.translationKeyCompleted;
      step.status = item.status;
      steps.push(step);
    })

    return steps;
  }

  private setCurrentStep(list: VerticalStatusItem[], stateName: VERTICAL_ITEM_NAME, status: VerticalStatusItemState): VerticalStatusItem[] {
    const currentStep = list.find((i) => i.id === stateName);
    if (!currentStep) {
      return [];
    }
    
    list = list.map((i) => {
      // set to completed all steps until current status
      if (i.order < currentStep.order) {
        i.status = VerticalStatusItemState.COMPLETED;
      } else {
        // empty the future states
        i.status = VerticalStatusItemState.EMPTY;
      }

      if (i.id === currentStep.id) {
        currentStep.status = status;
      }

      return i;
    })

    return list;
  }

  // TODO: Delete this mock
  public showSimulateButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public onSimulateNextStep() {
      const order = this.orderData$.getValue();

      switch (order?.status) {
        case OrderStatus.CREATED: {
          order.status = OrderStatus.PAID; // next one
          this.orderData$.next(order);

          if (order.deliveryOption === DeliveryOption.PICKUP && order.paymentType === PaymentOption.CASH) {
            order.status = OrderStatus.ACCEPTED_BY_RESTAURANT; // next one
            this.orderData$.next(order);
          }
          break;
        }
        case OrderStatus.PAID: {
          order.status = OrderStatus.ACCEPTED_BY_RESTAURANT; // next one
          this.orderData$.next(order);
          break;
        }
        case OrderStatus.ACCEPTED_BY_RESTAURANT: {
          order.status = OrderStatus.IN_DELIVERY; // next one
          this.orderData$.next(order);
          break;
        }
        case OrderStatus.IN_DELIVERY: {
          order.status = OrderStatus.DELIVERED; // next one
          this.orderData$.next(order);
          this.showSimulateButton$.next(false);
          break;
        }
      }
      this.setVerticalSteps();
  }

  public onPressPayOnline() {
    this.router.navigateByUrl("stripe/" + this.orderData$.getValue()?.id);
  }
}

export enum VERTICAL_ITEM_NAME {
  ORDER_PAYMENT = "order_payment",
  PAY_AT_PICKUP = "pay_at_pickup",
  RESTAURANT_CONFIRMATION = "restaurant_confirmation",
  FOOD_IN_PREPARATION = "food_preparation",
  IN_DELIVERY = "in_delivery",
  DELIVERED = "order_delivered",
  ORDER_PICKED_UP = "order_picked_up"
}

export const INIT_VERTICAL_ITEMS_DELIVERY = [
  {
    order: 1,
    required: true,
    id: VERTICAL_ITEM_NAME.ORDER_PAYMENT,
    translationKeyDefault: "order_vertical_status_delivery_step_1_default",
    translationKeyCompleted: "order_vertical_status_delivery_step_1_completed",
    status: VerticalStatusItemState.EMPTY
  },
  {
    order: 2,
    required: true,
    id: VERTICAL_ITEM_NAME.RESTAURANT_CONFIRMATION,
    translationKeyDefault: "order_vertical_status_delivery_step_2_default",
    translationKeyCompleted: "order_vertical_status_delivery_step_2_completed",
    status: VerticalStatusItemState.EMPTY
  },
  {
    order: 3,
    required: true,
    id: VERTICAL_ITEM_NAME.FOOD_IN_PREPARATION,
    translationKeyDefault: "order_vertical_status_delivery_step_3_default",
    translationKeyCompleted: "order_vertical_status_delivery_step_3_completed",
    status: VerticalStatusItemState.EMPTY
  },
  {
    order: 4,
    required: true,
    id: VERTICAL_ITEM_NAME.IN_DELIVERY,
    translationKeyDefault: "order_vertical_status_delivery_step_4_default",
    translationKeyCompleted: "order_vertical_status_delivery_step_4_completed",
    status: VerticalStatusItemState.EMPTY
  },
  {
    order: 5,
    required: true,
    id: VERTICAL_ITEM_NAME.DELIVERED,
    translationKeyDefault: "order_vertical_status_delivery_step_5_default",
    translationKeyCompleted: "order_vertical_status_delivery_step_5_completed",
    status: VerticalStatusItemState.EMPTY
  }
];


export const INIT_VERTICAL_ITEMS_PICKUP = [
  {
    order: 1,
    required: false,
    id: VERTICAL_ITEM_NAME.ORDER_PAYMENT,
    translationKeyDefault: "order_vertical_status_pickup_step_1_default",
    translationKeyCompleted: "order_vertical_status_pickup_step_1_completed",
    status: VerticalStatusItemState.EMPTY
  },
  {
    order: 2,
    required: false,
    id: VERTICAL_ITEM_NAME.PAY_AT_PICKUP,
    translationKeyDefault: "order_vertical_status_pickup_step_2_default",
    translationKeyCompleted: "order_vertical_status_pickup_step_2_completed",
    status: VerticalStatusItemState.EMPTY
  },
  {
    order: 3,
    required: true,
    id: VERTICAL_ITEM_NAME.RESTAURANT_CONFIRMATION,
    translationKeyDefault: "order_vertical_status_pickup_step_3_default",
    translationKeyCompleted: "order_vertical_status_pickup_step_3_completed",
    status: VerticalStatusItemState.EMPTY
  },
  {
    order: 4,
    required: true,
    id: VERTICAL_ITEM_NAME.FOOD_IN_PREPARATION,   
    translationKeyDefault: "order_vertical_status_pickup_step_4_default",
    translationKeyCompleted: "order_vertical_status_pickup_step_4_completed",
    status: VerticalStatusItemState.EMPTY
  },
  {
    order: 5,
    required: true,
    id: VERTICAL_ITEM_NAME.ORDER_PICKED_UP,
    translationKeyDefault: "order_vertical_status_pickup_step_5_default",
    translationKeyCompleted: "order_vertical_status_pickup_step_5_completed",
    status: VerticalStatusItemState.EMPTY
  }
]