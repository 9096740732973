<div class="button-container">
    <div class="selector">
        <ng-container *ngIf="item.selected">
            <div class="rectangle-selector">

            </div>
        </ng-container>
    </div>

    <div class="icon">
        <ng-container *ngIf="!!item.icon">
            <mat-icon [ngClass]="{'icon-default': !item.selected, 'icon-selected': item.selected}">{{item.icon}}</mat-icon>
        </ng-container>
    </div>

    <div class="text" [ngClass]="{'selected': item.selected}">
        {{ item.key | translate }}
    </div>

</div>