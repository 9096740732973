<div class="main-container">

    <div class="main-title">
        {{ "checkout_title" | translate }}
    </div>

    <!---
        Desktop view
    -->
    <div class="columns-container">
        <!-- Left -->
        <div class="column-left">
                
            <!-- Address -->
            <div class="address-container">
                <!-- Show delivery address -->
                <ng-container *ngIf="(basketData$ | async)?.deliveryOption === DeliveryOption.DELIVERY; else showAddressPickup">
                    <ng-container *ngTemplateOutlet="addressDelivery"></ng-container>    
                </ng-container>
                <!-- Show Pick-up address -->
                <ng-template #showAddressPickup>
                    <ng-container *ngTemplateOutlet="addressPickup"></ng-container>    
                </ng-template>       
            </div>

            <!-- Payment method -->
            <div class="payment-method-container">
                <ng-container *ngTemplateOutlet="paymentMethod"></ng-container>    
            </div>
        </div>

        <!-- Right -->
        <div class="column-right">
            <!-- Basket items & total -->
            <div class="basket-items-container">
                <ng-container *ngTemplateOutlet="basketItems"></ng-container>    
            </div>
        </div>
    </div>


    <!-- Todo: maybe do it with some resize listener and inside ng-container with ngIf instead of purely hiding it from css-->
    
    <!--- 
        Mobile view
    -->
    <div class="mobile-container">
         <!-- Address -->
         <div class="address-container">
            <!-- Show delivery address -->
            <ng-container *ngIf="(basketData$ | async)?.deliveryOption === DeliveryOption.DELIVERY; else showAddressPickup">
                <ng-container *ngTemplateOutlet="addressDelivery"></ng-container>    
            </ng-container>
            <!-- Show Pick-up address -->
            <ng-template #showAddressPickup>
                <ng-container *ngTemplateOutlet="addressPickup"></ng-container>    
            </ng-template>       
        </div>

        <!-- Basket items & total -->
        <div class="basket-items-container">
            <ng-container *ngTemplateOutlet="basketItems"></ng-container>    
        </div>

         <!-- Payment method -->
         <div class="payment-method-container">
            <ng-container *ngTemplateOutlet="paymentMethod"></ng-container>    
        </div>
    </div>


    <!-- Button to move forward -->
    <div class="button-container">
        <div class="button-continue">
            <fs-button [text]="((selectedPaymentOption$ | async) === PaymentOption.CASH ? 'checkout_place_order' : 'checkout_go_payment') | translate"
                [style]="'full-width-large-height'"
                [type]="'raised-primary'" (onPress)="onPressContinue()">
            </fs-button>
        </div>
    </div>
</div>



<!-- 
    Address delivery
-->
<ng-template #addressDelivery>
    <div class="address-delivery">

        <!-- Text box with all details -->
        <delivery-address></delivery-address>

        <!-- Map -->
        <!-- For desktop always show it. For mobile, show it only if it is expanded -->
        <ng-container *ngIf="(isMobileView$ | async) ? (isAddressExpanded$ | async) : true">
            <div class="address-map">
                <fs-map-lite 
                    [address]="(userAddress$ | async) || null"
                    [disableDefaultUI]="true"
                    [width]="(mapWidthResize$ | async) || 200"
                    [height]="200">
                </fs-map-lite>
            </div>
        </ng-container>
        <!-- Expand button mobile -->
        <ng-container *ngIf="(isMobileView$ | async)">
            <div class="address-toggle-button">
                <img class="pinpoint-img" [src]="'apps/user-app/src/assets/distance-pinpoint.svg'"/>
                <fs-link class="mr-1 ml-1"
                    [text]="((isAddressExpanded$ | async) ? 'checkout_address_minify' : 'checkout_address_expand') | translate" 
                    [buttonBehavior]="true"
                    (press)="onAddressToggleClick()">
                </fs-link>
                <img class="pinpoint-img" [ngClass]="{'rotate': (isAddressExpanded$ | async)}"
                    [src]="'apps/user-app/src/assets/arrow-drop-down-address.svg'"/>
            </div>
        </ng-container>
    </div>
</ng-template>


<!-- 
    Address Pick-up 
-->

<ng-template #addressPickup>
    <div class="address-pickup">
        
        <!-- Pick-up address -->
        <pick-up-address
            [inputAddress]="(basketData$ | async)?.restaurantAddress"
            [restaurantName]="((basketData$ | async)?.restaurantName) || ''">
        </pick-up-address>

        <!-- Map -->
        <!-- For desktop always show it. For mobile, show it only if it is expanded -->
        <ng-container *ngIf="(isMobileView$ | async) ? (isAddressExpanded$ | async) : true">
            <div class="address-map">
                <fs-map-lite 
                    [address]="(basketData$ | async)?.restaurantAddress || null"
                    [disableDefaultUI]="true"
                    [width]="200"
                    [height]="200">
                </fs-map-lite>
            </div>
        </ng-container>
        <!-- Expand button mobile -->
        <ng-container *ngIf="(isMobileView$ | async)">
            <div class="address-toggle-button">
                <img class="pinpoint-img" [src]="'apps/user-app/src/assets/distance-pinpoint.svg'"/>
                <fs-link class="mr-1 ml-1"
                    [text]="((isAddressExpanded$ | async) ? 'checkout_address_minify' : 'checkout_address_expand') | translate" 
                    [buttonBehavior]="true"
                    (press)="onAddressToggleClick()">
                </fs-link>
                <img class="pinpoint-img" [ngClass]="{'rotate': (isAddressExpanded$ | async)}"
                    [src]="'apps/user-app/src/assets/arrow-drop-down-address.svg'"/>
            </div>
        </ng-container>
    </div>
</ng-template>

<!-- 
    Basket items & total 
-->
<ng-template #basketItems>
    <div class="basket-items">
        <order-basket [isCheckoutMode]="true"></order-basket>
    </div>
</ng-template>

<!-- 
    Payment method 
-->
<ng-template #paymentMethod>
    <div class="payment-method">
        <div class="address-title">
            {{ 'checkout_payment_method' | translate }}
        </div>

        <div class="payment-options">
            
            <!-- Cash -->
            <div class="cash-option" (mousedown)="onRadioPaymentClick(PaymentOption.CASH)"
                [ngClass]="{'disabled': (basketData$ | async)?.deliveryOption === DeliveryOption.DELIVERY}">
                <!-- Radio -->
                <mat-radio-button class="action-item" id='option_cash' 
                    [checked]="(selectedPaymentOption$ | async) === PaymentOption.CASH" 
                    [disabled]="(basketData$ | async)?.deliveryOption === DeliveryOption.DELIVERY"
                    [value]="'option_cash'">
                    <span class="option-title" 
                        [ngClass]="{
                            'selected': (selectedPaymentOption$ | async) === PaymentOption.CASH, 
                            'disabled': (basketData$ | async)?.deliveryOption === DeliveryOption.DELIVERY}"
                    > 
                        {{ 'checkout_payment_cash' | translate }}
                    </span>
                   
                </mat-radio-button>
                <!-- Image & text -->
                <div class="payment-split">
                    <!-- Logo -->
                    <div class="payment-option-logo" [ngClass]="{'disabled': (basketData$ | async)?.deliveryOption === DeliveryOption.DELIVERY}">
                        <img [src]="'apps/user-app/src/assets/checkout-cash' 
                            + ((selectedPaymentOption$ | async) === PaymentOption.CASH ? '-selected' : '') + '.svg'"
                        />
                    </div>
                    <!-- Text -->
                    <div class="payment-option-content">
                        <div class="payment-option-text" 
                            [ngClass]="{'selected' : (selectedPaymentOption$ | async) === PaymentOption.CASH,
                                        'disabled': (basketData$ | async)?.deliveryOption === DeliveryOption.DELIVERY
                            }">
                            <!-- Available / selected -->
                            <ng-container *ngIf="(basketData$ | async)?.deliveryOption === DeliveryOption.PICKUP; else cashNotAvailable">
                                {{ 'checkout_payment_cash_pay' | translate }}
                            </ng-container>
                            <!-- Disabled -->
                            <ng-template #cashNotAvailable>
                                {{ 'checkout_payment_cash_not_available' | translate }}
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Online -->
            <div class="online-option ml-3" (mousedown)="onRadioPaymentClick(PaymentOption.ONLINE)">
                <!-- Radio -->
                <mat-radio-button class="action-item" id='option_online' [checked]="(selectedPaymentOption$ | async) === PaymentOption.ONLINE" 
                    [value]="'option_online'">
                    <span class="option-title" 
                        [ngClass]="{'selected': (selectedPaymentOption$ | async) === PaymentOption.ONLINE, 
                        'disabled': false}"
                    > 
                        {{ 'checkout_payment_online' | translate }}
                    </span>
                </mat-radio-button>
                <!-- Image & text -->
                <div class="payment-split">
                    <!-- Logo -->
                    <div class="payment-option-logo">
                        <img [src]="'apps/user-app/src/assets/checkout-online' 
                            + ((selectedPaymentOption$ | async) === PaymentOption.ONLINE ? '-selected' : '') + '.svg'"
                        />
                    </div>
                    <!-- Text -->
                    <div class="payment-option-content">
                        <div class="payment-option-text" 
                            [ngClass]="{'selected' : (selectedPaymentOption$ | async) === PaymentOption.ONLINE}"
                        >
                            <!-- Available / selected -->
                            <ng-container *ngIf="true; else onlineNotAvailable">
                                <div class="online-pay-container">
                                    <div class="online-pay-safe">
                                        <span class="mr-1">{{ 'checkout_payment_online_safe' | translate }}</span>
                                        <div class="payment-stripe-logo">
                                            <img [src]="'apps/user-app/src/assets/checkout-stripe.svg'"/>
                                        </div>
                                    </div>
                                    <div class="payment-stripe-cards">
                                        <img [src]="'apps/user-app/src/assets/checkout-visa-mastercard.svg'"/>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- Disabled -->
                            <ng-template #onlineNotAvailable>
                                {{ 'checkout_payment_online_not_available' | translate }}
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>