import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DeliveryOption, TopNavBarService } from '../../services/navbar/top-navbar.service';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { UserAddressFacade } from '../../services/user-address/user-address.facade.service';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { OrderBasketService } from '../../services/order-basket/order-basket.service';
import { OrderCart, OrderCartAddonItem, OrderCartProductItem } from '../../services/order-basket/model/order-basket.model';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { OrdersService } from '../../services/orders/orders.service';
import { OrderCreateRequest, OrderLineItemAddons, OrderLineItemCreateRequest } from '../../models/order-create-request';
import { OrderResponse } from '../../models/order-response';

@UntilDestroy()
@Component({
  selector: 'web-foodis-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
})
export class CheckoutPageComponent implements OnInit {

  public userAddress$: Observable<AddressResponse> = this.userAddressFacade.getCurrentAddress$();
  public PaymentOption: typeof PaymentOption = PaymentOption;
  public DeliveryOption: typeof DeliveryOption = DeliveryOption;

  // default is online
  public selectedPaymentOption$: BehaviorSubject<PaymentOption> = new BehaviorSubject<PaymentOption>(PaymentOption.ONLINE);
  public basketData$: Observable<OrderCart> = this.orderBasketService.getState$();

  // visibility (mobile)
  public isAddressExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isMobileView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mapWidthResize$: BehaviorSubject<number> = new BehaviorSubject<number>(200);

  constructor(
    private topNavBarService: TopNavBarService,
    private userAddressFacade: UserAddressFacade,
    private orderBasketService: OrderBasketService,
    private ordersService: OrdersService,
    private toasterService: ToasterService,
    private localeService: LocaleService,
    private router: Router
  ) {

  }

  public ngOnInit() {
    // make sure order basket popup is not visible anymore
    this.topNavBarService.closeOrderBasketInPopup();

    // check the window size to better cover the mobile vs desktop functionality
    this.listenToWindowResize();

    // preselect cash as default for pickup
    this.setDefaultPaymentOption();
    // also listen for changes (in case the screen is open: eg: mobile and needs to re-check the availablility of the selected option)
    this.topNavBarService.getDeliveryOption$().pipe(untilDestroyed(this)).subscribe((option) => {
      if (option === DeliveryOption.DELIVERY) {
        this.selectedPaymentOption$.next(PaymentOption.ONLINE);
      }
    })
  }

  private setDefaultPaymentOption() {
    if (this.orderBasketService.getState().deliveryOption === DeliveryOption.PICKUP) {
      this.selectedPaymentOption$.next(PaymentOption.CASH);
    } else { // delivery
      this.selectedPaymentOption$.next(PaymentOption.ONLINE);
    }
  }

  private listenToWindowResize() {
     // a first check
    this.checkIsMobileVersion();
    // listen to window resize now:
    fromEvent(window, 'resize').pipe(untilDestroyed(this)).subscribe((a) => {            
        this.checkIsMobileVersion();
    });
  }

  private checkIsMobileVersion() {
      const width = window.innerWidth;
      const isMobileView = width <= 576; //px fits best our page composition
      let reductionPercent = 1;

      if (width < 400) {
        reductionPercent = 0.8;
      } else if (width < 576) {
        reductionPercent = 0.86;
      } 

      if (isMobileView) {
        this.mapWidthResize$.next(reductionPercent * window.innerWidth);
      } else {
        if (width >= 1300 && width <1600) {
          reductionPercent = 0.20;
          this.mapWidthResize$.next(reductionPercent * window.innerWidth);
        } else if (width >= 1600) {
          reductionPercent = 0.25;
          this.mapWidthResize$.next(reductionPercent * window.innerWidth);
        } else {
          this.mapWidthResize$.next(200);
        }
      }
      this.isMobileView$.next(isMobileView);
  }
  

  private getPaymentOption(): PaymentOption {
    return this.selectedPaymentOption$.getValue();
  }

  public onRadioPaymentClick(option: PaymentOption) {

    if (this.getPaymentOption() === option) {
      return;
    }

    if (option === PaymentOption.CASH && this.orderBasketService.getState().deliveryOption === DeliveryOption.DELIVERY) {
          return; // ignore the action because cash payment is not available for delivery
    }

    this.selectedPaymentOption$.next(option);
  }
  

    public onAddressToggleClick() {
      let value = this.isAddressExpanded$.getValue();
      this.isAddressExpanded$.next(!value);
    }


    // Submit the order
    public onPressContinue() {
      // transform from cart to create request model
      const order: OrderCreateRequest = this.transformCartToOrder();

      // API creation
      this.ordersService.createOrder$(order).subscribe((createdOrder: OrderResponse) => {

        if (createdOrder) {
          // clear the basket now that the order was created
          this.orderBasketService.emptyCart();

          // redirect the user to the next flow (stripe payment / order page)
          if (order.paymentType === PaymentOption.ONLINE) {
            this.router.navigateByUrl("stripe/" + createdOrder.id);
          } else {
            this.router.navigateByUrl("user/order/" + createdOrder.id);
          }
        } else {
          // means the order creation failed
        }
      })
    }

    // Transformer: (cart model to api order request model adaption)

    private transformCartToOrder(): OrderCreateRequest {
      // basket
      const basketData = this.orderBasketService.getState();

      // payment
      const paymentOption = this.selectedPaymentOption$.getValue();

      // address
      const userAddress = this.userAddressFacade.getCurrentAddress();

      return this.ordersService.transformCartToOrder(basketData, paymentOption, userAddress);
    }
}


export enum PaymentOption {
  CASH = "CASH",
  ONLINE = "ONLINE"
}
