import {BehaviorSubject, find, map, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import { PublicRestaurantResponse } from "../model/public-restaurant-response";

@Injectable({
    providedIn: 'root',
})
export class FullRestaurantPageState {

    private state$: BehaviorSubject<PublicRestaurantResponse[]> = new BehaviorSubject<PublicRestaurantResponse[]>([]);

    constructor(
    ) {
    }

    public getData$(): Observable<PublicRestaurantResponse[]> {
        return this.state$.asObservable();
    }

    public getData(): PublicRestaurantResponse[] {
        return this.state$.getValue();
    }

    public setData(value: PublicRestaurantResponse[]): void {
        this.state$.next(value);
    }

    public upsertItem(param: PublicRestaurantResponse) {
        let currentList = [...this.getData()];

        // No existing elements
        if (currentList.length === 0) {
            currentList = [...currentList, param];
            this.setData(currentList);
            return;
        }

        const index = currentList.findIndex((item: PublicRestaurantResponse) => {
            return item.restaurantInfo.id === param.restaurantInfo.id
        });
        // New item
        if (-1 === index) {
            currentList = [...currentList, param];
        }
        // Existing item which needs to be replaced:
        else {
            currentList = [...currentList.slice(0, index), param, ...currentList.slice(index + 1)];
        }
        this.setData(currentList);
    }
}
