import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { DeliveryOption } from "libs/shared-models/src/lib/delivery-option";

export class OrderCart {
    restaurantId: string = "";
    restaurantName: string = "";
    restaurantSlug: string = "";
    restaurantAddress: AddressResponse = new AddressResponse();
    restaurantMinOrderAmount: number = 0; 
    deliveryOption: DeliveryOption;
    items: OrderCartProductItem[] = [];
    initialPrice: number = 0;
    deliveryPrice: number = 350; // TODO - receive this from API at restaurant level or general
    extraDeliveryDiscount: number = 0;
    totalPrice: number = 0;
    // locally used to store the data / not needed for api:
    supportsDelivery: boolean = true;
    supportsPickup: boolean = true;
}

export class OrderCartProductItem {
    productId: string = "";
    productName: string = "";
    productCategoryId: string = "";
    productPrice: number = 0;
    productPriceDelivery: number = 0;
    addons: OrderCartAddonItem[] = [];
    quantity: number = 0;
    specialInstructions: string = ""
}

export class OrderCartAddonItem {
    addonId: string = "";
    addonName: string = "";
    addonGroupId: string = ""
    addonPrice: number = 0;
    quantity: number = 0;
}