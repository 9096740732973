import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { DeliveryOption } from "../delivery-option";

export class OrderCreateRequest {
  restaurantId: string = "";
  restaurantName: string = "";
  restaurantSlug: string = "";
  deliveryOption: DeliveryOption;
  items: OrderLineItemCreateRequest[] = [];
  initialPriceCents: number = 0;
  costOfDeliveryCents: number = 0;
  totalPriceCents: number = 0;
  deliveryDiscountCents: number = 0;
  totalDeliveryPriceCents: number = 0;
  userAddress: AddressResponse;
  paymentType: string = "";
}

export class OrderLineItemCreateRequest {
  productId: string = "";
  productName: string = "";
  menuCategoryId: string = ""; // product category
  productPriceCents: number = 0;
  productPriceCentsDelivery: number = 0;
  addons: OrderLineItemAddons[] = [];
  quantity: number = 0;
  specialInstructions: string = "";
}

export class OrderLineItemAddons {
  addonId: string = "";
  addonName: string = "";
  addonGroupId: string = "";
  addonPriceCents: number = 0;
  quantity: number = 0;
}


