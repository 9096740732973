import { Route } from '@angular/router';
import { UserDashboardComponent } from "./pages/dashboard/dashboard.component";
import { LoginRedirectComponent } from "./pages/login-redirect/login-redirect.component";
import { RestaurantPageComponent } from './pages/restaurant-page/restaurant-page.component';
import { CheckoutPageComponent } from './pages/checkout/checkout-page.component';
import { StripeCheckoutComponent } from './pages/stripe-checkout/stripe-checkout.component';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { OrdersListComponent } from './pages/orders-list/orders-list.component';
import { UserSectionComponent } from './pages/user-section/user-section.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { AddressPageComponent } from './pages/address-page/address-page.component';
import { UserPaymentPageComponent } from './pages/payment-page/payment-page.component';

export const appRoutes: Route[] = [
    { path: '', component: UserDashboardComponent,  pathMatch: 'full'},
    { path: 'dashboard', redirectTo: '', },
    // { path: 'dashboard', component: UserDashboardComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginRedirectComponent},
    { path: 'restaurant', component: RestaurantPageComponent},
    { path: 'restaurant/:slug', component: RestaurantPageComponent},
    { path: 'checkout', component: CheckoutPageComponent},
    { path: 'stripe', component: StripeCheckoutComponent},
    { path: 'stripe/:orderid', component: StripeCheckoutComponent},  
    {
        path: 'user',
        component: UserSectionComponent,
        children: [
          { path: 'profile', component: ProfilePageComponent },
          { path: 'orders', component: OrdersListComponent },
          { path: 'order/:orderid', component: OrderPageComponent},
          { path: 'address', component: AddressPageComponent },
          { path: 'payment', component: UserPaymentPageComponent },
        ]
      },
];
