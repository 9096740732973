import { Injectable} from '@angular/core';
import { ApiBaseService } from 'libs/shared-services/src/lib/api-base.service';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { OrderCreateRequest, OrderLineItemAddons, OrderLineItemCreateRequest } from '../../models/order-create-request';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'apps/user-app/src/environments/environment';
import { OrderLineItemResponse, OrderListResponse, OrderResponse, OrderStatus } from '../../models/order-response';
import { OrdersStateService } from './state/orders.state';
import { PaymentOption } from '../../pages/checkout/checkout-page.component';
import { OrderCart, OrderCartAddonItem, OrderCartProductItem } from '../order-basket/model/order-basket.model';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { DeliveryOption } from '../navbar/top-navbar.service';

@Injectable({
    providedIn: 'root',
})
export class OrdersService {

    constructor(
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private ordersStateService: OrdersStateService
    ) {
    }

    public createOrder$(order: OrderCreateRequest): Observable<OrderResponse> {
        const apiCall = this.apiService.post(environment.API_ORDERS, order);
        return apiCall.pipe(
            map((res) => {
                const createdOrder = Object.assign(new OrderResponse(), res);
                
                // keep this
                this.ordersStateService.setData(createdOrder);
                return createdOrder;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(null);
            })
        );
    }

    /*
        TODO: DELETE THESE CUSTOM MOCKED FUNCTIONS 
    */

    public mockIsPaid() {
       this.mockState(OrderStatus.PAID);
    }

    public mockIsAcceptedByRestaurant() {
        this.mockState(OrderStatus.ACCEPTED_BY_RESTAURANT);
    }

    public mockInDelivery() {
        this.mockState(OrderStatus.IN_DELIVERY);
    }

    public mockDelivered() {
        this.mockState(OrderStatus.DELIVERED);
    }

    private mockState(newState: OrderStatus) {
        const data = this.ordersStateService.getData();
        data.status = newState;
        this.ordersStateService.setData(data);
    }

    // END delete

    public getOrderById(id: string): OrderResponse | null {
        const data = this.ordersStateService.getData();
        if (data.id === id) {
            return data;
        } 
        return null;
    }

    public getOrderById$(id: string): Observable<OrderResponse | null> {
        if (this.getOrderById(id)) {
            return this.ordersStateService.getData$();
        } else {
            return this.fetchOrderByIdAPI$(id);
        }
    }

    // fresh data each time
    public fetchOrderByIdAPI$(id: string): Observable<OrderResponse | null> {
        if (!id) {
            return of(null);
        }

        const path = environment.API_INDIVIDUAL_ORDER.replace("{{order_id}}", id);
        const apiCall = this.apiService.get(path);
        
        return apiCall.pipe(
            map((res) => {
                const order = Object.assign(new OrderResponse(), res);
                this.ordersStateService.setData(order);
                return order;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(null);
            })
        );
    }

    /*
        Fetch all orders list (for the Orders section)
    */
    public fetchOrdersListAPI$(): Observable<OrderListResponse> {
        const url = environment.API_ORDERS + "?pageSize=100";
        const apiCall = this.apiService.get(url);
        return apiCall.pipe(
            map((res) => {
                const ordersRes = Object.assign(new OrderListResponse(), res);
                return ordersRes;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of([]);
            })
       );
    }

    /* 
        Transformers (Cart to Order,  Order to Cart)
    */

    public transformCartToOrder(basketData: OrderCart, paymentOption: PaymentOption, userAddress: AddressResponse): OrderCreateRequest {
    
        // new creation order
        let order: OrderCreateRequest = new OrderCreateRequest();
    
        order.restaurantId          = basketData.restaurantId;
        order.restaurantName        = basketData.restaurantName;
        order.restaurantSlug        = basketData.restaurantSlug;
        order.deliveryOption        = basketData.deliveryOption;
        order.initialPriceCents     = basketData.initialPrice; 
        order.costOfDeliveryCents   = basketData.deliveryPrice;
        order.totalDeliveryPriceCents   = 600; // ?
        order.totalPriceCents       = basketData.totalPrice;
        order.deliveryDiscountCents = basketData.extraDeliveryDiscount;
        order.paymentType           = paymentOption;
        
        if (basketData.deliveryOption === DeliveryOption.DELIVERY) {
            order.userAddress         =  userAddress
        }
    
        let items: OrderLineItemCreateRequest[] = [];
    
        basketData.items.forEach((p: OrderCartProductItem) => {
    
            // transforming each product to the new model too
            let prod: OrderLineItemCreateRequest = new OrderLineItemCreateRequest();
            prod.productId            = p.productId;
            prod.productName          = p.productName;
            prod.menuCategoryId       = p.productCategoryId;
            prod.productPriceCents    = p.productPrice;
            prod.productPriceCentsDelivery = p.productPriceDelivery;
            prod.quantity             = p.quantity;
            prod.specialInstructions  = p.specialInstructions;
    
            let addons: OrderLineItemAddons[] = [];
            p.addons.forEach((a: OrderCartAddonItem) => {
            // transforming each addon to the new model too
            let addon:OrderLineItemAddons = new OrderLineItemAddons();
            addon.addonId         = a.addonId;
            addon.addonGroupId    = a.addonGroupId;
            addon.addonName       = a.addonName;
            addon.addonPriceCents = a.addonPrice;
            addon.quantity        = a.quantity;
            // push addon to the new list
            addons.push(addon);
            });
    
            prod.addons = addons;
    
            // push product to the new list
            items.push(prod);
        });
    
        order.items = items;
    
        return order;
    }


    // Transform inverse: From Order data to Cart data
    public transformOrderToCart(order: OrderResponse): OrderCart {

        let basketOrder: OrderCart = new OrderCart();

        basketOrder.restaurantId    = order.restaurantId;
        basketOrder.restaurantName  = order.restaurantName;
        basketOrder.restaurantSlug  = order.restaurantSlug;
        basketOrder.deliveryOption  = order.deliveryOption
        basketOrder.initialPrice    = order.initialPriceCents;
        basketOrder.deliveryPrice   = order.costOfDeliveryCents;
        // basketOrder.  = order.totalDeliveryPriceCents;  600; // TODO - fix this if needed
        basketOrder.totalPrice      = order.totalPriceCents;
        basketOrder.extraDeliveryDiscount = order.deliveryDiscountCents;

        // Products and addons
        let products: OrderCartProductItem[] = [];
        order.items.forEach((item: OrderLineItemResponse) => {
            let newProd: OrderCartProductItem = new OrderCartProductItem();
            newProd.productId            = item.productId;
            newProd.productName          = item.productName;
            newProd.productCategoryId    = item.menuCategoryId;
            newProd.productPrice         = item.productPriceCents;
            newProd.productPriceDelivery = item.productPriceCentsDelivery;
            newProd.quantity             = item.quantity;
            newProd.specialInstructions  = item.specialInstructions;

            // addons
            let addons: OrderCartAddonItem[] = [];
            item.addons.forEach((a: OrderLineItemAddons) => {
                // transforming each addon to the new model too
                let newAddon:OrderCartAddonItem = new OrderCartAddonItem();
                newAddon.addonId         = a.addonId;
                newAddon.addonGroupId    = a.addonGroupId;
                newAddon.addonName       = a.addonName;
                newAddon.addonPrice      = a.addonPriceCents;
                newAddon.quantity        = a.quantity;
                // push addon to the new list
                addons.push(newAddon);
            });
    
            newProd.addons = addons;
            
            products.push(newProd);
        });

        basketOrder.items = products;

        return basketOrder;
    }
}