import { Component, HostListener, OnInit } from '@angular/core';
import { FullRestaurantPageService } from '../../services/full-restaurant-page/full-restaurant-page.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { PublicRestaurantResponse } from '../../services/full-restaurant-page/model/public-restaurant-response';
import { UserAddressFacade } from '../../services/user-address/user-address.facade.service';
import { DeliveryOption, TopNavBarService } from '../../services/navbar/top-navbar.service';
import { ModalService } from 'libs/shared-ui/src/lib/modal-service/modal-service.service';
import { MoreRestaurantInfoModalComponent } from './modals/more-restaurant-info/more-restaurant-info.component';
import { ProductDetailsModalComponent } from './modals/product-details-modal/product-details-modal.component';
import { CustomProductModalModel } from './modals/product-details-modal/custom-product-modal.model';
import { AddonGroupResponse } from 'libs/shared-models/src/lib/restaurant/addon-group-response';
import { OrderBasketService } from '../../services/order-basket/order-basket.service';
import { OrderCartProductItem } from '../../services/order-basket/model/order-basket.model';
import { MenuCategoryWithSlimProducts } from 'libs/shared-models/src/lib/restaurant/menu-category-with-slim-products';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Language, LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { ProductInfo } from 'libs/shared-models/src/lib/restaurant/product-info';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { MenuCategoryWithProducts } from 'libs/shared-models/src/lib/restaurant/menu-category-with-products';

@UntilDestroy()
@Component({
  selector: 'web-foodis-restaurant-page',
  templateUrl: './restaurant-page.component.html',
  styleUrl: './restaurant-page.component.scss',
})

export class RestaurantPageComponent implements OnInit{

	@HostListener("window:scroll", [])
	onWindowScroll() {
		this.handleWindowScroll();
	}

	private currentRestaurantId: string = "";
	public restaurantData$: BehaviorSubject<PublicRestaurantResponse | undefined> = new BehaviorSubject<PublicRestaurantResponse | undefined>(new PublicRestaurantResponse());
	public deliveryOption$: Observable<DeliveryOption> = this.topNavBarService.getDeliveryOption$();

	public isFavourite = false;
	public DeliveryOption: typeof DeliveryOption = DeliveryOption;

	public currentTab$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public currentTabIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private smoothScrollAnimationRunning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	// Search part:
	public currentProductsSearchInput: string = "";	
    public productsSearchResults = new Map<string, SearchMap>(); // Keeps search results visibility logic for categories and products

	// Restaurant can add title / descriptions in either English or German. Check if language is german (main) and has value - otherwise, switch to default English
	// Eg: in ProductSlim model we have the fields name & nameEn. 
	public useGermanDescriptions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor(
		private fullPageService: FullRestaurantPageService,
		private route: ActivatedRoute,
		private userAddressFacade: UserAddressFacade,
		private topNavBarService: TopNavBarService,
		private modalService: ModalService,
		private orderBasketService: OrderBasketService,
		private localeService: LocaleService
	) {

	}

	public ngOnInit(): void {
		this.fetchData();

		// Listen to language changes
		this.localeService.getLanguage$().subscribe((lang: Language) => {      
			this.useGermanDescriptions$.next(lang.iso2 === "de");
		})
	}

	private fetchData() {
		this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
			const slug = params.get('slug');
			if (slug) {
				this.currentRestaurantId = slug;
				this.fullPageService.fetchRestaurantDataBySlug(slug);
				this.listenToDataChanges(slug);
			}
		});
	}

	private listenToDataChanges(slug: string) {
		this.fullPageService.getRestaurantBySlug$(slug).pipe(untilDestroyed(this)).subscribe((data) => {
			this.restaurantData$.next(data);

			// update the basket with the delivery support too
			this.updateBasketDeliverySupport();

			// show a closed message popup if the restaurant is closed on page load
			if (data?.restaurantInfo.id) {
				const isClosed = data.restaurantInfo.closed || this.isOutsideBusinessHours(data);
				if (isClosed) {
					this.fullPageService.openClosedWarningPopup();
				}
			}
		})

		this.orderBasketService.onBasketEmptyAction$.pipe(untilDestroyed(this)).subscribe((value) => {
			if (value) {
				this.updateBasketDeliverySupport();
			}
		})
	}

	private updateBasketDeliverySupport() {
		const data = this.restaurantData$.getValue();
		if (data?.restaurantInfo.id) {
			const hasDelivery = data?.restaurantInfo.hasFoodisDelivery || data?.restaurantInfo.hasOwnDelivery || false;
			const hasPickup = data?.restaurantInfo.hasPickup || false;
			const restaurantId = data?.restaurantInfo.id || "";
			const restaurantName = data?.restaurantInfo.name || "";
			const restaurantSlug = data?.restaurantInfo.slug || "";
			this.orderBasketService.updateDeliverySupport(restaurantId, hasDelivery, hasPickup, restaurantName, restaurantSlug);
		}		
	}


	/*
		Distance (km) from restaurant's geo-location points to my current address points
	*/
   
	public distanceToMyAddress(item: PublicRestaurantResponse | undefined | null): number {
		if (!item) {
			return 0;
		}
		const userAddress = this.userAddressFacade.getCurrentAddress();
		return this.fullPageService.getDistanceToMyAddress(item, userAddress);
	}

	public onPressMoreInfo() {
		this.openMoreInfoModal(this.restaurantData$.getValue());		
	}

	private openMoreInfoModal(restaurant: PublicRestaurantResponse | undefined) {
		const modalConfig = this.modalService.getDefaultDialogConfig();
		modalConfig.data = restaurant;
		modalConfig.width = "800px";
		modalConfig.disableClose = true;
		this.modalService.openCustomDialog(MoreRestaurantInfoModalComponent, modalConfig);
	}


	/*
        Business hours (outside or restaurant closed)
    */
	public isOutsideBusinessHours(res: PublicRestaurantResponse | null | undefined): boolean {
		if (!res) {
			return false;
		}
		return this.fullPageService.isOutsideBusinessHours(res.restaurantInfo.id);
	}
	public getNextAvailableHours(res: PublicRestaurantResponse | null | undefined): string {
		if (!res) {
			return "";
		}
		return this.fullPageService.getNextAvailableHours(res.restaurantInfo.id);
	}

	public onPressProduct(product: ProductInfo, categoryId: string) {
		const modalData = new CustomProductModalModel();
		modalData.restaurantId = this.restaurantData$.getValue()?.restaurantInfo.id || "";
		modalData.restaurantName = this.restaurantData$.getValue()?.restaurantInfo.name || "";
		modalData.restaurantSlug = this.restaurantData$.getValue()?.restaurantInfo.slug || "";
		modalData.restaurantAddress = this.restaurantData$.getValue()?.restaurantInfo.address || new AddressResponse();
		modalData.restaurantMinOrderAmount = this.restaurantData$.getValue()?.restaurantInfo.minOrderAmount || 0;
		modalData.product = product;
		modalData.productCategoryId = categoryId;
		modalData.deliveryType = this.topNavBarService.getDeliveryOption();
		modalData.supportsDelivery = this.restaurantData$.getValue()?.restaurantInfo.hasOwnDelivery || this.restaurantData$.getValue()?.restaurantInfo.hasFoodisDelivery || false;
		modalData.supportsPickup = this.restaurantData$.getValue()?.restaurantInfo.hasPickup || false;

		const addonGroupsList = this.restaurantData$.getValue()?.addonGroups;
		modalData.addonGroups = product.addonGroupIds.reduce((acc, groupId) => {
			const found = addonGroupsList?.find((item) => item.id === groupId);
			if (found) {
				// remove the unavailable addons
				const newGroup = Object.assign(new AddonGroupResponse(), found); // new instance
        		newGroup.addonList = Object.assign([], found.addonList.filter((a) => a.available)); // new instance		
				
				// make sure at least one add-on is available
				if (newGroup.addonList.length > 0) {
					acc.push(newGroup);
				}
			}
			return acc;
		  }, [] as AddonGroupResponse[]
		);

		this.openProductDetailsModal(modalData);
	}

	private openProductDetailsModal(data: CustomProductModalModel) {
		const modalConfig = this.modalService.getDefaultDialogConfig();
		modalConfig.data = data;
		modalConfig.width = "700px";
		modalConfig.disableClose = true;
		this.modalService.openCustomDialog(ProductDetailsModalComponent, modalConfig);
	}

	public onCategoryTabChange(event: any) {	
		const el = document.getElementById(event.tab.ariaLabel);

		// check if it is already selected so to skip it
		if (event.tab.ariaLabel === this.currentTab$.getValue()) {
			return;
		}

		this.currentTab$.next(event.tab.ariaLabel);
        this.currentTabIndex$.next(event.index);
		
		if (el) {			
			let offset = 140 //px:  to nav bar (70 height) + padding of the categories (10 top + 10 bottom) + the height of sticky categories (50 height)

			if (window.innerWidth <= 576 ) {
				offset = 130; // because the top nav header gets 10 px smaller
			}

			const y = el.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset;  // eleme - what is already scrolled - offset

			this.smoothScrollAnimationRunning$.next(true);
			
			window.scrollTo({top: y, behavior: 'smooth'}); // otherwise use "instant" instead of "smooth" - if you don't want to use the smoothScrollAnimationRunning$. Otherwise is buggy
			
			setTimeout(() => {
				this.smoothScrollAnimationRunning$.next(false);
			}, 1000)
		}
	}

	private handleWindowScroll() {

		if (this.smoothScrollAnimationRunning$.getValue()) {
			return; // ignore it, otherwise it gets buggy. Skip the resize while the scrolling is the automated one after category click
		}

		let categoryElements: any[] | undefined = [];
		
		if (this.restaurantData$.getValue()?.products?.categories && (this.restaurantData$.getValue()?.products?.categories || []).length > 0) {
			const list = this.restaurantData$.getValue()?.products.categories;
			categoryElements = list?.map((category, i) => {
				return {
					element: document.getElementById(category.categoryId),
					index: i
				}
			})
			
			const visibleElement = categoryElements?.find((item) => item.element.getBoundingClientRect().top > 10); // positive (visible)
			if (visibleElement) {

				const scrolledTo = window.scrollY + window.innerHeight;
				const isReachBottom = (scrolledTo + 10) > document.body.scrollHeight; // 10px offset to make sure it gets the scroll (sometimes it doesn't get it for 3-5 px)

				if (isReachBottom) {
					return; // skip
				}
				this.forceUpdateTab(visibleElement.element.id, visibleElement.index);
			}
		}
	}

	private forceUpdateTab(id: string, index: number) {
		this.currentTab$.next(id);
        this.currentTabIndex$.next(index);
	}


	// Add to basket
	public onPressAddToCart(event: MouseEvent, product: ProductInfo, productCategory: MenuCategoryWithSlimProducts) {

		event.preventDefault();
		event.stopImmediatePropagation(); // to make sure the entire tile doesn't open the product modal

		// Check if the restaurant is closed and skip adding product if true. Show a popup for that		
		if (this.restaurantData$.getValue()?.restaurantInfo.id) {
			const isClosed = this.restaurantData$.getValue()?.restaurantInfo.closed || this.isOutsideBusinessHours(this.restaurantData$.getValue());
			if (isClosed) {
				this.fullPageService.openClosedWarningPopup();
				return; // skip the rest of the code. stops here
			}			
		}

		// Check if the product has mandatory add-ons. If yes, open the modal instead
		const groupRequiredAndNotSelected = this.restaurantData$.getValue()?.addonGroups.find((group) => {
		  // if required
		  if (group.minSelection > 0) {        
			return !!product.addonGroupIds.find((selected) => {
			  return selected === group.id ;
			});
		  }
		  return false;
		})

		// if required, open product popup
		if (groupRequiredAndNotSelected) {
			// popup
			this.onPressProduct(product, productCategory.categoryId);
		  	return;  // skip the rest of the code. stops here
		}		

		// Create the order basket product:
		const restaurantId = this.restaurantData$.getValue()?.restaurantInfo.id || '';
		const restaurantName = this.restaurantData$.getValue()?.restaurantInfo.name || '';
		const restaurantSlug = this.restaurantData$.getValue()?.restaurantInfo.slug || '';
		const restaurantAddress = this.restaurantData$.getValue()?.restaurantInfo.address || new AddressResponse();
		const restaurantMinOrderAmount = this.restaurantData$.getValue()?.restaurantInfo.minOrderAmount || 0;
		const supportsDelivery = this.restaurantData$.getValue()?.restaurantInfo.hasOwnDelivery || this.restaurantData$.getValue()?.restaurantInfo.hasFoodisDelivery || false;
		const supportsPickup = this.restaurantData$.getValue()?.restaurantInfo.hasPickup || false;
		
		const p = new OrderCartProductItem();		
		p.productId = product.id;
		p.productName = product.name; //TODO: if it's english / de
		p.productCategoryId = productCategory.categoryId;
		p.productPrice = product.priceCents;
		p.productPriceDelivery = product.deliveryPriceCents;
		p.quantity = 1;
		p.specialInstructions = "";
		p.addons = [];

		this.orderBasketService.addProduct(restaurantId, restaurantName, restaurantSlug, restaurantAddress, restaurantMinOrderAmount, p, supportsDelivery, supportsPickup);
	}


	/*
		Search functionality 
	*/

	public onSearchProductUpdate(searchValue: string) {
		this.currentProductsSearchInput = searchValue;
		// Avoiding lowercase/uppercase mismatch
		searchValue = searchValue.toLowerCase();

		// Categories
        const categoryList = this.restaurantData$.getValue()?.products.categories || [];
        categoryList.forEach((c) => {

            const searchCategory = new SearchMap();
            searchCategory.id = c.categoryId;            
            searchCategory.type = "category";                
            
            // visibility            
            if (!searchValue || searchValue === "")  {
                searchCategory.visible = true; // reset
				searchCategory.expandedBySearch = false;
            } else {
                if (searchValue.length === 1) {
                    // first letter only (from any word separated by space)
                    const allWords = c.categoryName.toLocaleLowerCase().split(' ');
                    searchCategory.visible = allWords.some((w) => !!w && w.startsWith(searchValue)); 
                } else {
                    searchCategory.visible = c.categoryName.toLocaleLowerCase().includes(searchValue); // contains the input
                }
            }

			if (searchCategory.visible) {
				searchCategory.expandedBySearch = true;
			}
			
            // updating the category
            this.productsSearchResults.set(searchCategory.id, searchCategory);


            // Products
            let atLeastOneProductFound = false;
                           
			c.products.forEach((p) => {
				const searchProduct = new SearchMap();
				searchProduct.id = p.id;
				searchProduct.type = "product";           
				
				// visibility
				if (!searchValue || searchValue === "")  {
					searchProduct.visible = true
				} else {
					if (searchValue.length === 1) {
						// first letter only (from any word separated by space)                            
						const allWords = p.name.toLocaleLowerCase().split(' ');
						searchProduct.visible = allWords.some((w) => !!w && w.startsWith(searchValue)); 
					} else {
						searchProduct.visible = p.name.toLocaleLowerCase().includes(searchValue);
					}
				}

				if (!!searchValue && searchProduct.visible) {
					searchCategory.expandedBySearch = true;                            
					this.productsSearchResults.set(searchCategory.id, searchCategory);
					atLeastOneProductFound = true;
				}

				// updating the product
				this.productsSearchResults.set(searchProduct.id, searchProduct);
			});

			if (atLeastOneProductFound) {
				searchCategory.visible = true;
				searchCategory.expandedBySearch = true;
				// updating the category
				this.productsSearchResults.set(searchCategory.id, searchCategory);
			}
        });
	}

	public onSearchSeeMoreCategoryTap(category: MenuCategoryWithProducts) {
		category.products.forEach((p) => {
			const searchProduct = new SearchMap();
			searchProduct.id = p.id;
			searchProduct.type = "product";   
			searchProduct.visible = true;
			searchProduct.expandedBySearch = true;        
			// updating the product
			this.productsSearchResults.set(searchProduct.id, searchProduct);
		});

		const searchCategory = new SearchMap();
		searchCategory.id = category.categoryId;            
		searchCategory.type = "category";
		searchCategory.visible = true;
		searchCategory.expandedBySearch = true;
		searchCategory.expandedBySeeMore = true;            
		 // updating the category
		 this.productsSearchResults.set(searchCategory.id, searchCategory);
	}

	public clearProductSearchPress() {
		this.onSearchProductUpdate("");
	}	
}

class SearchMap {
	id: string = "";
	type: "" | "product" | "category"  = "";
	expandedBySearch: boolean = false;
	expandedBySeeMore: boolean = false;
	visible: boolean = true;
}
