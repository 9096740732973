import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Observable, fromEvent} from "rxjs";
import { AuthService } from "libs/shared-services/src/lib/auth.service";
import { UserAddressFacade} from "../../services/user-address/user-address.facade.service";
import { AddressResponse} from "libs/shared-models/src/lib/address-response";
import { environment} from "../../../environments/environment";
import { BlurBackgroundService } from "../../services/background-handler/blur-background.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DeliveryOption, TopNavBarService } from '../../services/navbar/top-navbar.service';
import { MatDrawer } from '@angular/material/sidenav';
import { OrderBasketService } from '../../services/order-basket/order-basket.service';
import { CurrentProductService } from 'libs/shared-services/src/lib/current-product.service';
import { Product } from 'libs/shared-models/src/lib/product-type';
import { NavigationEnd, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss'],
})
export class TopNavBarComponent {

    private sideMenuDrawer: MatDrawer;
    @ViewChild('sideMenuDrawer', { static: false }) set content(content: MatDrawer) {        
        if(content) {
            this.sideMenuDrawer = content;
        }
    }

    public selfData$ = this.topNavBarService.getSelfData$();
    public deliveryOption$ = this.topNavBarService.getDeliveryOption$();
    public orderBasketData$ = this.orderBasketService.getState$();
    public basketItemsCount$ = this.orderBasketService.basketItemsCount$.asObservable();

    public mobileMenuVisible$: Observable<boolean> = this.topNavBarService.mobileMenuVisible$();    
    public hideDeliveryBtn$: Observable<boolean> = this.topNavBarService.hideDeliveryButtons$.asObservable();
    public isOrderBasketOpenInPopup$: Observable<boolean> = this.topNavBarService.isOrderBasketOpenInPopup$.asObservable();
    public isCheckoutPage: boolean = false;
    public isMobileLayout: boolean = false;

    public DeliveryOption: typeof DeliveryOption = DeliveryOption;

    constructor(
        private authService: AuthService,
        private userAddressFacade: UserAddressFacade,
        private blurBackgroundService: BlurBackgroundService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private topNavBarService: TopNavBarService,
        private orderBasketService: OrderBasketService,
        private currentProductService: CurrentProductService
    ) {
    }

    public ngOnInit() {        
        
        // address background update
        this.isAddressOpen$().pipe(untilDestroyed(this)).subscribe((val) => {
          if (!val) {
            this.blurBackgroundService.setBlurBackground(false);
            this.ref.detectChanges();
          }
        })

        // in case address is forcely opened (eg: from missing address flow)
        this.topNavBarService.getForceAddressOpen$().pipe(untilDestroyed(this)).subscribe((value) => {
            this.blurBackgroundService.setBlurBackground(value);
            this.userAddressFacade.setAddressOpen(value);
        })

        this.routeHandling();
        this.listenToWindowResize();
    }

    private routeHandling() {
        this.checkRoute(this.router.url);
        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.checkRoute(event.url);
          }
        });
    }
    
    private checkRoute(url: string) { 
        this.isCheckoutPage = url.includes("/checkout");
        this.checkIsMobileCheckout();
    }

    private listenToWindowResize() {
        fromEvent(window, 'resize').pipe(untilDestroyed(this)).subscribe((a) => {            
            this.checkIsMobileCheckout();
        });
    }

    private checkIsMobileCheckout() {
        const width = window.innerWidth;
        const isMobile = width <= 576;
        this.isMobileLayout = isMobile;
    }
      

    /*
        Delivery related
     */

    public onPressDelivery() {
        this.topNavBarService.setDeliveryOption(DeliveryOption.DELIVERY);
    }

    public onPressPickup() {
        this.topNavBarService.setDeliveryOption(DeliveryOption.PICKUP);
    }

    /*
        Profile related
    */  

    public isLoggedIn$(): Observable<boolean> {
        return this.authService.$getLoginState();
    }

    public onPressLogin() {
        this.currentProductService.setProduct(Product.USER_APP);
        window.location.href = environment.PRODUCT_URLS.ACCOUNT;
    }

    public onPressSignup() {
        this.currentProductService.setProduct(Product.USER_APP);
        window.location.href = environment.PRODUCT_URLS.ACCOUNT_REGISTRATION;
    }

    // Logout & clear user data from services
    public doLogout() {
        // clear states
        this.topNavBarService.clearSelfData();        
        this.userAddressFacade.clearDataOnLogout();
             
        // logout
        this.authService.doLogout(false);
    }

    /*
        Address related
    */

    public onAddressClick(event: any) {
        let currValue = this.userAddressFacade.isAddressOpen();
        currValue = !currValue;
        this.blurBackgroundService.setBlurBackground(currValue);
        this.userAddressFacade.setAddressOpen(currValue);
    }

    public isAddressOpen$(): Observable<boolean> {
        return this.userAddressFacade.isAddressOpen$();
    }
    
    public getCurrentAddress$(): Observable<AddressResponse> {
        return this.userAddressFacade.getCurrentAddress$();
    }


    /*
        Mobile menu related
    */
    public onTapMobileHamburgerMenu() {
        // setting view value
        this.topNavBarService.setMobileMenuVisible(true);
        // opening the menu
        setTimeout(() => {            
            this.sideMenuDrawer.open();
        }, 0) // "0" is a hack as the this.sideMenuDrawer gets the value after it is made true in the ngIf view. And it gets the instance on the ViewChild afterwards in set content
    }

    public closeMobileSideMenu() {
        this.sideMenuDrawer.close().then(() => {
            this.topNavBarService.setMobileMenuVisible(false);
        });
    }

    public onChangeMobileNavState(value: boolean) {
        this.topNavBarService.setMobileMenuVisible(value);
    }


    /*
        Order basket
    */

    public onPressOrderBasket() {
        this.topNavBarService.toggleOrderCart();
    }
}