import { Injectable} from '@angular/core';
import { ApiBaseService } from 'libs/shared-services/src/lib/api-base.service';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'apps/user-app/src/environments/environment';
import { PaymentIntentResponse } from '../../models/payment-intent-response';

@Injectable({
    providedIn: 'root',
})
export class StripeService {

    constructor(
        private apiService: ApiBaseService,
        private toasterService: ToasterService
    ) {
    }

    public requestPaymentOrderAPI$(orderId: string): Observable<PaymentIntentResponse> {
      
        const path = environment.API_STRIPE_PAYMENT_INTENT.replace("{{order_id}}", orderId);
        const apiCall = this.apiService.get(path);

        return apiCall.pipe(
            map((res) => {
                const paymentResponse = Object.assign(new PaymentIntentResponse(), res);
                return paymentResponse;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(null);
            })
        );
    }
}