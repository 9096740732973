
<!-- 
    Mobile-only left overlay menu
-->
<ng-container *ngIf="mobileMenuVisible$ | async">
    <div class="mobile-nav-bar">
        <mat-drawer-container class="side-drawer-container" autosize [hasBackdrop]="true">
            <!-- Opened content (left side)-->
            <mat-drawer #sideMenuDrawer class="side-drawer-content" mode="side" (openedChange)="onChangeMobileNavState($event)">
                
                <!-- All the side menu content-->
                <div class="all-side-menu-content">

                    <!-- Close button-->
                    <div class="side-menu-close">
                        <div class="side-menu-close-icon" (mousedown)="closeMobileSideMenu()">
                            <mat-icon class="side-menu-close-icon">close</mat-icon>
                        </div>
                    </div>

                    <!-- Logo -->
                    <div class="flex justify-center">
                        <div class="mobile-nav-logo">
                            <logo-user-app (logoPress)="closeMobileSideMenu()"></logo-user-app>
                        </div>
                    </div>       
                    
                    <!-- Menu -->
                    <div class="mobile-menu-container">
                        <!-- Mobile Name / Profile / address / logout buttons -->
                        <ng-container *ngIf="(isLoggedIn$() | async); else mobileVisitorProfile">                        
                                <div class="mobile-menu-items">
                                    <!-- Account name -->
                                    <div class="mobile-logged-in-container">
                                        <img [src]="'apps/user-app/src/assets/nav-bar-profile.svg'" alt="Profile name"/>
                                        <div class="logged-in-text"> {{ (selfData$ | async)?.name }}</div>
                                    </div>
                                    <!-- Menu buttons-->
                                    <a href="dashboard"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">home</mat-icon>{{ "nav_bar_menu_home" | translate }}</button></a>
                                    <a href="user/profile"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">person_outline</mat-icon>{{ "nav_bar_menu_profile" | translate }}</button></a>
                                    <a href="user/orders"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">list</mat-icon>{{ "nav_bar_menu_orders"  | translate }}</button></a>
                                    <a href="user/address"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">location_on</mat-icon>{{ "nav_bar_menu_address"  | translate }}</button></a>      
                                    <a href="user/payment"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">payment</mat-icon>{{ "nav_bar_menu_payment"  | translate }}</button></a>
                                    <button mat-menu-item (click)="doLogout()"><mat-icon class="mr-3" class="default-icon-color">exit_to_app</mat-icon>{{ "nav_bar_menu_logout"  | translate }}</button>
                                </div>   
                        </ng-container>
                        <!-- Mobile logged out state -->
                        <ng-template #mobileVisitorProfile>
                            <div class="flex flex-col">
                                <button mat-menu-item (click)="onPressLogin()">
                                    <div class="flex flex-row items-center">
                                        <img class="mr-3" [src]="'apps/user-app/src/assets/nav-bar-profile.svg'" alt="Log in"/>
                                        {{ "nav_bar_menu_login" | translate }}
                                    </div>                                
                                </button>

                                <button mat-menu-item (click)="onPressSignup()">
                                    <div class="flex flex-row items-center">
                                        <img class="mr-3" [src]="'apps/user-app/src/assets/nav-bar-sign-up-plus-right.svg'" alt="Sign up"/>
                                        {{ "nav_bar_menu_signup" | translate }}
                                    </div>
                                </button>
                            </div>
                        </ng-template>
                                      
                        <!-- Language & made with (bottom)-->
                        <div class="language-made-container">
                            <!-- Language menu -->
                            <div class="language-mobile-menu">
                                {{ 'nav_bar_menu_language' | translate }}<nav-language-selector></nav-language-selector>
                            </div>
                            <!-- Temporary commented - to check if it really makes sense to have it -->
                            <!-- <made-in-vienna></made-in-vienna> -->
                        </div>                            
                    </div>   


                </div>
            </mat-drawer>
        </mat-drawer-container>
    </div>
</ng-container>    


<!-- 
    Desktop and mobile main container
-->

<div class="main-container">

    <!-- Hamburger button -->
    <div class="hamburger-menu-mobile" (mousedown)="onTapMobileHamburgerMenu()">
        <mat-icon class="hamburger-menu-mobile-icon">menu</mat-icon>
    </div>

    <!-- Logo -->
    <div class="left-container">
        <div class="nav-logo">
            <logo-user-app></logo-user-app>
        </div>
    </div>

    <!-- Address -->
    <div class="middle-address-container" *ngIf="!(isMobileLayout && isCheckoutPage); else checkoutTitle">
        <button mat-flat-button class="entire-address-button" (click)="onAddressClick($event)">
            <div class="address-action-container">
                <div class="icon-location">
                    <img [src]="'apps/user-app/src/assets/location-pin-main.svg'" alt="Address location pin"/>
                </div>

                <div class="address-title-text">
                    {{ 'nav_bar_address_title' | translate }}
                </div>

                <div class="user-address-content" [ngClass]="{ 'empty': (getCurrentAddress$() | async)?.isEmpty() }">
                    <!-- No address -->
                    <ng-container *ngIf="(getCurrentAddress$() | async)?.isEmpty(); else existingAddress">
                        <div class="user-address-street empty font-semibold" [ngClass]="{ 'opened': (isAddressOpen$() | async) }">{{ "address_no_existing_address" | translate }}</div>
                        <img class="address-down-icon" [ngClass]="{ 'opened': (isAddressOpen$() | async) }" [src]="'apps/user-app/src/assets/arrow-drop-down-address.svg'" alt="Address location button"/>
                    </ng-container>

                    <!-- Existing Address -->
                    <ng-template #existingAddress>
                        <div class="user-address-street">{{ (getCurrentAddress$() | async)?.getStreetName()  }} {{ (getCurrentAddress$() | async)?.getStreetNumber()  }}</div>
                        <div class="user-address-zip-city">{{ (getCurrentAddress$() | async)?.getPostalCode()  }}, {{ (getCurrentAddress$() | async)?.getCity()  }}</div>
                        <img class="dropdown-img-src" [src]="'apps/user-app/src/assets/arrow-drop-down-address.svg'" alt="Address location button"/>
                    </ng-template>
                </div>
            </div>
        </button>
    </div>

    <ng-template #checkoutTitle>
        <div class="checkout-title">
            {{ "checkout_title" | translate }}
        </div>        
    </ng-template>

    <!-- Delivery type -->
    <div class="delivery-type-container">
        <ng-container *ngIf="(hideDeliveryBtn$ | async) === false">
            <!-- Delivery -->
            <button-delivery-type 
                [icon]="'delivery-type-bicycle'" 
                [state]="(deliveryOption$ | async) === DeliveryOption.DELIVERY ? 'selected' : ''" 
                [text]="'nav_bar_delivery_type_home' | translate" 
                (onPress)="onPressDelivery()">
            </button-delivery-type>
            <!-- Pickup -->
            <button-delivery-type 
                [icon]="'delivery-type-pick-up-hand'" 
                [state]="(deliveryOption$ | async) === DeliveryOption.PICKUP ? 'selected' : ''" 
                [text]="'nav_bar_delivery_type_pickup' | translate" 
                (onPress)="onPressPickup()" class="pl-3">   
            </button-delivery-type>
        </ng-container>
        
    </div>
    <!-- (Mobile-only) delivery type-->
    <div class="delivery-type-container-mobile" *ngIf="!(isMobileLayout && isCheckoutPage)">
        <!-- delivery as main button-->
        <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.DELIVERY">
            <button mat-button [matMenuTriggerFor]="menuDelivery">
                <div class="deliver-type-mobile-text-selected">
                    <img class="deliver-type-mobile-src"[src]="'apps/user-app/src/assets/delivery-type-bicycle-selected.svg'" alt="Home delivery"/>
                    <div> {{ 'nav_bar_delivery_type_home' | translate }}</div>
                    <img class="ml-1" [src]="'apps/user-app/src/assets/arrow-drop-down-address.svg'" alt="Food delivery options"/>
                </div>
            </button>
        </ng-container>
        <!-- pickup as main button -->
        <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.PICKUP">
            <button mat-button [matMenuTriggerFor]="menuDelivery">
                <div class="deliver-type-mobile-text-selected">
                    <img class="deliver-type-mobile-src" [src]="'apps/user-app/src/assets/delivery-type-pick-up-hand-selected.svg'" alt="Pickup"/>
                    <div> {{ 'nav_bar_delivery_type_pickup' | translate }}</div>
                    <img class="ml-1" [src]="'apps/user-app/src/assets/arrow-drop-down-address.svg'" alt="Food delivery options"/>
                </div>
            </button>
        </ng-container>
        
        <!-- dropdown buttons-->
        <mat-menu #menuDelivery="matMenu">            
            <!-- delivery as secondary button-->
            <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.PICKUP">
                <button mat-menu-item (click)="onPressDelivery()">
                    <div class="logged-in-container">
                        <img class="deliver-type-mobile-src" [src]="'apps/user-app/src/assets/delivery-type-bicycle.svg'" alt="Home food delivery"/>
                        <div class="deliver-type-mobile-text"> {{ 'nav_bar_delivery_type_home' | translate }}</div>
                    </div>                
                </button>
            </ng-container>
            <!-- pickup as secondary button-->
            <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.DELIVERY">
                <button mat-menu-item (click)="onPressPickup()">
                    <div class="logged-in-container">
                        <img class="deliver-type-mobile-src" [src]="'apps/user-app/src/assets/delivery-type-pick-up-hand.svg'" alt="Pick-up"/>
                        <div class="deliver-type-mobile-text"> {{ 'nav_bar_delivery_type_pickup' | translate }}</div>
                    </div>                
                </button>
            </ng-container>
        </mat-menu>
    </div>

    <!-- Cart Order -->
    <div class="order-cart-container">
        <div class="order-cart-button" *ngIf="(hideDeliveryBtn$ | async) === false " (mousedown)="onPressOrderBasket()">
            <img class="order-cart-img-src" [src]="'apps/user-app/src/assets/order-basket.svg'" alt="Delivery food orders cart"/>
            <div class="order-cart-text">({{(basketItemsCount$ | async) || 0}})</div>
            <ng-container *ngIf="((basketItemsCount$ | async) || 0) > 0">
                <div class="order-cart-order">
                    {{ "nav_bar_delivery_order_cart" | translate }}
                </div>
            </ng-container>
        </div>
    </div>

    <!-- Language menu -->
    <div class="language-container">
        <nav-language-selector></nav-language-selector>
    </div>

    <!-- Profile -->
    <div class="mini-profile-container">
        <ng-container *ngIf="(isLoggedIn$() | async); else visitorProfile">

            <button mat-button [matMenuTriggerFor]="menu">
                <div class="logged-in-container">
                    <img [src]="'apps/user-app/src/assets/nav-bar-profile.svg'" alt="Profile name"/>
                    <div class="logged-in-text"> {{ (selfData$ | async)?.name }}</div>
                    <img [src]="'apps/user-app/src/assets/arrow-drop-down-address.svg'" alt="Food profile details"/>
                </div>
            </button>
            <mat-menu #menu="matMenu">
                <!-- Menu buttons-->
                <a href="dashboard"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">home</mat-icon>{{ "nav_bar_menu_home" | translate }}</button></a>
                <a href="user/profile"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">person_outline</mat-icon>{{ "nav_bar_menu_profile" | translate }}</button></a>
                <a href="user/orders"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">list</mat-icon>{{ "nav_bar_menu_orders"  | translate }}</button></a>
                <a href="user/address"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">location_on</mat-icon>{{ "nav_bar_menu_address"  | translate }}</button></a>
                <a href="user/payment"><button mat-menu-item><mat-icon class="mr-3" class="default-icon-color">payment</mat-icon>{{ "nav_bar_menu_payment"  | translate }}</button></a>
                <button mat-menu-item (click)="doLogout()"><mat-icon class="mr-3" class="default-icon-color">exit_to_app</mat-icon>{{ "nav_bar_menu_logout"  | translate }}</button>
            </mat-menu>

        </ng-container>
        <ng-template #visitorProfile>
            <div class="logged-out-container" (click)="onPressLogin()">
                <img [src]="'apps/user-app/src/assets/nav-bar-profile.svg'" alt="Log in"/>
                <div class="logged-out-text">{{ 'nav_bar_menu_login' | translate }}</div>
            </div>
            <div class="logged-out-container-sign-up" (click)="onPressSignup()">
                <img [src]="'apps/user-app/src/assets/nav-bar-sign-up-plus-right.svg'" alt="Log in"/>
                <div class="logged-out-text">{{ 'nav_bar_menu_signup' | translate }}</div>
            </div>
        </ng-template>
    </div>
</div>

<!-- Address overall -->
<ng-container *ngIf="isAddressOpen$() | async">
    <div class="address-wrapper">
        <address-editor></address-editor>
    </div>
</ng-container>


<!-- Order cart popup -->
<ng-container *ngIf="isOrderBasketOpenInPopup$ | async">
    <div class="order-basket-wrapper">
        <order-basket></order-basket>
    </div>
</ng-container>