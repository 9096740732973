import { AllergenEnum } from "./AllergenEnum";

export class ProductInfo {
    public id: string = "";
    public name: string = "";
    public nameEn: string = "";
    public description: string = "";
    public descriptionEn: string = "";
    public priceCents: number = 0;
    public deliveryPriceCents: number = 0;
    public available: boolean = false;
    public allergenList: AllergenEnum[];
    public addonGroupIds: string[] = [];
    public imageUrl: string = "";    

    constructor() {
    }
}
