import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { DeliveryOption } from "libs/shared-models/src/lib/delivery-option";
import { AddonGroupResponse } from "libs/shared-models/src/lib/restaurant/addon-group-response";
import { ProductInfo } from "libs/shared-models/src/lib/restaurant/product-info";

export class CustomProductModalModel {
    restaurantId: string = "";
    restaurantName: string = "";
    restaurantSlug: string = "";
    restaurantAddress: AddressResponse = new AddressResponse();
    restaurantMinOrderAmount: number = 0;
    product: ProductInfo = new ProductInfo();
    productCategoryId: string = "";    
    addonGroups: AddonGroupResponse[] = [];
    quantity: number = 1;
    selectedAddons: SelectedAddon[] = [];
    deliveryType: DeliveryOption;
    specialInstructions: string = "";
    supportsDelivery: boolean = false;
    supportsPickup: boolean = false;
}

export class SelectedAddon {
    addonGroupId: string; 
    addonIdsList: string[] = [];
}