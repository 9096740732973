<div class="address-text">
    <!-- Icon & title -->
    <div class="address-title">
        <div class="address-delivery-icon">
            <img [src]="'apps/user-app/src/assets/delivery-type-bicycle-selected.svg'"/>
        </div>
        {{ 'checkout_delivery_address' | translate }}
    </div>

    <!-- Street -->
    <div class="address-street pb-1">
        <div class="address-label">{{ 'checkout_delivery_street' | translate  }}: </div>
        <div class="address-content bold">
            {{ 
                (userAddress$ | async)?.streetName + " " +
                (userAddress$ | async)?.streetNumber
            }}
        </div>
    </div>

    <div class="flex flex-row pb-1">
        <!-- Door -->
        <div class="flex flex-row mr-5">
            <div class="address-label">{{ 'checkout_delivery_door' | translate  }}: </div>
            <div class="address-content bold">{{ (userAddress$ | async)?.doorNumber || '-' }}</div>
        </div>
        <!-- Floor -->
        <div class="flex flex-row">
            <div class="address-label">{{ 'checkout_delivery_floor' | translate  }}: </div>
            <div class="address-content">{{ (userAddress$ | async)?.floor || '-' }}</div>
        </div>
    </div>

    <!-- Entrance -->
    <div class="flex flex-row pb-1">
        <div class="address-label">{{ 'checkout_delivery_entrance' | translate  }}: </div>
        <div class="address-content">{{ (userAddress$ | async)?.entrance || '-' }}</div>
    </div>

    <!-- Specific details -->
    <div class="flex flex-row pb-1">
        <div class="address-label">{{ 'checkout_delivery_specific' | translate  }}: </div>
        <div class="address-content">{{ (userAddress$ | async)?.additionalInfo || '-' }}</div>
    </div>

    <!-- Postal code, city -->
    <div class="flex flex-row pt-3">
        <div class="address-content">
            {{ (userAddress$ | async)?.postalCode }}
            {{ (userAddress$ | async)?.district }},
            {{ (userAddress$ | async)?.city }}
        </div>
    </div>

    <!-- Default edit button -->
    <ng-container *ngIf="usage === 'default'">
        <!-- Edit address button -->
        <ng-container>
            <div class="address-edit-area" (mouseup)="onClickAddressEdit()">
                <span class="address-edit-image">
                    <img [src]="'apps/user-app/src/assets/edit-pencil.svg'"/>
                </span>
                {{ "checkout_address_edit" | translate }}
            </div>
        </ng-container>  
    </ng-container>

    <!-- User address page usage -->
    <ng-container *ngIf="usage === 'user-address-page'">
        <div class="user-address-page-buttons">
            <fs-button [text]="'address_page_edit_button' | translate"
                [type]="'raised'" [leadingIcon]="'edit'"
                (onPress)="onClickAddressEdit()"
                class="user-page-btn">
            </fs-button>
            <fs-button [text]="'address_page_new_button' | translate"
                [type]="'raised'" [leadingIcon]="'search'"
                (onPress)="onClickAddressSearch()"
                class="user-page-btn">
            </fs-button>
        </div>
    </ng-container>
    
</div>