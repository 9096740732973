import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { UserAddressFacade } from './user-address/user-address.facade.service';
import { TopNavBarService } from './navbar/top-navbar.service';
import { DashboardService } from './dashboard/dashboard-service';
import { OrderBasketService } from './order-basket/order-basket.service';


@Injectable({
  providedIn: 'root'
})
export class MainServicesInitService {

    private allLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
      private userAddressFacade: UserAddressFacade,
      private topNavBarService: TopNavBarService,
      private dashboardService: DashboardService,
      private orderBasketService: OrderBasketService,
    ) {
    }

    public initServices() {

        // address
        this.userAddressFacade.initAddress();
        // top navbar
        this.topNavBarService.initNavBar();
        // dashboard
        this.dashboardService.initService();
        // order basket
        this.orderBasketService.initService();

        combineLatest([
            this.userAddressFacade.isFinished$(),
            this.topNavBarService.isFinished$(),
            this.dashboardService.isFinished$(),
            this.orderBasketService.isFinished$(),
        ]).subscribe(
            ([userAddress, topNavBar, dashboard, orderBasket]) => {

                // if already loaded before and some service emits again, ignore it
                if (this.allLoaded$.getValue()) {
                    return; 
                }

                // make sure all are loaded
                if (userAddress && topNavBar && dashboard && orderBasket) {
                    this.allLoaded$.next(true);
                }            
            }
        );
    }

    public allServicesLoaded$(): Observable<boolean> {
        return this.allLoaded$.asObservable();
    }
}
