import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import { CustomLocalFilters } from './model/custom-local-filters';
import { CuisineCategory, DashboardService } from 'apps/user-app/src/app/services/dashboard/dashboard-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'filters-modal',
  templateUrl: './filters-modal.component.html',
  styleUrls: ['./filters-modal.component.scss'],
})
export class FiltersModalComponent implements OnInit {
    /*
        Local data
     */
    public tempData$: BehaviorSubject<CustomLocalFilters> = new BehaviorSubject<CustomLocalFilters>(new CustomLocalFilters());

    public showClearFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CustomLocalFilters,
        private dialogRef: MatDialogRef<FiltersModalComponent>,
        private dashboardService: DashboardService
    ) {
    }

    public ngOnInit() {

        // Assign the data received on Modal creation
        const newData = Object.assign(new CustomLocalFilters(), this.getDialogData()); // new instance
        newData.cuisineCategories = this.getDialogData().cuisineCategories.map((item) => {
          return {...item}
        })

        this.tempData$.next(Object.assign(new CustomLocalFilters(), newData));

        // Listen to UI modal backdrop:
        this.dialogRef.backdropClick().subscribe(() => {
            this.onCancel();
        });

        // wait for changes and check if it's different than default
        this.tempData$.pipe(untilDestroyed(this)).subscribe((data) => {
          const categories = data.cuisineCategories.filter((c) => c.selected);
          const hasFiltersActivated = (data.onlyOpen !== this.dashboardService.DEFAULT_SHOW_OPEN) || (categories.length > 0);            
          this.showClearFilters$.next(hasFiltersActivated);
        })
    }

    private getData(): CustomLocalFilters {
        return this.tempData$.getValue();
    }

    private setData(val: CustomLocalFilters) {
        this.tempData$.next(val);
    }

    // The data added on Modal creation (injected in the dialog from parent call)
    private getDialogData(): CustomLocalFilters {
      return this.data;
    }

    // Popup cancel
    public onCancel() {
      this.dialogRef.close();
    }

  // Create button status
  public isButtonDisabled$(): Observable<boolean> {
    return this.tempData$.pipe(
      map(data => {
        const newData = Object.assign(new CustomLocalFilters(), data);
        return JSON.stringify(newData) === JSON.stringify(this.getDialogData()); // compare to the initial one before the popup was opened
      })
    );
  }

  public onOpenNowToggleClick(event: any) {
    const currentData = this.getData();
    currentData.onlyOpen = event.checked;
    this.setData(currentData);
  }

  // Cuisine item click
  public onCuisineChange(event: any, selectedItem: CuisineCategory) {
    const currentData = this.getData();    
    currentData.cuisineCategories = currentData.cuisineCategories.map((item) => {
      if (item.id === selectedItem.id) {
        item.selected = event.selected;
      }
      return item;
    })
    this.setData(currentData);
  }

  public onPressClearFilters() {
    const currentData = this.getData();
    currentData.onlyOpen = false;
    currentData.cuisineCategories = currentData.cuisineCategories.map((c) => {
      c.selected = false;
      return c;
    })
    this.setData(currentData);
  }

  // OK - close popup & apply filters
  public onOKButtonClick() {
    this.dialogRef.close({data: this.getData()});
  }
}
