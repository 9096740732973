<div class="main-container">

    <ng-container *ngIf="!!(restaurantData$ | async) && !!(restaurantData$ | async)?.restaurantInfo?.id">
        <div class="main-items">

            <!-- Back button -->
            <a [routerLink]="'/'">
                <div class="back-button">
                    <mat-icon class="back-button-mat">arrow_back_ios</mat-icon>
                    {{ "restaurant_page_back" | translate }}
                </div>
            </a>

            <!-- 
                Logo & Background image
            -->
            <div class="r-info-logo-background">                                    
                <!-- Logo -->
                <div class="r-info-logo-container">     
                <ng-container *ngIf="!!(restaurantData$ | async)?.restaurantInfo?.logoImageUrl">
                    <div class="restaurant-logo-image-filled">
                        <img class="logo-image-src" [src]="(restaurantData$ | async)?.restaurantInfo?.logoImageUrl || ''"/>
                    </div>
                </ng-container>
            </div>           
            <!-- Background -->
            <div class="r-info-background-container">
                <ng-container *ngIf="!!(restaurantData$ | async)?.restaurantInfo?.mainImageUrl; else missingBackgroundImage">
                    <div class="restaurant-image-filled">
                        <img [src]="(restaurantData$ | async)?.restaurantInfo?.mainImageUrl || ''"/>
                    </div>
                </ng-container>     
                <ng-template #missingBackgroundImage>
                    <div class="missing-restaurant-image-container">
                        {{ "restaurant_image_not_provided" | translate }}
                    </div>
                </ng-template>  
            </div>     
        </div>

        <!-- 
                Main info row
            -->
        <div class="main-row">
                <div class="main-row-left">
                    <!-- Name -->
                    <div class="name-container">
                        {{ (restaurantData$ | async)?.restaurantInfo?.name }}
                    </div>

                    <!-- Rating -->
                    <div class="rating-container">
                        <img class="restaurant-distance-src" [src]="'apps/user-app/src/assets/star-review.svg'"/>
                        <div class="restaurant-distance-text">
                            4.5
                        </div>
                    </div>

                    <!-- Delivery time -->
                    <div class="delivery-time-container">
                        <div class="delivery-time-icon">
                            <img class="delivery-time-icon-src" [src]="'apps/user-app/src/assets/time-hours.svg'"/>
                        </div>
                        <div class="delivery-time-text">
                            {{ ("restaurant_delivery_time_" + ((restaurantData$ | async)?.restaurantInfo?.deliveryTimeRange || '').toLowerCase()) | translate }}    
                        </div> 
                    </div>

                    <!-- Distance (km) -->
                    <div class="distance-container">
                        <img class="restaurant-distance-src" [src]="'apps/user-app/src/assets/distance-pinpoint.svg'"/>
                        <div class="restaurant-distance-text">
                            {{ ((distanceToMyAddress(restaurantData$ | async)) || 0) | format_distance }}
                        </div>
                    </div>

                    <!-- Cuisines list -->
                    <div class="cuisine-container">
                        <ng-container *ngFor="let cuisine of (restaurantData$ | async)?.restaurantInfo?.categoryList; let i = index">                                
                            {{ ("restaurant_info_category_" + cuisine.toLowerCase()) | translate }}<ng-container *ngIf="i < ((restaurantData$ | async)?.restaurantInfo?.categoryList?.length || 0) - 1">,</ng-container>
                        </ng-container> 
                    </div>
                </div>
                

                <!-- Favourite and More info -->
                <div class="more-container">
                    <div class="favourite-container">
                        <div class="delivery-time-icon" (mousedown)="isFavourite = !isFavourite">
                            <img class="favourite-icon-src" [src]="'apps/user-app/src/assets/' + (isFavourite ? 'favourite-heart-filled' : 'favourite-heart-empty') + '.svg'" [title]="'restaurant_page_favourite' | translate"/>
                        </div>
                    </div>
                    <div class="more-info-container">
                        <fs-button [text]="'restaurant_page_more_info' | translate"
                                [type]="'raised'" [leadingIcon]="'info'" (onPress)="onPressMoreInfo()">
                        </fs-button>
                    </div>
                </div>
        </div>

        <!-- 
            Closed message 
        -->
        <ng-container *ngIf="(restaurantData$ | async)?.restaurantInfo?.closed || isOutsideBusinessHours(restaurantData$ | async)">
            <div class="closed-message-container">
                <div class="closed-message-row-1">
                    <div class="closed-message-icon">
                        <mat-icon>error</mat-icon>
                    </div>
                    <div class="closed-message-title">
                        {{ "restaurant_page_not_available" | translate }}
                    </div>
                </div>
                <div class="closed-message-row-2">                    
                    <ng-container *ngIf="(restaurantData$ | async)?.restaurantInfo?.closed || false">
                        <div>{{ 'restaurant_currently_closed' | translate }}</div>  
                    </ng-container>                                         
                                                                
                    <!-- Open, but not available due to opening hours-->
                    <ng-container *ngIf="!(restaurantData$ | async)?.restaurantInfo?.closed && isOutsideBusinessHours(restaurantData$ | async)">
                        <ng-container *ngIf="!!getNextAvailableHours(restaurantData$ | async)">
                            <div>{{ 'restaurant_closed_until' | translate }} </div>     
                        </ng-container>
                        <!-- Next available date -->     
                        <ng-container *ngIf="!!getNextAvailableHours(restaurantData$ | async); else nextDateNotAvailable">
                            <div class="pl-2">{{ getNextAvailableHours(restaurantData$ | async) }}</div>  
                        </ng-container>
                        <!-- Missing next date -->
                        <ng-template #nextDateNotAvailable>
                            <div> 
                                <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.DELIVERY; else pickUpNotAvailable">
                                    {{ 'restaurant_hours_not_available_delivery' | translate }}
                                </ng-container>
                                <ng-template #pickUpNotAvailable>
                                    {{ 'restaurant_hours_not_available_pickup' | translate }}
                                </ng-template>
                            </div>
                        </ng-template>
                    </ng-container>                                            
                    
                </div>
            </div>            
        </ng-container>

        <!-- 
                Product categories list
            -->

            <!-- Header list -->
            <div class="product-categories-container">
                <div class="product-categories">
                    <mat-tab-group class="h-full" (selectedTabChange)="onCategoryTabChange($event)" [selectedIndex]="(currentTabIndex$ | async)">    
                        <ng-container *ngFor="let productCategory of (restaurantData$ | async)?.products?.categories">
                            <mat-tab [aria-label]="productCategory.categoryId">
                                <ng-template mat-tab-label> {{ productCategory.categoryName }}</ng-template>                    
                            </mat-tab>
                        </ng-container>    
                    </mat-tab-group>

                    
                </div>
            </div>       

            <div class="search-input-container">
                <!-- Search bar -->
                <div class="product-search" >
                    <ng-container *ngIf="!!currentProductsSearchInput"> 
                        <div class="product-search-clear">
                            <fs-link [text]="'restaurant_page_search_clear' | translate" [buttonBehavior]="true" (press)="clearProductSearchPress()"></fs-link>
                        </div>
                    </ng-container>
                    <fs-input [label]="'restaurant_page_search_label' | translate" [style]="'full-width'" class="mr-4 product-search-input"
                        [appearanceStyle]="'outline'" [subscriptSizing]="'dynamic'" [floatLabel]="'always'"
                        (valueUpdate)="onSearchProductUpdate($event)" [inputValue]="currentProductsSearchInput"
                        [prefixIcon]="'search'" [trailingIcon]="!!currentProductsSearchInput ? 'close' : ''" 
                        (trailingIconPress)="clearProductSearchPress()" [keepTrailingIconSpace]="true"
                        >
                    </fs-input>
                    
                </div>
            </div>
            
            

            <!-- Content - list of categories and products-->
            <div class="product-categories-expanded">

                <div class="product-category-item">
                    <!-- Each category loop -->
                    <ng-container *ngFor="let productCategory of (restaurantData$ | async)?.products?.categories">

                        <!-- Search result too visible / invisible-->
                        <ng-container *ngIf="!productsSearchResults.get(productCategory.categoryId) || productsSearchResults.get(productCategory.categoryId)?.visible">
                            <!-- Category title -->
                            <div class="category-title" [id]="productCategory.categoryId" [ngClass]="{'selected': (currentTab$ | async) === productCategory.categoryId}">
                                {{ productCategory.categoryName }}

                                <ng-container *ngIf="!!currentProductsSearchInput && productsSearchResults.get(productCategory.categoryId)?.expandedBySearch && 
                                                (productsSearchResults.get(productCategory.categoryId)?.expandedBySeeMore === false)">
                                    <div class="search-see-more" (click)="onSearchSeeMoreCategoryTap(productCategory)">
                                        - <span class="search-see-more-link">{{ "restaurant_page_search_category_see_more" | translate }}</span>
                                    </div>
                                 </ng-container>
                            </div>
                            <!-- Description -->
                            <ng-container *ngIf="!!productCategory.categoryDescription">
                                <div class="category-description">
                                    {{ productCategory.categoryDescription }}
                                </div>
                            </ng-container>
                        </ng-container>

                        <div class="category-products-list" [ngClass]="{'no-margin-top' :  productsSearchResults.get(productCategory.categoryId)?.visible === false}">
                            <!-- Each product loop from the looped category -->
                            <ng-container *ngFor="let product of productCategory.products; index as i">

                                <!-- Search result filtering -->
                                <ng-container *ngIf="!productsSearchResults.get(product.id) || productsSearchResults.get(product.id)?.visible" >
                                    <div class="product-item" [ngClass]="{'third-element': (i+1) % 3 === 0, 'second-element': (i+1) % 2 === 0}" (mousedown)="onPressProduct(product, productCategory.categoryId)">

                                        <!-- Name + Description + Image -->
                                        <div class="name-image-container">
                                            <div class="product-left-side">
                                                <!-- Name -->
                                                <div class="product-name">
                                                    {{ ((useGermanDescriptions$ | async) && product.name || !product.nameEn) ? 
                                                        product.name : 
                                                        product.nameEn }}
                                                </div>
                                                <!-- Description -->
                                                <div class="product-description">
                                                    {{ ((useGermanDescriptions$ | async) && product.description || !product.descriptionEn) ? 
                                                        product.description : 
                                                        product.descriptionEn }}
                                                </div>
                                            </div>
            
                                            <div class="product-right-side">
                                                <!-- Image -->
                                                <ng-container *ngIf="!!product.imageUrl">
                                                    <img class="product-image-src" [src]="product.imageUrl">
                                                </ng-container>                                        
                                            </div>
                                        </div>

                                        <!-- Price + Add button -->
                                        <div class="price-add-container">
                                            <div class="product-price">
                                                <!-- Switch between the Pickup + Delivery price based on user's selection -->
                                                <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.DELIVERY">
                                                    {{ product.deliveryPriceCents | format_cents: true }}
                                                </ng-container>
                                                <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.PICKUP">
                                                    {{ product.priceCents| format_cents: true }}
                                                </ng-container>                                        
                                            </div>
                                            <div class="btn-add-to-cart" (mousedown)="onPressAddToCart($event, product, productCategory)">
                                                <div class="add-to-cart-text">{{ 'restaurant_page_add_product' | translate }}</div>
                                                <img class="add-icon-src" [src]="'apps/user-app/src/assets/add-plus-round-circle.svg'" />
                                            </div>
                                        </div>                                                                
                                    </div>
                                </ng-container>       
                                <!-- end search result filtering -->
                                
                            </ng-container>                        
                            <!-- end loop of each product -->
                        </div>                                            
                    </ng-container>    
                </div>                
            </div>
        </div>

        <div class="order-basket-lateral">
            <order-basket></order-basket>
        </div>
    </ng-container>
</div>