import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'vertical-status-progress',
  templateUrl: './vertical-status-progress.component.html',
  styleUrls: ['./vertical-status-progress.component.scss'],
})
export class VerticalStatusProgressComponent implements OnInit {

    @Input() public items: VerticalStatusItem[] | null = [];

    public VerticalStatusItemState: typeof VerticalStatusItemState = VerticalStatusItemState;

    constructor(
        private router: Router
    ) {
    }

    public ngOnInit() {
    }
}

export class VerticalStatusItem {
    translationKeyDefault: string = "";
    translationKeyCompleted: string = "";
    status: VerticalStatusItemState = VerticalStatusItemState.EMPTY;
    id: string = "";
    order: number = 0;
    required: boolean = false;
} 

export enum VerticalStatusItemState {
    EMPTY = "empty",
    IN_PROGRESS = "progress",
    COMPLETED = "completed"
}