import { AllergenEnum } from "./AllergenEnum";

export class AddonResponse {
    id: string = "";
    name: string = "";
    nameEn: string = "";
    priceCents: number = 0;
    allergenList: AllergenEnum[] = [];
    available: boolean = false;
}
