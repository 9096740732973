<div class="main-basket-container">

    <!-- When the cart order is opened from any other place - so it's not inside the restaurant page, but overall on top of the website -->
    <ng-container *ngIf="isOpenInPopup$ | async">
        <div class="popup-top-bar">
            <mat-icon class="popup-top-bar-close" (mousedown)="onPressClose()">close</mat-icon>
        </div>
    </ng-container>

    <!-- Title -->
    <div class="basket-title" [ngClass]="{'checkout': isCheckoutMode}">
        <!-- icon -->
        <ng-container *ngIf="!!(data$ | async)?.restaurantId">
            <img class="basket-img-src" [src]="'apps/user-app/src/assets/order-basket.svg'"/>
        </ng-container>
        
        <!-- title -->
        {{ "order_basket_title" | translate }}
    </div>

    <!-- Delivery type -->
    <ng-container *ngIf="!isCheckoutMode">
        <div class="delivery-type-container">
            <!-- Delivery -->
            <button-delivery-type 
                [icon]="'delivery-type-bicycle'" 
                [state]="(data$ | async)?.deliveryOption === DeliveryOption.DELIVERY ? 'selected' : ''" 
                [text]="'nav_bar_delivery_type_home' | translate" 
                (onPress)="onPressDelivery()"
                #tooltip="matTooltip"
                [matTooltip]="('order_basket_delivery_option_not_available' | translate) + ' ' + (data$ | async)?.restaurantName"
                [matTooltipPosition]="'below'"                       
                [matTooltipDisabled]="(data$ | async)?.supportsDelivery"              
                [matTooltipClass]="'custom-delivery-info-tooltip'" >
            </button-delivery-type>
            <!-- Pickup -->
            <button-delivery-type 
                [icon]="'delivery-type-pick-up-hand'" 
                [state]="(data$ | async)?.deliveryOption === DeliveryOption.PICKUP ? 'selected' : ''" 
                [text]="'nav_bar_delivery_type_pickup' | translate" 
                (onPress)="onPressPickup()" class="pl-3"
                #tooltip="matTooltip"
                [matTooltip]="('order_basket_delivery_option_not_available' | translate) + ' ' + (data$ | async)?.restaurantName"
                [matTooltipPosition]="'below'"                       
                [matTooltipDisabled]="(data$ | async)?.supportsPickup"              
                [matTooltipClass]="'custom-delivery-info-tooltip'" >
            </button-delivery-type>
        </div>
    </ng-container>

    <!-- Empty basket state -->
    <ng-container *ngIf="!(data$ | async)?.restaurantId">
        <div class="empty-state-container">
            <!-- icon -->
            <div class="empty-basket-icon">
                <img class="empty-basket-img-src" [src]="'apps/user-app/src/assets/order-basket-gray.svg'"/>
            </div>

            <!-- text -->
            <div class="empty-basket-text">
                {{ "order_basket_empty" | translate }}
            </div>
        </div>
    </ng-container>


    <!-- Has something added -->
    <ng-container *ngIf="!!(data$ | async)?.restaurantId">      
        
        <ng-container *ngIf="
            !!(currentRoute$ | async) && ((currentRoute$ | async) !== ('/restaurant/' + (data$ | async)?.restaurantSlug)) 
        ">
            <div class="restaurant-link-container" [ngClass]="{'checkout' : isCheckoutMode}">

                <!-- Link to restaurant -->
                <!-- <ng-container *ngIf="!isCheckoutMode; else restaurantNoLink"> -->
                    <a [href]="'/restaurant/' + (data$ | async)?.restaurantSlug" class="restaurant-name-link">
                        <span class="restaurant-name-text">{{"order_basket_restaurant" | translate}} - </span> {{ (data$ | async)?.restaurantName }}
                    </a>
                <!-- </ng-container> -->
                <!-- No Link, just the restaurant shown -->
                <!-- <ng-template #restaurantNoLink>
                    <div class="restaurant-name-no-link">
                        <span class="restaurant-name-text">{{"order_basket_restaurant" | translate}} - </span> {{ " " + (data$ | async)?.restaurantName }}
                    </div>
                </ng-template> -->
            </div>            
        </ng-container>

        <!-- Products list -->
        <div class="products-list" [ngClass]="{
            'pickup': (data$ | async)?.deliveryOption === DeliveryOption.PICKUP,
            'pickup-popup': (data$ | async)?.deliveryOption === DeliveryOption.PICKUP && (isOpenInPopup$ | async),
            'popup': (data$ | async)?.deliveryOption === DeliveryOption.DELIVERY && (isOpenInPopup$ | async),
            'pickup-other':  (data$ | async)?.deliveryOption === DeliveryOption.PICKUP && !!(currentRoute$ | async) && ((currentRoute$ | async) !== ('/restaurant/' + (data$ | async)?.restaurantSlug)),
            'pickup-checkout': isCheckoutMode,
            'other': !!(currentRoute$ | async) && ((currentRoute$ | async) !== ('/restaurant/' + (data$ | async)?.restaurantSlug))
        }">

            <!-- Product item -->
            <ng-container *ngFor="let product of (data$ | async)?.items; index as i">
                <div class="product-item" [ngClass]="{'checkout' : isCheckoutMode}">
                    <div class="product-row-1">
                        <ng-container *ngIf="isCheckoutMode; else regularProductName">
                            <div class="product-name"> {{ product.quantity + " x " + product.productName }}</div>
                        </ng-container>
                        <ng-template #regularProductName>
                            <div class="product-name">{{ product.productName }}</div>
                        </ng-template>
                        <div class="product-price">
                            {{ getProductFullPrice(product) | format_cents: true }}
                        </div>
                    </div>
                    <div class="product-row-2">                        
                        <div class="product-extras">
                            <!-- Addons list -->
                            <ng-container *ngFor="let addon of product.addons; index as i">
                                {{ addon.addonName }}<ng-container *ngIf="addon.addonPrice > 0">({{addon.addonPrice | format_cents: true}})</ng-container><ng-container *ngIf="i < product.addons.length - 1">, </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!!product.specialInstructions">
                                <div class="special-instructions-text">"{{product.specialInstructions}}"</div>
                            </ng-container>                            
                        </div>
                        <!-- Add / Remove buttons -->
                        <ng-container *ngIf="!isCheckoutMode">
                            <div class="product-buttons">
                                <div class="product-remove-btn" (mousedown)="removeProductQuantity(product)">
                                    <img class="product-quantity-btn" [src]="product.quantity === 1 ? 'apps/user-app/src/assets/trash-bin-green.svg' : 'apps/user-app/src/assets/minus-round-green.svg'"/>
                                </div>
                                <div class="product-quantity-text">
                                    {{ product.quantity }}
                                </div>
                                <div class="product-add-btn" (mousedown)="addProductQuantity(product)">
                                    <img class="product-quantity-btn" [src]="'apps/user-app/src/assets/add-plus-round-circle.svg'"/>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                </div>
            </ng-container>

        </div>

        <!-- Bottom action bar -->
        <div class="bottom-bar" [ngClass]="{'checkout' : isCheckoutMode}">
            
            <!-- Subtotal -->
            <div class="total-row-1">
                <div class="subtotal-text">{{ "order_basket_subtotal" | translate }}</div>
                <div class="subtotal-value">{{ ((data$ | async)?.initialPrice || 0) | format_cents: true }}</div>
            </div>

            <!-- Minimum restaurant order -->
            <ng-container *ngIf="((data$ | async)?.initialPrice || 0) < (((data$ | async)?.restaurantMinOrderAmount || 0) * 100); else totalNumber">
                <div class="total-row-1">
                    <div class="min-order-text">{{ "order_basket_minimum_restaurant" | translate }}</div>
                    <div class="min-order-value">{{ (((data$ | async)?.restaurantMinOrderAmount || 0) * 100) | format_cents: true }}</div>
                </div>
            </ng-container>
            
            <ng-template #totalNumber> 
                <!-- Delivery fee -->
                <div class="total-row-1" *ngIf="(data$ | async)?.deliveryOption === DeliveryOption.DELIVERY">
                    <div class="subtotal-text">{{ "order_basket_delivery_fee" | translate }}</div>
                    <div class="subtotal-value">{{ ((data$ | async)?.deliveryPrice || 0) | format_cents: true }}</div>
                </div>

                <!-- Discount for delivery -->
                <div class="total-row-1" *ngIf="(data$ | async)?.deliveryOption === DeliveryOption.DELIVERY && ((data$ | async)?.extraDeliveryDiscount || 0 > 0)">
                    <div class="discount-text">{{ "order_basket_delivery_discount" | translate }} <div
                        #tooltip="matTooltip"
                        [matTooltip]="'order_basket_info_discount' | translate"
                        [matTooltipPosition]="'left'"   
                        (mousedown)="tooltip.toggle()"                                  
                        [matTooltipClass]="'custom-delivery-info-tooltip'" >
                        <img class="info-gray-discount ml-1" [src]="'apps/user-app/src/assets/info-gray.svg'"/>
                    </div></div>
                    <div class="subtotal-value">− {{ ((data$ | async)?.extraDeliveryDiscount || 0) | format_cents: true }}</div>
                </div>

                <!-- Total -->
                <div class="total-row-2">
                    <div class="total-text">{{ "order_basket_total" | translate }} </div>
                    <div class="total-value">{{ ((data$ | async)?.totalPrice || 0) | format_cents: true }}</div>
                </div>
            </ng-template>
            

            <!-- Action button -->
            <ng-container *ngIf="!isCheckoutMode">
                <div class="bottom-action-right-add" (click)="onContinueToCheckout()">
                    <fs-button [text]="'order_basket_continue' | translate" [style]="'full-width-large-height-no-margin'"
                    [type]="'raised-primary'"
                    [disabled]="((data$ | async)?.initialPrice || 0) < (((data$ | async)?.restaurantMinOrderAmount || 0) * 100)"></fs-button>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>