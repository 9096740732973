import {Component, Input} from '@angular/core';
import {SideMenuItem} from "../user-side-menu/user-side-menu.component";

@Component({
  selector: 'user-item-side-menu',
  templateUrl: './user-item-side-menu.component.html',
  styleUrls: ['./user-item-side-menu.component.scss'],
})
export class UserItemSideMenuComponent {

    @Input() public item: SideMenuItem = new SideMenuItem();

}
