import {BehaviorSubject, find, map, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import { RestaurantSearchResponse } from "../model/restaurant-search-response";

@Injectable({
    providedIn: 'root',
})
export class RestaurantsListState {

    private state$: BehaviorSubject<RestaurantSearchResponse> = new BehaviorSubject<RestaurantSearchResponse>(new RestaurantSearchResponse());

    constructor(
    ) {
    }

    public getData$(): Observable<RestaurantSearchResponse> {
        return this.state$.asObservable();
    }

    public getData(): RestaurantSearchResponse {
        return this.state$.getValue();
    }

    public setData(value: RestaurantSearchResponse): void {
        this.state$.next(value);
    }
}
