<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
       <span class="modal-title-strong">{{ (tempData$ | async)?.restaurantInfo?.name }}</span> <span class="title-more-text">- {{ 'restaurant_page_more_info' | translate}}</span>
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'dashboard_modal_filter_close' | translate }}
        </div>
    </div>
</div>

<div class="modal-content" #modalContent>
    <!--
        Address
    -->
    <div class="address-row">
        <div class="address-left">
            <!-- Address -->
            <div class="subtitle-text mr-4">
                {{ 'restaurant_info_modal_address' | translate }}
            </div>
            <!-- Data -->
            <div class="full-address">
                {{ (tempData$ | async)?.restaurantInfo?.address?.streetName  }} {{ (tempData$ | async)?.restaurantInfo?.address?.streetNumber  }},
                {{ (tempData$ | async)?.restaurantInfo?.address?.district  }} {{ (tempData$ | async)?.restaurantInfo?.address?.postalCode  }}, {{ (tempData$ | async)?.restaurantInfo?.address?.city  }}
            </div>
        </div>
        <div class="address-right">
            <div class="copy-btn mr-5" (mousedown)="onCopyClick()">
                <img class="copy-src" [src]="'apps/user-app/src/assets/copy.svg'"/>
                {{ "restaurant_info_modal_address_copy" | translate }}
            </div>

            <div class="external-btn"  (mousedown)="onExternalMapsClick()">
                <img class="external-src" [src]="'apps/user-app/src/assets/external-link.svg'"/>
                {{ "restaurant_info_modal_address_maps" | translate }}
            </div>
        </div>
    </div>
    <!-- Map -->
    <div class="address-map">
        <fs-map-lite 
            [address]="(tempData$ | async)?.restaurantInfo?.address || null"
            [width]="(mapWidthResize$ | async) || 0"
            [height]="200">
        </fs-map-lite>
    </div>


    <!--
        Business hours
    -->

    <div class="business-hours">
        <!-- Title -->
        <div class="subtitle-text mb-2 mr-4">
            {{ 'restaurant_info_modal_hours' | translate }}
        </div>

        <!-- Dropdown accordion Business hours-->
        <mat-accordion>
            <mat-expansion-panel hideToggle [expanded]="hoursPanelOpenState" (opened)="hoursPanelOpenState = true" (closed)="hoursPanelOpenState = false">
                <mat-expansion-panel-header class="expansion-header">                    
                    <mat-panel-description>                        
                        <!-- Header info -->
                        <div class="r-info-hours-row header">
                            <div class="r-info-hours-col-1 header">{{ "restaurant_info_hours_day" | translate }}</div>
                            <div class="r-info-hours-col-2 row header">
                                <div class="r-info-hours-item-icon">
                                    <img [src]="'apps/user-app/src/assets/delivery-type-pick-up-hand-selected.svg'"/>
                                </div>
                                <div class="r-info-col-2-header-text">
                                    {{ "restaurant_info_hours_pickup" | translate }}
                                </div>
                            </div>
                            <div class="r-info-hours-col-3 row header">
                                <div class="r-info-hours-item-icon">
                                    <img [src]="'apps/user-app/src/assets/delivery-type-bicycle-selected.svg'"/>
                                </div>
                                <div class="r-info-col-3-header-text">
                                    {{ "restaurant_info_hours_delivery" | translate }}
                                </div>
                            </div>
                        </div>                    
                    </mat-panel-description>
                    <!-- right side -->
                    <div class="subtitle-text"><span class="show-all-btn-inside">{{ 'restaurant_info_modal_hours_all' | translate }}</span><mat-icon>{{ hoursPanelOpenState ? 'expand_less' : 'expand_more'}}</mat-icon></div>
                </mat-expansion-panel-header>

                
                <!-- Content -->
                <!-- All days and hours-->
                <div>
                    <ng-container *ngFor="let hours of (getHours()| async)">
                        <div class="r-info-hours-row">
                            <div class="r-info-hours-col-1"> {{ "restaurant_info_availability_" + hours.day | translate }}</div>
                            <div class="r-info-hours-col-2"> 
                                <ng-container *ngIf="(hours.pickupHours || []).length === 0">
                                    <ng-container *ngTemplateOutlet="closedHours"></ng-container>
                                </ng-container>
                                <ng-container *ngFor="let item of (hours.pickupHours || [])">
                                    <div>{{ item.openTime + " - " + item.closeTime }}</div>
                                </ng-container>
                            </div>
                            <div class="r-info-hours-col-3">
                                <ng-container *ngIf="(hours.deliveryHours || []).length === 0">
                                    <ng-container *ngTemplateOutlet="closedHours"></ng-container>
                                </ng-container>
                                <ng-container *ngFor="let item of (hours.deliveryHours || [])">
                                    <div>{{ item.openTime + " - " + item.closeTime }}</div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #closedHours>
                        <div class="flex items-center">
                            <img class="closedHoursImage" [src]="'apps/user-app/src/assets/status-unavailable-red.svg'"/> {{ "restaurant_info_hours_closed" | translate }}
                        </div>
                    </ng-template>


                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <!-- Current data -->
        <ng-container *ngIf="!hoursPanelOpenState">
            <div class="current-data-container">
                <div class="r-info-hours-row header">
                    <div class="r-info-hours-col-1 ">{{ "restaurant_info_hours_today" | translate }}</div>
                    <div class="r-info-hours-col-2">
                        <ng-container *ngIf="(getToday().pickupHours || []).length === 0">
                            <ng-container *ngTemplateOutlet="closedHours"></ng-container>
                        </ng-container>
                        <ng-container *ngFor="let item of (getToday().pickupHours || [])">
                            <div>{{ item.openTime + " - " + item.closeTime }}</div>
                        </ng-container>
                    </div>
                    <div class="r-info-hours-col-3">
                        <ng-container *ngIf="(getToday().deliveryHours || []).length === 0">
                            <ng-container *ngTemplateOutlet="closedHours"></ng-container>
                        </ng-container>
                        <ng-container *ngFor="let item of (getToday().deliveryHours || [])">
                            <div>{{ item.openTime + " - " + item.closeTime }}</div>
                        </ng-container>
                    </div>
                </div>          
            </div>

            <div class="mobile-show-all-btn-inside subtitle-text" (mousedown)="hoursPanelOpenState = true">{{ 'restaurant_info_modal_hours_all' | translate }}</div>
            <hr class="mt-5">
        </ng-container>
    </div>


    <!--
        Other details
    -->
    <div class="other-details-container">

        <!-- Left side -->
        <div class="other-details-left">
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_company" | translate }}</div>
                <div>
                    <ng-container *ngIf="!!(tempData$ | async)?.restaurantInfo?.companyName; else dataNotProvided ">
                        {{(tempData$ | async)?.restaurantInfo?.companyName}}
                    </ng-container>
                </div>
            </div>
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_phone" | translate }}</div>
                <div>
                    <ng-container *ngIf="!!(tempData$ | async)?.restaurantInfo?.phoneNumber; else dataNotProvided">
                        {{(tempData$ | async)?.restaurantInfo?.phoneNumber}}
                    </ng-container>
                </div>
            </div>
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_email" | translate }}</div>
                <div>
                    <ng-container *ngIf="!!(tempData$ | async)?.restaurantInfo?.email; else dataNotProvided">
                        {{(tempData$ | async)?.restaurantInfo?.email}}
                    </ng-container>
                </div>
            </div>
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_website" | translate }}</div>
                <div>
                    <ng-container *ngIf="!!(tempData$ | async)?.restaurantInfo?.website; else dataNotProvided ">
                        {{(tempData$ | async)?.restaurantInfo?.website}}
                    </ng-container>
                </div>
            </div>
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_vat" | translate }}</div>
                <div>
                    <ng-container *ngIf="!!(tempData$ | async)?.restaurantInfo?.vatNumber; else dataNotProvided ">
                        {{(tempData$ | async)?.restaurantInfo?.vatNumber}}
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- Right side -->
        <div class="other-details-right">
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_pickup" | translate }}</div>
                <div>
                    <ng-container *ngIf="(tempData$ | async)?.restaurantInfo?.hasPickup; else hasNoSupport ">
                        <img class="delivery-ok-sign" [src]="'apps/user-app/src/assets/check-ok.svg'"/>
                    </ng-container>
                </div>
            </div>
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_own" | translate }}</div>
                <div>
                    <ng-container *ngIf="(tempData$ | async)?.restaurantInfo?.hasOwnDelivery; else hasNoSupport ">
                        <img class="delivery-ok-sign" [src]="'apps/user-app/src/assets/check-ok.svg'"/>
                    </ng-container>
                </div>
            </div>
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_foodis" | translate }}</div>
                <div>
                    <ng-container *ngIf="(tempData$ | async)?.restaurantInfo?.hasFoodisDelivery; else hasNoSupport ">
                        <img class="delivery-ok-sign" [src]="'apps/user-app/src/assets/check-ok.svg'"/>
                    </ng-container>
                </div>
            </div>
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_time" | translate }}</div>
                <div>
                    <ng-container *ngIf="!!(tempData$ | async)?.restaurantInfo?.minOrderAmount; else dataNotProvided">
                        {{ ("restaurant_delivery_time_" + ((tempData$ | async)?.restaurantInfo?.deliveryTimeRange || '').toLowerCase()) | translate  }}
                    </ng-container>
                </div>
            </div>
            <div class="other-details-item">
                <div class="subtitle-text pr-2">{{ "restaurant_info_modal_min" | translate }}</div>
                <div>
                    <ng-container *ngIf="!!(tempData$ | async)?.restaurantInfo?.minOrderAmount; else dataNotProvided">
                        {{ (((tempData$ | async)?.restaurantInfo?.minOrderAmount || 0) * 100) | format_cents: true }}
                    </ng-container>
                </div>
            </div>
            
        </div>
            
    </div>

    <ng-template #dataNotProvided>
        <span class="not-provided-text">{{ "restaurant_info_modal_not_available" | translate }}</span>
    </ng-template>
    <ng-template #hasNoSupport>
        <img [src]="'apps/user-app/src/assets/minus-not-available.svg'"/>
    </ng-template>

    <!--
        Description
    -->
    <div class="description-container">
        <div class="subtitle-text pr-2">{{ "restaurant_info_modal_description" | translate }}</div>
        <div>
            <ng-container *ngIf="!!(tempData$ | async)?.restaurantInfo?.description; else dataNotProvided ">
                {{(tempData$ | async)?.restaurantInfo?.description}}
            </ng-container>
        </div>
    </div>
</div>
