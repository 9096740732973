<!-- Order cart bottom overlay button (smaller width view) -->
<ng-container *ngIf="((basketItemsCount$ | async) || 0 > 0) &&
                        ((isOrderBasketOpenInPopup$ | async) === false) &&
                        !hideByRoute
">
    <div class="order-basket-bottom-action">
        <div class="bottom-action-right-add">
            <fs-button [text]="('order_basket_title' | translate) + ' (' + (((orderBasketData$ | async)?.totalPrice || 0) | format_cents: true) + ')' " [style]="'full-width-large-height-no-margin'"
                (onPress)="onOpenBasketPress()"
                [type]="'raised-primary'">
            </fs-button>
        </div>
    </div>
</ng-container>