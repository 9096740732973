export const environment = {
    BUILD_PRODUCT: "user-app",
    API_URL_BASE: "https://api.staging.foodis.day/",
    PRODUCT_URLS: {
        LANDING_PAGE: "https://staging.foodis.day",
        USER_APP: "https://app.staging.foodis.day",
        RESTAURANT: "https://restaurant.staging.foodis.day",
        DRIVER: "https://driver.staging.foodis.day",
        ADMIN: "https://admin.staging.foodis.day",
        ACCOUNT: "https://account.staging.foodis.day",
        ACCOUNT_REGISTRATION: "https://account.staging.foodis.day/register",
        ACCOUNT_RESET_PASSWORD: "https://account.staging.foodis.day/forgot-password"
    },
    STRIPE_PUBLISHABLE_KEY: "pk_test_51PdTiERrbKJHrpRQ8aEqhK0vQJT9Z7a8cbzzQ6g7fapFnMnwJdzz5HuCwYPFanl4AqjN2I44GxBXJGAByLPxcTgv00Jrbc9E26",
    API_USER_SELF: "v1/users/self",
    API_USER_ADDRESSES: "v1/users/self/addresses",
    API_USER_SET_DEFAULT_ADDRESS: "v1/users/self/addresses/{{address_id}}/default",
    API_USER_UPDATE_DELETE_ADDRESS: "v1/users/self/addresses/{{address_id}}",
    API_GET_RESTAURANTS_LIST: "v1/restaurants/search",
    API_GET_FULL_RESTAURANT: "v1/public/restaurants/{{restaurantId}}",
    API_GET_FULL_RESTAURANT_BY_SLUG: "v1/public/restaurants/slug/{{slug}}",
    API_ORDERS: "v1/users/orders",
    API_INDIVIDUAL_ORDER: "v1/users/orders/{{order_id}}",
    API_STRIPE_PAYMENT_INTENT: "v1/users/orders/{{order_id}}/payment-intent",
    DEBUG_STAGING_BADGE: true
};

