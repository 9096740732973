import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrderBasketService } from '../../services/order-basket/order-basket.service';
import { BehaviorSubject, Observable, debounce, interval } from 'rxjs';
import { OrderCart, OrderCartProductItem } from '../../services/order-basket/model/order-basket.model';
import { TopNavBarService } from '../../services/navbar/top-navbar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { UserAddressFacade } from '../../services/user-address/user-address.facade.service';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { ModalService } from 'libs/shared-ui/src/lib/modal-service/modal-service.service';
import { LocaleTranslatePipe } from 'libs/shared-services/src/lib/locale-pipe';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { DeliveryOption } from 'libs/shared-models/src/lib/delivery-option';

@UntilDestroy()
@Component({
  selector: 'order-basket',
  templateUrl: './order-basket.component.html',
  styleUrl: './order-basket.component.scss',
})
export class OrderBasketComponent implements OnInit, OnDestroy {

  @Input() public isCheckoutMode: boolean = false;
  @Input() public isOrderPageMode: boolean = false;
  @Input() public set orderPageData(pageData: OrderCart | null) {
    if (this.isOrderPageMode && pageData) {
      this.data$ = new BehaviorSubject<OrderCart>(pageData);
    }
  }; 

  public data$: Observable<OrderCart> =  this.orderBasketService.getState$();

  public DeliveryOption: typeof DeliveryOption = DeliveryOption;

  public currentRoute$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public isOpenInPopup$: Observable<boolean> = this.topNavBarService.isOrderBasketOpenInPopup$.asObservable();
  

  constructor(
    private orderBasketService: OrderBasketService,
    private topNavBarService: TopNavBarService,
    private userAddressFacade: UserAddressFacade,
    private modalService: ModalService,
    private localeService: LocaleService,
    private router: Router
  ) {
    
  }

  public ngOnInit(): void {    
    if (this.isOrderPageMode) {
      this.currentRoute$.next(this.router.url); // just this one is needed
      return;
      // don't continue the subscription modes - those are not needed and they would create issues for just displaying the order page
    } else {
        // These are needed for Order Basket + Checkout mode
        this.checkRoute();
        this.subscribeTopNavBar();
        this.subscribeDataChange();   
        this.subscribeAddressChange(); 
    }
  }

  /*
    Route check
  */
  private checkRoute() {  
    this.currentRoute$.next(this.router.url);

    // Check if it's loaded outside the resaurant page - and if it was almost empty (so no products, no id) => make sure all the other fields are reset to original value
    if (!this.router.url.includes("/restaurant/") && !this.orderBasketService.getState().restaurantId && this.orderBasketService.getState().items.length === 0) {
      this.orderBasketService.emptyCart();
    }
  }
  
  /*
    Subscribe to Top Nav Bar delivery option change
  */
  private subscribeTopNavBar() {    
    this.topNavBarService.getDeliveryOption$().pipe(untilDestroyed(this)).subscribe((option: DeliveryOption) => {

      // Check if the selected delivery option is also supported by the restaurant. If not, return / don't change it
      if (option === DeliveryOption.DELIVERY && !this.orderBasketService.getState().supportsDelivery) {        
        if (this.orderBasketService.getState().supportsPickup) {
          this.orderBasketService.setDeliveryOption(DeliveryOption.PICKUP); // force to pickup

          if ((!this.orderBasketService.getState().restaurantId && this.currentRoute$.getValue().includes("/restaurant/"))
              || (this.currentRoute$.getValue() === ("/restaurant/" + this.orderBasketService.getState().restaurantSlug))) {
            this.topNavBarService.setDeliveryOption(DeliveryOption.PICKUP); // force the entire state to pickup
          }
        } else {
          console.warn("[Issue with delivery option] A1 - Has no delivery or pickup");
        }
        return;
      }

      if (option === DeliveryOption.PICKUP && !this.orderBasketService.getState().supportsPickup) {        
        if (this.orderBasketService.getState().supportsDelivery) {
          this.orderBasketService.setDeliveryOption(DeliveryOption.DELIVERY); // force to delivery

          if ((!this.orderBasketService.getState().restaurantId && this.currentRoute$.getValue().includes("/restaurant/"))
            || (this.currentRoute$.getValue() === ("/restaurant/" + this.orderBasketService.getState().restaurantSlug))) {
            this.topNavBarService.setDeliveryOption(DeliveryOption.DELIVERY); // force the entire state to delivery
          }
        } else {
          console.warn("[Issue with delivery option] A2 - Has no delivery or pickup");
        }
        return;
      }

      this.orderBasketService.setDeliveryOption(option);
    })
  }

  /*
    Subscribe to data change
  */
  private subscribeDataChange() {    
    this.data$.pipe(untilDestroyed(this)).subscribe((data) => {
        
      // Check if the selected delivery option is also supported by the restaurant. If not, return / don't change it
      if (data.deliveryOption === DeliveryOption.DELIVERY && !data.supportsDelivery) {        
        if (data.supportsPickup) {
          this.orderBasketService.setDeliveryOption(DeliveryOption.PICKUP); // force to pickup

          if ((!data.restaurantId && this.currentRoute$.getValue().includes("/restaurant/"))
              || (this.currentRoute$.getValue() === ("/restaurant/" + data.restaurantSlug))) {
            this.topNavBarService.setDeliveryOption(DeliveryOption.PICKUP); // force the entire state to pickup
          }
        } else {
          console.warn("[Issue with delivery option] B1 - Has no delivery or pickup");
        }
      }
      
      // Check if the selected delivery option is also supported by the restaurant. If not, return / don't change it
      if (data.deliveryOption === DeliveryOption.PICKUP && !data.supportsPickup) {        
        if (data.supportsDelivery) {
          this.orderBasketService.setDeliveryOption(DeliveryOption.DELIVERY); // force to delivery

          if ((!data.restaurantId && this.currentRoute$.getValue().includes("/restaurant/"))
            || (this.currentRoute$.getValue() === ("/restaurant/" + data.restaurantSlug))) {
            this.topNavBarService.setDeliveryOption(DeliveryOption.DELIVERY); // force the entire state to delivery
          }
        } else {
          console.warn("[Issue with delivery option] B2 - Has no delivery or pickup");
        }
      }
    })
  }

  /*
    Listen to User Address changes
  */
  private subscribeAddressChange() {
    this.userAddressFacade.getCurrentAddress$()
      .pipe(untilDestroyed(this))
      .pipe(debounce(() => interval(100)))
      .subscribe((a: AddressResponse) => {

      if (a.isEmpty()) {
        // empty cart
        this.orderBasketService.emptyCart();

        // show warning popup
        this.openWarningForceEmptyCart();

        // redirect
        if (this.currentRoute$.getValue().includes('/restaurant/')) {
          this.router.navigateByUrl("dashboard");
        }
      }
    });
  }

  private openWarningForceEmptyCart() {
		const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("cart_missing_address_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("cart_missing_address_body"),
            rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("cart_missing_address_ok"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("cart_missing_address_address"),
            preselectedButton: "left",
        }

        // open popup
        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // true                
              this.topNavBarService.setForceAddressOpen(true);
            }
        });
	}

  public ngOnDestroy(): void {
      if (!this.orderBasketService.getState().restaurantId) {
        this.orderBasketService.emptyCart();
      }
  }

  /*
        Delivery related
  */

  public onPressDelivery() {
      // TODO: also to update the current state in orderBasketService

      if (!this.orderBasketService.getState().supportsDelivery) {
        return;
      }
      this.topNavBarService.setDeliveryOption(DeliveryOption.DELIVERY);
  }

  public onPressPickup() {
      // TODO: also to update the current state in orderBasketService

      if (!this.orderBasketService.getState().supportsPickup) {
        return;
      }
      this.topNavBarService.setDeliveryOption(DeliveryOption.PICKUP);
  }


  // Add again / remove product
  public addProductQuantity(product: OrderCartProductItem) {
    this.orderBasketService.increaseQuantity(product);
  }

  public removeProductQuantity(product: OrderCartProductItem) {
    this.orderBasketService.decreaseQuantity(product);
  }

  public getProductFullPrice(product: OrderCartProductItem) {
    const deliveryOption = this.orderBasketService.getState().deliveryOption;
    let productFullSum = 0;

    // product price
    productFullSum += deliveryOption === DeliveryOption.DELIVERY ? product.productPriceDelivery : product.productPrice;

    // add-ons price
    product.addons.forEach((a) => {
        productFullSum += a.addonPrice;
    })

    // multiply by quantity
    productFullSum = productFullSum * product.quantity;

    return productFullSum;
  }

  public onContinueToCheckout() {
    this.topNavBarService.closeOrderBasketInPopup();
    this.orderBasketService.handleGoToCheckout();
  }

  public onPressClose() {
    this.topNavBarService.closeOrderBasketInPopup();
  }
  
}
