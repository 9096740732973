import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable,} from "rxjs";

/*
    This service is used to blur all the content which is not part of the Top Navigation bar
    Eg:  When the Address editor is opened, we need to blur all the other content
 */

@Injectable({
    providedIn: 'root'
})
export class BlurBackgroundService {

    private isBlurBackground: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        @Inject('env') private environment: any,
    ) {
    }

    public setBlurBackground(value: boolean) {
        this.isBlurBackground.next(value);
    }

    public getBlurBackgroundState$(): Observable<boolean> {
        return this.isBlurBackground.asObservable();
    }
}

