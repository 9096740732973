export enum RestaurantCategoryEnum {
    ASIAN = "ASIAN",
    BALKAN = "BALKAN",
    BURGER = "BURGER",
    CHINESE = "CHINESE",
    HALAL = "HALAL",
    INDIAN = "INDIAN",
    ITALIAN_PIZZA = "ITALIAN_PIZZA",
    ORIENTAL = "ORIENTAL",
    SUSHI = "SUSHI"
}
