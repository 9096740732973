<div class="main-container">
  
    <div class="content-center">

        <!-- Title -->
        <div class="title-total">
            <div class="payment-title">
                {{ "stripe_payment_title" | translate }}
            </div>
            <ng-container *ngIf="(stripeLoading$ | async) === false">
                <div class="payment-total">
                    {{ "stripe_payment_total" | translate }}: <span class="amount-total">{{ ((paymentIntent$ | async)?.amount || 0) | format_cents: true  }}</span>
                </div>
            </ng-container>
            
        </div>

         <!-- Loading spinner -->
         <ng-container *ngIf="(stripeLoading$ | async)">
            <div class="loading-spinner-container">
                <fs-loading-spinner></fs-loading-spinner>
            </div>
         </ng-container>


        <!-- Here the Stripe UI is loaded in an iframe by Stripe -->
        <div id="payment-element">
             
        </div>


        <!-- Safely pay / Buttons -->
        <ng-container *ngIf="(stripeLoading$ | async) === false">
            <div class="bottom-buttons">
                <fs-button class="simulate-button" [text]="'Valid testing cards' | translate" [type]="'raised'" (onPress)="onAutoCompleteClick()" [style]="'full-width-large-height'"></fs-button>
                <fs-button class="simulate-button" [text]="'Pay now' | translate" [type]="'raised-primary'" (onPress)="onConfirmClick()" [style]="'full-width-large-height'"></fs-button>
            </div>
        </ng-container>
    </div>

    <!-- Safely pay -->
    <div class="online-pay-safe">
        <mat-icon class="lock-icon">lock</mat-icon>
        <span class="mr-1">{{ 'checkout_payment_online_safe' | translate }}</span>
        <div class="payment-stripe-logo">
            <a href="https://stripe.com/" target="_blank"><img [src]="'apps/user-app/src/assets/checkout-stripe.svg'"/></a>
        </div>
    </div>
    
</div>

