import { BehaviorSubject, Observable} from "rxjs";
import { Injectable} from "@angular/core";
import { OrderResponse } from "../../../models/order-response";

@Injectable({
    providedIn: 'root',
})
export class OrdersStateService {

    private state$: BehaviorSubject<OrderResponse> = new BehaviorSubject<OrderResponse>(new OrderResponse());

    constructor(
    ) {
    }

    public getData$(): Observable<OrderResponse> {
        return this.state$.asObservable();
    }

    public getData(): OrderResponse {
        return this.state$.getValue();
    }

    public setData(value: OrderResponse): void {
        this.state$.next(value);
    }
}
