<div class="main-container">

    <div class="mb-5">
        {{ 'nav_bar_menu_profile' | translate }}
    </div>

    <div class="data-container">
        <div class="mt-2">Name:  <span class="field-value">{{ (selfData$ | async)?.name }} </span> </div>
        <div class="mt-3"> Email: <span class="field-value">{{ (selfData$ | async)?.email }} </span></div>    


        <div class="mt-2"> Language: <nav-language-selector></nav-language-selector></div>

        <div class="mt-2"> Security:         
            <fs-link class="ml-2" [text]="'Password reset'"  [buttonBehavior]="true" (press)="onPasswordResetPress()">
            </fs-link>  
        </div>

        <fs-button [text]="'nav_bar_menu_logout' | translate"
            [type]="'raised'" [leadingIcon]="'exit_to_app'"
            (onPress)="onLogoutClick()"
            class="logout-btn">
         </fs-button>
    </div>
</div>