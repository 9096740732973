import { Injectable} from '@angular/core';
import { BehaviorSubject, Observable, map} from "rxjs";
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';

import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';
import { RestaurantCategoryEnum } from 'libs/shared-models/src/lib/restaurant/restaurant-category-enum';
import { CookieService } from 'libs/shared-services/src/lib/cookie.service';
import { CustomLocalFilters } from '../../pages/dashboard/modals/filters-modal/model/custom-local-filters';

@Injectable({
    providedIn: 'root',
})
export class DashboardService extends BaseLoadingService {

    // categories & filter (selected property)
    private cuisineCategories$: BehaviorSubject<CuisineCategory[]> = new BehaviorSubject<CuisineCategory[]>([]);

    // open now filter
    public readonly DEFAULT_SHOW_OPEN: boolean = true;
    private onlyOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.DEFAULT_SHOW_OPEN);

    constructor(
        private cookieService: CookieService
    ) {
        super();   
    }

    public initService() {
        this.initList();
    }

    private initList() {
        // default list
        let list = Object.keys(RestaurantCategoryEnum).map((item)=> {
            return {
                id: item,
                lokaKey: "restaurant_info_category_" + item.toLocaleLowerCase(),
                imagePath: 'apps/user-app/src/assets/cuisine_' + item.toLocaleLowerCase() +'.jpg',
                urlPath: '/category/' + item.toLocaleLowerCase(),
                selected: false,
                hasOpacity: false
            } as CuisineCategory
        })

        // check existing cookie values for Filter: cuisine categories 
        const cookieCategories = this.cookieService.getCookie("filter_selected_cuisine");        
        if (cookieCategories) {
            const selectedCuisines = JSON.parse(cookieCategories);
            if (selectedCuisines && selectedCuisines.length > 0) {
                list = list.map((c) => {
                    if (selectedCuisines.find((item: string) => item === c.id)) {
                        c.selected = true;
                    }
                    c.hasOpacity = !c.selected;
                    return c;
                })
            }
        }

        // check existing cookie for Filter: open only 
        const cookieOpenNow = this.cookieService.getCookie("filter_open_now");   
        if (cookieOpenNow === "true") {
            this.onlyOpen$.next(true);    
        } else {
            if (cookieOpenNow === "false") {
                this.onlyOpen$.next(false);
            } else {
                // Not set yet on cookie. Add the default value
                this.cookieService.setCookie('filter_open_now', JSON.stringify(this.getOpenNowFilter()));
            }
        }        

        // init setters
        this.setList(list);
        this.setFinished();
    }

    /*
        Categories (cuisines)
    */

    private getList(): CuisineCategory[] {
        return this.cuisineCategories$.getValue();
    }

    private setList(list: CuisineCategory[]) {
        this.cuisineCategories$.next(list);

        // save the selected ones on local cookie
        const selectedCuisines = list.filter((c) => c.selected).map((c) => c.id);
        this.cookieService.setCookie('filter_selected_cuisine', JSON.stringify(selectedCuisines));
    }

    public getAllCuisinesList$(): Observable<CuisineCategory[]> {
        return this.cuisineCategories$.asObservable();
    }    

    public getAllCuisinesList(): CuisineCategory[] {
        return this.cuisineCategories$.getValue();
    }  

    public cuisineCategoryClick(category: CuisineCategory) {        
        let list = this.getList();
        list = list.map((c) => { 
            // make all the others with opacity
            if (!c.selected) {
                c.hasOpacity = true;
            }

            // select the current one
            if (c.id === category.id) {
                c.selected = !c.selected;                
                c.hasOpacity = !c.selected;
            }            
            return c;
        });

        const atLeastOneSelected = list.some((item) => item.selected);
        if (!atLeastOneSelected) {
            list = list.map((c) => { 
                c.hasOpacity = false;
                return c;
            });
        }

        this.setList(list);
    }

    public clearCuisineCategoryFilter() {
        let list = this.getList();
        list = list.map((c) => { 
            c.hasOpacity = false;
            c.selected = false;
            return c;
        });
        this.setList(list);
    }

    /*
        Open now filter
    */
    public getOpenNowFilter$(): Observable<boolean> {
        return this.onlyOpen$.asObservable();
    }

    public getOpenNowFilter(): boolean {
        return this.onlyOpen$.getValue();
    }

    public setOpenNowFilter(value: boolean) {
        this.onlyOpen$.next(value);
        this.cookieService.setCookie('filter_open_now', JSON.stringify(value));
    }

    /*
        Changes from filters modal
    */
    public onModalFiltersUpdate(data: CustomLocalFilters) {
        // update the list
        let list = this.getList();
        list = list.map((item) => {
            item.selected = data.cuisineCategories.find((d) => d.id === item.id)?.selected;
            item.hasOpacity = !item.selected;
            return item;
        })
        const atLeastOneSelected = list.some((item) => item.selected);
        if (!atLeastOneSelected) {
            list = list.map((c) => { 
                c.hasOpacity = false;
                return c;
            });
        }
        this.setList(list);

        // update the open status
        this.setOpenNowFilter(data.onlyOpen);
    }
}

export interface CuisineCategory {
    id: string;
    lokaKey: string;
    imagePath: string;
    urlPath: string;
    selected?: boolean,
    hasOpacity?: boolean;
}
