import { Component, Input, OnInit } from '@angular/core';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserAddressFacade } from '../../services/user-address/user-address.facade.service';
import { TopNavBarService } from '../../services/navbar/top-navbar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss'],
})
export class DeliveryAddressComponent implements OnInit {

  public userAddress$: BehaviorSubject<AddressResponse | null> = new BehaviorSubject<AddressResponse | null>(null);


  // TODO => USE IN TEMPLATE THE NEW base fs-delivery-address from shared libs UI


  @Input() usage: string = "default"; // ["default", "user-address-page", "order-page"]
  @Input() set inputAddress(address: AddressResponse | undefined | null) {
    if (address) {
      this.userAddress$.next(address);
    }
  }

  constructor(
    private userAddressFacade: UserAddressFacade,
    private topNavBarService: TopNavBarService
  ) {
  }

  public ngOnInit(): void {
    if (this.usage !== 'order-page') {
      this.userAddressFacade.getCurrentAddress$().pipe(untilDestroyed(this)).subscribe((a) => {
        this.userAddress$.next(a);
      })
    }
  }

  // Open directly the edit step of the address (expanded version)
  public onClickAddressEdit() {
    this.topNavBarService.setForceAddressOpen(true);
    setTimeout(() => {
      this.userAddressFacade.forcelyOpenExtended();
    }, 0);
  }

  // Open the regular search
  public onClickAddressSearch() {
    this.topNavBarService.setForceAddressOpen(true);
  }

}
