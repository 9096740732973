import {
    AfterViewInit,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { UserAddressFacade } from "../../services/user-address/user-address.facade.service";
import { ToasterService } from "libs/shared-services/src/lib/toaster.service";
import { Language, LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { AddressTranslations, BaseAddressEditorComponent } from 'libs/shared-ui/src/lib/fs-base-address-editor/fs-base-address-editor.component';
import { BlurBackgroundService } from '../../services/background-handler/blur-background.service';

@Component({
  selector: 'address-editor',
  templateUrl: './address-editor.component.html',
  styleUrls: ['./address-editor.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AddressEditorComponent implements OnInit, AfterViewInit {

    @ViewChild(BaseAddressEditorComponent) baseAddressComponent!: BaseAddressEditorComponent;

    private defaultTranslations: AddressTranslations = {
        address_image_title_back_search      : "",
        search_address_placeholder           : "",
        address_image_title_clear_input      : "",
        address_image_title_browser_location : "",
        address_stored_title                 : "",
        address_image_title_default          : "",
        address_image_title_edit             : "",
        address_image_title_delete           : "",
        address_stored_see_all               : "",
        address_extended_more                : "",
        address_extended_delete              : "",
        address_extended_button_confirm      : "",
        address_extended_button_save_changes : "",
        address_extended_instructions        : "",
        address_extended_postal_code         : "",
        address_extended_door                : "",
        address_extended_floor               : "",
        address_extended_entrance            : "",
        address_extended_street_number       : "",
        address_extended_street              : ""
    }

    public addressTranslationsList$: BehaviorSubject<AddressTranslations> = new BehaviorSubject<AddressTranslations>(this.defaultTranslations);

    public constructor(
        private userAddressFacade: UserAddressFacade,
        private blurBackgroundService: BlurBackgroundService,
        private toasterService: ToasterService,
        private localeService: LocaleService
    ) {
    }

    public ngOnInit() {
        this.localeService.getLanguage$().subscribe((lang: Language) => {
            // wait for languages to load / react to languages load
            this.setAddressTranslations();
        })
    }

    public ngAfterViewInit(): void {
        this.userAddressFacade.setAddressBaseComponent(this.baseAddressComponent);
    }
    
    public getAddresses$(): Observable<AddressResponse[]> {
        return this.userAddressFacade.getAddresses$();
    }

    private setAddressTranslations() {
        const t: AddressTranslations = {
            address_image_title_back_search      : this.localeService.translate("address_image_title_back_search"),
            search_address_placeholder           : this.localeService.translate("search_address_placeholder"),
            address_image_title_clear_input      : this.localeService.translate("address_image_title_clear_input"),
            address_image_title_browser_location : this.localeService.translate("address_image_title_browser_location"),
            address_stored_title                 : this.localeService.translate("address_stored_title"),
            address_image_title_default          : this.localeService.translate("address_image_title_default"),
            address_image_title_edit             : this.localeService.translate("address_image_title_edit"),
            address_image_title_delete           : this.localeService.translate("address_image_title_delete"),
            address_stored_see_all               : this.localeService.translate("address_stored_see_all"),
            address_extended_more                : this.localeService.translate("address_extended_more"),
            address_extended_delete              : this.localeService.translate("address_extended_delete"),
            address_extended_button_confirm      : this.localeService.translate("address_extended_button_confirm"),
            address_extended_button_save_changes : this.localeService.translate("address_extended_button_save_changes"),
            address_extended_instructions        : this.localeService.translate("address_extended_instructions"), 
            address_extended_postal_code         : this.localeService.translate("address_extended_postal_code"),
            address_extended_door                : this.localeService.translate("address_extended_door"),
            address_extended_floor               : this.localeService.translate("address_extended_floor"),
            address_extended_entrance            : this.localeService.translate("address_extended_entrance"),
            address_extended_street_number       : this.localeService.translate("address_extended_street_number"),
            address_extended_street              : this.localeService.translate("address_extended_street"),
        }
        this.addressTranslationsList$.next(t);
    }
    
    /*
       Confirm button
    */
    public confirmAddressClick(address: AddressResponse) {
        this.userAddressFacade.upsertNewAddress(address);
    }

    /*
        Select Default from list
    */
    public onExistingAddressSelect(a: AddressResponse) {
        this.userAddressFacade.setDefaultAddress(a);
    }

    /*
        Delete
    */
    public onDeleteAddress(a: AddressResponse) {
        this.userAddressFacade.deleteAddress(a);
    }

    /*
        Browser location not allowed by the user
    */
    public onBrowserBlockedError() {        
        this.toasterService.showWarning(this.localeService.translate("user_address_location_failed_title"), this.localeService.translate("user_address_location_failed_body"));
    }

    /*
        Browser location not supported by the browser
    */
    public onBrowserNotSupportedError() {        
        this.toasterService.showWarning(this.localeService.translate("user_address_location_geo_not_supported_title"), this.localeService.translate("user_address_location_geo_not_supported_body"));
    }
    /*
        Background is clicked, so we need to cancel the flow
    */
    public onBackgroundPress() {
        this.blurBackgroundService.setBlurBackground(false);
        this.userAddressFacade.setAddressOpen(false);
    }
}