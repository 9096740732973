import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { Language, LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { CustomProductModalModel, SelectedAddon } from './custom-product-modal.model';
import { AddonGroupResponse } from 'libs/shared-models/src/lib/restaurant/addon-group-response';
import { OrderBasketService } from 'apps/user-app/src/app/services/order-basket/order-basket.service';
import { OrderCartAddonItem, OrderCartProductItem } from 'apps/user-app/src/app/services/order-basket/model/order-basket.model';
import { AddonResponse } from 'libs/shared-models/src/lib/restaurant/addon-response';
import { FullRestaurantPageService } from 'apps/user-app/src/app/services/full-restaurant-page/full-restaurant-page.service';
import { LocaleTranslatePipe } from 'libs/shared-services/src/lib/locale-pipe';
import { AllergenEnum } from 'libs/shared-models/src/lib/restaurant/AllergenEnum';
import { DeliveryOption } from 'libs/shared-models/src/lib/delivery-option';

@Component({
  selector: 'web-product-details-modal',
  templateUrl: './product-details-modal.component.html',
  styleUrl: './product-details-modal.component.scss'
})
export class ProductDetailsModalComponent implements OnInit, AfterViewInit {

  @ViewChild('modalContent', { static: false }) modalContent: ElementRef | undefined;

  // Data
  public tempData$: BehaviorSubject<CustomProductModalModel> = new BehaviorSubject<CustomProductModalModel>(new CustomProductModalModel());

  // Restaurant can add title / descriptions in either English or German. Check if language is german (main) and has value - otherwise, switch to default English
  // Eg: in ProductSlim model we have the fields name & nameEn. 
  public useGermanDescriptions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public DeliveryOption: typeof DeliveryOption = DeliveryOption;
  public MAX_ADDONS_SHOWN_DEFAULT = 5;
  
  public showAllAddonsMap = new Map();

  public showMaxWiggleAnimation$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public showMinWiggleAnimation$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public showRequiredWiggleAnimation$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public showScaleUpQuantityAnimation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
  public showScaleDownQuantityAnimation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CustomProductModalModel,
    private dialogRef: MatDialogRef<ProductDetailsModalComponent>,
    private toasterService: ToasterService,
    private localeService: LocaleService,
    private orderBasketService: OrderBasketService,
    private fullRestaurantService: FullRestaurantPageService
  ) {

  }

  public ngOnInit() {

    // Listen to language changes
    this.localeService.getLanguage$().subscribe((lang: Language) => {      
      this.useGermanDescriptions$.next(lang.iso2 === "de");
    })

    // Assign the data received on Modal creation
    const newData = Object.assign(new CustomProductModalModel(), this.getDialogData()); 
    newData.addonGroups = this.getDialogData().addonGroups.map((item) => {
      return {...item}
    })
    this.tempData$.next(Object.assign(new CustomProductModalModel(), newData));

    // Listen to UI modal backdrop:
    this.dialogRef.backdropClick().subscribe(() => {
      this.onCancel();
    });
  }

  public ngAfterViewInit(): void {
    
  }

  /*
    Popup / data related
  */

  private getData(): CustomProductModalModel {
    return this.tempData$.getValue();
  }

  private setData(val: CustomProductModalModel) {
      this.tempData$.next(val);
  }

  // The data added on Modal creation (injected in the dialog from parent call)
  private getDialogData(): CustomProductModalModel {
    return this.data;
  }

  // Popup cancel
  public onCancel() {
    this.dialogRef.close();
  }

  /*
    Show All / Less in addons list
  */
  public onPressShowAllLess(addonGroupId: string) {
    const currentValue = this.showAllAddonsMap.has(addonGroupId) && !!this.showAllAddonsMap.get(addonGroupId);
    this.showAllAddonsMap.set(addonGroupId, !currentValue); // toggle
  }


  /*  
    Add-ons interaction
  */
  public onRadioAddonClick(addonId: string, addonGroupId: string) {
      this.handleAddonSelection(addonId, addonGroupId, "required");
  }

  public onCheckboxAddonClick($event: Event, addonId: string, addonGroupId: string) {
    $event.stopImmediatePropagation(); // stop any other listener - we only need the action once (this happens if user tapps on both parent and checkbox)
    this.handleAddonSelection(addonId, addonGroupId, "optional");
  }

  private handleAddonSelection(paramAddonId: string, paramAddonGroupId: string, usage: "required" | "optional") {
    const data = this.getData();
    let tempSelectedAddons = [...data.selectedAddons].map((item) => {
      const newItem = {...item}
      newItem.addonIdsList = [...newItem.addonIdsList];
      return {...newItem}
    });

    const itemFound = tempSelectedAddons.find((selection: SelectedAddon) => {        
      return (selection.addonGroupId === paramAddonGroupId) && !!selection.addonIdsList.find((a: string) => a === paramAddonId);
    });

    // if it doesn't exist, add it
    if (!itemFound) {
      // check if the group is already added with a list
      const groupAlreadyAdded = tempSelectedAddons.find((selection: SelectedAddon) => selection.addonGroupId === paramAddonGroupId);

      if (groupAlreadyAdded) {          
        // group added, means the item was the one missing, so we create it
        tempSelectedAddons = tempSelectedAddons.map((selection: SelectedAddon) => {
          if (selection.addonGroupId === paramAddonGroupId) {
            switch (usage) {
              case "required": {
                // We clean up all the other radios - we always keep 1 radio active
                selection.addonIdsList = [paramAddonId]; // replace all with 1 - as only 1 mandatory can be chosen
                break;
              }
              case "optional":
                // For checkbox, we just add it true on creation
                selection.addonIdsList.push(paramAddonId); // push an additional one
                break;
            }
          }
          return {...selection};
        });
      } else {
        // group doesn't exist, create new one and a new item too:
        const selection = new SelectedAddon();
        selection.addonGroupId = paramAddonGroupId;
        selection.addonIdsList = [paramAddonId]; // this works for both checkbox and radio in this case, as the group was not created          
        tempSelectedAddons.push(selection);
      }
    } else {
      // Item exists, so we need to switch the value
      tempSelectedAddons = tempSelectedAddons.map((selection: SelectedAddon) => {
        if (selection.addonGroupId === paramAddonGroupId) {
          switch (usage) {
            case "required": {
              // Here, it's redundant, but we just add it again. Because if it exists, it means it was already selected
              selection.addonIdsList = [paramAddonId];
              break;
            }
            case "optional":
              // For Checkbox, we remove it if existed
              selection.addonIdsList = selection.addonIdsList.filter((id) => id !== paramAddonId);
              break;
          }
        }
        return selection;
      });
    }

    // Check if we now have more than allowed addons
    const selected = tempSelectedAddons.find((s) => s.addonGroupId === paramAddonGroupId);
    const numberOfAddons = selected ? selected.addonIdsList.length : 0;
    if (numberOfAddons > (this.getAddonGroupById(paramAddonGroupId)?.maxSelection || 0)) {

      // scroll to the required one
      const element = document.getElementById(paramAddonGroupId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center"});
      }

      // trigger wiggle animation
      this.showMaxWiggleAnimation$.next(paramAddonGroupId);
      setTimeout(() => {
        this.showMaxWiggleAnimation$.next("");

        //  Just set again the initial state in order to update the checkboxes
        data.selectedAddons = [...data.selectedAddons];
        this.setData(data);
      }, 500);

      return; // skip
    }

    // setting the data
    data.selectedAddons = tempSelectedAddons;
    this.setData(data);
  }

  public isAddonSelected$(addonId: string, addonGroupId: string): Observable<boolean> {
    return this.tempData$.pipe(map((data) => {
      return !!data.selectedAddons.find((selection: SelectedAddon) => {        
        return selection.addonGroupId === addonGroupId && !!selection.addonIdsList.find((a: string) => a === addonId);
      })
    }));
  }

  public numberSelectedAddons(addonGroupId: string): number {
    const data = this.getData();
    const selected = data.selectedAddons.find((s) => s.addonGroupId === addonGroupId);
    return selected ? selected.addonIdsList.length : 0;
  }

  private getAddonGroupById(id: string): AddonGroupResponse | undefined {
    const data = this.getData();
    const group = data.addonGroups.find((s) => s.id === id);
    return group;
  }

  /*
    Bottom buttons actions
  */
 
  public onPressQuantityMinus() {
    const currentData = this.getData();
    if (currentData.quantity === 1) {
      return; // we cannot have 0 quantity
    }
    currentData.quantity -= 1;
    this.showScaleDownQuantityAnimation$.next(true);
    setTimeout(() => {
      this.showScaleDownQuantityAnimation$.next(false);
    }, 500);

    this.setData(currentData);
  }

  public onPressQuantityPlus() {
    const currentData = this.getData();
    currentData.quantity += 1;
    this.showScaleUpQuantityAnimation$.next(true);
    setTimeout(() => {
      this.showScaleUpQuantityAnimation$.next(false);
    }, 500);
    this.setData(currentData);
  }

  public onSpecialInstructionsUpdate(value: string) {
    const currentData = this.getData();
    currentData.specialInstructions = value;
    this.setData(currentData);
  }


  /*
      Add product / complete button
  */
  public onAddButtonPress() {
    const data = this.getData();	
    
    // Check if the required files have been filled-in

    const groupRequiredAndNotSelected = data.addonGroups.find((group) => {
      // if required
      if (group.minSelection > 0) {        
        const isSelected = data.selectedAddons.find((selected) => {
          return selected.addonGroupId === group.id && selected.addonIdsList.length >= group.minSelection;
        });
        return !isSelected;
      }
      return false;
    })

    if (groupRequiredAndNotSelected) {

      // scroll to the required one
      const element = document.getElementById(groupRequiredAndNotSelected.id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center"});
      }

      // do the blinking animation to focus on the needed field
      this.showRequiredWiggleAnimation$.next(groupRequiredAndNotSelected.id);
      // do the min. wiggle animation
      this.showMinWiggleAnimation$.next(groupRequiredAndNotSelected.id);
      setTimeout(() => {
        this.showRequiredWiggleAnimation$.next("");
        this.showMinWiggleAnimation$.next("");
      }, 500);
  
      /*
        Stop the entire flow - do not complete adding the product
      */
      return; 
    }
    

    // Build the order cart data
    
		const p = new OrderCartProductItem();		
		p.productId = data.product.id;
		p.productName = data.product.name; //TODO: if it's english / de
		p.productCategoryId = data.productCategoryId;
		p.productPrice = data.product.priceCents;
		p.productPriceDelivery = data.product.deliveryPriceCents;
		p.quantity = data.quantity;
		p.specialInstructions = data.specialInstructions;

    // go through each selected addon
    let newAddonsList: OrderCartAddonItem[] = [];

		data.selectedAddons.forEach((addon) => {

      // create a new Order format for each addon
      const tempList = addon.addonIdsList.map((id) => {
        const orderAddon = new OrderCartAddonItem();
        orderAddon.addonGroupId = addon.addonGroupId;
        orderAddon.addonId = id;

        let fullAddon: AddonResponse = new AddonResponse();
        const searchGroup = data.addonGroups.find((g: AddonGroupResponse) => {
          g.addonList.find((a: AddonResponse) => {
            if (a.id === id) {
              fullAddon = a;
            }
            return a.id === id
          });
        })

        orderAddon.addonName = fullAddon.name;
        orderAddon.addonPrice = fullAddon.priceCents;
        orderAddon.quantity = 1;
        return orderAddon;
      });

      newAddonsList = [...newAddonsList, ...tempList];      
    });

    p.addons = newAddonsList;

    // Check if the restaurant is closed => so we don't continue adding the product
    if (this.fullRestaurantService.checkIsClosed(data.restaurantId)) {
      this.fullRestaurantService.openClosedWarningPopup();
      
    } else {
      // add the product in the cart
      this.orderBasketService.addProduct(data.restaurantId, data.restaurantName, data.restaurantSlug, data.restaurantAddress, data.restaurantMinOrderAmount, p, data.supportsDelivery, data.supportsPickup);
    }

    // close the popup
    this.dialogRef.close();
  }


  /*
    Allergens 
  */

  public getFormattedAllergens(allergenList: AllergenEnum[], isProduct = true): string {
    let returnMsg = "";

    // no allergens added by the restaurant
    if (allergenList.length === 0) {      
      if (isProduct) {
        // product
        returnMsg = new LocaleTranslatePipe(this.localeService).transform("product_details_allergen_product_none");
      } else {
        // add-on
        returnMsg = new LocaleTranslatePipe(this.localeService).transform("product_details_allergen_addon_none");
      }      
    } else {
      // display all allergens
      allergenList.forEach((item, index) => {
        returnMsg += "(" + item + ") " + new LocaleTranslatePipe(this.localeService).transform(('restaurant_product_allergen_name_' + item.toLocaleLowerCase()));
        if (index < allergenList.length - 1) {
          returnMsg += ", "; // add a comma
        }
        if (index === allergenList.length - 1) {
          returnMsg += "."; // add a dot
        }
      })
    }

    // add the warning message too.
    returnMsg += " " + new LocaleTranslatePipe(this.localeService).transform("product_details_allergen_ask");

    return returnMsg;
  }
}