<div class="main-dashboard-container">

    <div class="main-dashboard-items">
        <!-- Search bar & filters -->
        <div class="search-and-filters">
            <div class="search-bar">

                <!-- Input field container -->
                <div class="input-field-container">

                    <!-- Search icon -->                
                    <div class="address-search-icon">
                        <img [src]="'./libs/shared-ui/assets/images/search-magnifying-glass.svg'" alt="Search for food"/>
                    </div>
                    
                    <!-- Input field -->
                    <input #restaurantsSearchField class="search-input" (input)="inputValueChanged()" type="text" placeholder="{{ 'dashboard_search_bar_placeholder' | translate }}">

                    <!-- Clear text (x) icon -->
                    <ng-container *ngIf="isClearButtonVisible$() | async">
                        <div class="address-clear-text-icon" (click)="onClearClick()">
                            <img [src]="'./libs/shared-ui/assets/images/clear-text-round-x.svg'" alt="Clear food search" title="{{ 'dashboard_search_bar_clear_placeholder' | translate }}"/>
                        </div>
                    </ng-container>
                </div>

            </div>

            <!-- Filters button-->
            <div class="filters">
                <div class="filter-box" (mousedown)="onFiltersClick()" [ngClass]="{'selected': (hasActivatedFilters$ | async)}">                
                    <div class="filter-box-text selected" [ngClass]="{'selected': (hasActivatedFilters$ | async)}">
                        {{ 'dashboard_filter_main_button' | translate }}
                    </div>
                    <div class="filter-box-icon">
                        <img class="filter-box-image" [src]="'apps/user-app/src/assets/main-filter.svg'"/>
                    </div>
                </div>
                <ng-container *ngIf="(hasActivatedFilters$ | async)">
                    <div class="categories-reset">
                        <span class="categories-reset-wide">- <span class="categories-reset-text" (mousedown)="onPressClearFilters()">{{ "dashboard_reset_all_button" | translate }}</span></span>                        
                        <span class="categories-reset-small" (mousedown)="onPressClearFilters()"><mat-icon>cancel</mat-icon></span>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Pre-defined cuisine Categories-->
        <div class="categories">
            <div class="category-title" [ngClass]="{'filter-active' : ((numberSelectedCuisines$() | async) || 0) > 0 }">
                {{ "dashboard_categories_title" |  translate }}
                <ng-container *ngIf="((numberSelectedCuisines$() | async) || 0) >= 1">
                    <div class="categories-reset">
                        - <span class="categories-reset-text" (mousedown)="onPressClearCategoryFilters()">{{ "dashboard_reset_button" | translate }}</span>
                    </div>
                </ng-container>
            </div>

            <!-- Categories List -->
            <div class="category-list">
                <!-- Item loop -->
                <ng-container *ngFor="let category of (categoriesList$ | async); index as i">                
                    <div class="category-item" (mouseup)="onPressCuisineCategory(category)" [ngClass]="{'opacity': category.hasOpacity}">
                        <!-- Image -->
                        <div class="category-image" [ngClass]="{'selected': category.selected, 'ml-1': category.selected && (i === 0)}">
                            <img class="category-image-src" [src]="category.imagePath"/>
                        </div>
                        <!-- X clear -->
                        <ng-container *ngIf="category.selected">
                            <div class="category-extra-clear-icon">
                                <mat-icon>cancel</mat-icon>
                            </div>                        
                        </ng-container>
                        <!-- Text -->
                        <div class="category-text" [ngClass]="{'selected': category.selected}">
                            {{ category.lokaKey | translate }}
                        </div>
                    </div>
                </ng-container>   
            </div>
        </div>

        <!-- Missing address -->
        <ng-container *ngIf="
            (userAddress$ | async)?.isTemporary && 
            (userAddress$ | async)?.latitude === 0 && 
            (userAddress$ | async)?.longitude === 0;else restaurantsList">
                            
            <div class="missing-address-container">
                <!-- Image -->
                <div class="missing-address-image">
                    <img [src]="'apps/user-app/src/assets/missing-address.jpg'"/>
                </div>

                <!-- Texts -->
                <div class="missing-address-text-container">
                    <!-- Title -->
                    <div class="missing-address-title">
                        <!-- Image (mobile) -->
                        <div class="missing-address-image-mobile">
                            <img [src]="'apps/user-app/src/assets/missing-address.jpg'"/>
                        </div>
                        <div>{{ "dashboard_missing_address_title" |  translate }}</div>
                        <img class="missing-address-title-image-src" [src]="'apps/user-app/src/assets/address-pin-google-map.png'"/>
                    </div>
                    <!-- Body -->
                    <div class="missing-address-body">
                        {{ "dashboard_missing_address_body" |  translate }}
                    </div>
                    <!-- Button -->
                    <div class="missing-address-button">
                        <!-- Button - Create product -->
                        <fs-button [text]="'dashboard_missing_address_button' | translate"
                                [type]="'raised-primary'" [leadingIcon]="'add'" (onPress)="onPressMissingAddress()">
                        </fs-button>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- Restaurants list -->
        <ng-template #restaurantsList>
            <div class="restaurants" *ngIf="(searchResults$ | async)">
                <div class="category-title">
                    {{ "dashboard_restaurants_title" |  translate }}
                </div>

                <!-- Empty list of restaurants -->
                <ng-container *ngIf="((searchResults$ | async)?.length || 0) === 0; else resultsList">
                    <div class="missing-restaurants-container">
                        <!-- Image -->
                        <div class="missing-address-image">
                            <img [src]="'apps/user-app/src/assets/missing-restaurant.jpg'"/>
                        </div>
            
                        <!-- Texts -->
                        <div class="missing-address-text-container">
                            <!-- Title -->
                            <div class="missing-address-title">
                                <!-- Image (mobile) -->
                                <div class="missing-address-image-mobile">
                                    <img [src]="'apps/user-app/src/assets/missing-restaurant.jpg'"/>
                                </div>     
                                <!-- No results found -->    
                                <ng-container *ngIf="(hasActivatedFilters$ | async)">
                                    {{ "dashboard_missing_restaurants_title" |  translate }}
                                </ng-container>
                                <!-- No available restaurants-->
                                <ng-container *ngIf="(hasActivatedFilters$ | async) === false">
                                    {{ "dashboard_missing_restaurants_delivery_title" |  translate }}
                                </ng-container>
                                
                            </div>
                            <!-- Body -->
                            <div class="missing-address-body">
                                <!-- Try changing the filters -->
                                <ng-container *ngIf="(hasActivatedFilters$ | async)">
                                    {{ "dashboard_missing_restaurants_body" |  translate }}
                                </ng-container>
                                <!-- Try changing the delivery option -->
                                <ng-container *ngIf="(hasActivatedFilters$ | async) === false">
                                    {{ "dashboard_missing_restaurants_delivery_body" |  translate }}
                                </ng-container>                                
                            </div>
                            <!-- Button -->
                            <div class="missing-address-button">
                                <!-- Clear filter-->
                                <ng-container *ngIf="(hasActivatedFilters$ | async)">
                                    <fs-button [text]="'dashboard_missing_restaurants_button' | translate"
                                        [type]="'raised'" [leadingIcon]="'clear'" (onPress)="onPressClearFilters()">
                                    </fs-button>
                                </ng-container>
                                <!-- Try pick-up / delivery -->
                                <ng-container *ngIf="(hasActivatedFilters$ | async) === false">
                                    <!-- Try pick-up -->
                                    <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.DELIVERY"> 
                                        <fs-button [text]="'dashboard_missing_restaurants_try_pickup' | translate"
                                            [type]="'raised'"  [leadingIconUrl]="'delivery-type-pick-up-hand.svg'" (onPress)="onPressTryPickup()">
                                        </fs-button>
                                    </ng-container>
                                    <!-- Try Delivery -->
                                    <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.PICKUP"> 
                                        <fs-button [text]="'dashboard_missing_restaurants_try_delivery' | translate"
                                            [type]="'raised'" [leadingIconUrl]="'delivery-type-bicycle.svg'" (onPress)="onPressTryDelivery()">
                                        </fs-button>
                                    </ng-container>
                                </ng-container>                                
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- List with results -->
                <ng-template #resultsList>
                    <div class="restaurants-list">
                        <!-- Restaurant search results -->
                        <ng-container *ngFor="let restaurant of (searchResults$ | async)">
                                    
                            <a class="restaurant-item" [href]="'restaurant/' + restaurant.slug">
                
                                    <!-- Images -->
                                    <div class="images-container" [ngClass]="{'isClosed' : restaurant.closed || isOutsideBusinessHours(restaurant)}">

                                        <!-- New Badge -->
                                        <ng-container *ngIf="restaurant.new && (!restaurant.closed && !isOutsideBusinessHours(restaurant)) ">                                        
                                            <div class="restaurant-new-badge">
                                                <img class="restaurant-new-badge-src" [src]="'apps/user-app/src/assets/badge-new.svg'"/>
                                                <div class="restaurant-new-badge-text">{{ 'restaurant_badge_new' | translate }}</div>
                                            </div>
                                        </ng-container>      

                                        <!-- Logo image -->
                                        <div class="restaurant-logo-image-container" [ngClass]="{'isClosed' : restaurant.closed || isOutsideBusinessHours(restaurant)}">
                                            <div class="restaurant-logo-image">
                                                <img class="restaurant-logo-image-src" [src]="restaurant.logoImageUrl"/>
                                            </div>
                                        </div>
                                        

                                        <!-- Background image -->
                                        <div class="restaurant-background-image" [ngClass]="{'isClosed' : restaurant.closed || isOutsideBusinessHours(restaurant)}">      
                                            <ng-container *ngIf="restaurant.mainImageUrl; else restaurantBackgroundMissing">
                                                <img class="restaurant-background-image-src" [src]="restaurant.mainImageUrl"/>
                                            </ng-container>    
                                            <ng-template #restaurantBackgroundMissing>
                                                <div class="restaurant-image-missing-container">
                                                    <div class="restaurant-missing-default-foodis">
                                                        {{ restaurant.name }}
                                                    </div>
                                                </div>
                                            </ng-template>                          
                                            
                                        </div>

                                        <!-- Closed message -->
                                        <ng-container *ngIf="restaurant.closed || isOutsideBusinessHours(restaurant)">
                                            <div class="restaurant-closed-text">
                                                <ng-container *ngIf="restaurant.closed">
                                                    <div>{{ 'restaurant_currently_closed' | translate }}</div>  
                                                </ng-container>                                         
                                                                                            
                                                <!-- Open, but not available due to opening hours-->
                                                <ng-container *ngIf="!restaurant.closed && isOutsideBusinessHours(restaurant)">
                                                    <ng-container *ngIf="!!getNextAvailableHours(restaurant)">
                                                        <div>{{ 'restaurant_closed_until' | translate }} </div>     
                                                    </ng-container>
                                                    <!-- Next available date -->     
                                                    <ng-container *ngIf="!!getNextAvailableHours(restaurant); else nextDateNotAvailable">
                                                        <div>{{ getNextAvailableHours(restaurant) }}</div>  
                                                    </ng-container>
                                                    <!-- Missing next date -->
                                                    <ng-template #nextDateNotAvailable>
                                                        <div> 
                                                            <ng-container *ngIf="(deliveryOption$ | async) === DeliveryOption.DELIVERY; else pickUpNotAvailable">
                                                                {{ 'restaurant_hours_not_available_delivery' | translate }}
                                                            </ng-container>
                                                            <ng-template #pickUpNotAvailable>
                                                                {{ 'restaurant_hours_not_available_pickup' | translate }}
                                                            </ng-template>
                                                        </div>
                                                    </ng-template>
                                                </ng-container>                                            
                                            </div>
                                        </ng-container>
                                    </div>
                                    
                                    <!-- Restaurant name + Star Rating -->
                                    <div class="text-row-1">
                                        <!-- Name -->
                                        <div class="restaurant-name">
                                            {{ restaurant.name }}
                                            <!-- Rating -->
                                            <div class="flex items-center ml-2">                                        
                                                <img class="restaurant-distance-src" [src]="'apps/user-app/src/assets/star-review.svg'"/>
                                                <div class="restaurant-distance-text">
                                                    4.5
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Distance to my address -->
                                        <div class="restaurant-distance">
                                            <img class="restaurant-distance-src" [src]="'apps/user-app/src/assets/distance-pinpoint.svg'"/>
                                            <div class="restaurant-distance-text">
                                                {{ distanceToMyAddress(restaurant) | format_distance }}
                                            </div>
                                        </div>
                                    </div>
                
                                    <!-- Delivery time + Cuisine -->
                                    <div class="text-row-2">
                                        <!-- Delivery time -->
                                        <div class="delivery-time-container">
                                            <div class="delivery-time-icon">
                                                <img class="delivery-time-icon-src" [src]="'apps/user-app/src/assets/time-hours.svg'"/>
                                            </div>
                                            <div class="delivery-time-text">
                                                {{ ("restaurant_delivery_time_" + restaurant.deliveryTimeRange.toLowerCase()) | translate }}    
                                            </div>                            
                                        </div>
                                        <!-- Cuisine category -->
                                        <div class="restaurant-cuisine-list">
                                            <ng-container *ngFor="let cuisine of restaurant.categoryList; let i = index">                                
                                                {{ ("restaurant_info_category_" + cuisine.toLowerCase()) | translate }}<ng-container *ngIf="i < restaurant.categoryList.length - 1">,</ng-container>
                                            </ng-container>        
                                        </div>
                                    </div>
                            </a>
                        </ng-container>    
                    </div>  
                </ng-template>
            </div>
        </ng-template>    

    </div>
    
    <!-- To fix before somehow the height when the restaurants list gets bigger -->
    <!-- <made-in-vienna></made-in-vienna> -->
</div>


