import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'button-delivery-type',
  templateUrl: './button-delivery-type.component.html',
  styleUrls: ['./button-delivery-type.component.scss'],
})
export class ButtonDeliveryTypeComponent implements OnInit {
    @Input() text: string = "";
    @Input() icon: string = "";
    @Input()
    public set state(s: string) {
        this._state = s;
        this.updateIconPath();
    }
    public get state(): string {
        return this._state;
    }

    private _state: string = "";

    @Output() onPress: EventEmitter<any> = new EventEmitter<any>();

    public iconPath: BehaviorSubject<string> = new BehaviorSubject<string>("");

    public ngOnInit() {
       this.updateIconPath();
    }

    private updateIconPath() {
        let iconState = this.icon + (this.state === 'selected' ? "-" + this.state : "");
        let path = 'apps/user-app/src/assets/' + iconState + '.svg';
        this.iconPath.next(path);
    }

    public onClick() {
        this.onPress.emit();
    }
}
