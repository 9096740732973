import {Component, Input} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'user-side-menu',
  templateUrl: './user-side-menu.component.html',
  styleUrls: ['./user-side-menu.component.scss'],
})
export class UserSideMenuComponent {
    public menuList: BehaviorSubject<SideMenuItem[]> = new BehaviorSubject<SideMenuItem[]>(MENU_LIST);

    @Input() set selected(value: string) {
        if (!!value) {
            let list = this.menuList.getValue();
            let foundItem = list.find((i) => i.id === value);
            if (!!foundItem) {
                list = list.map((i) => {
                    i.selected = i.id === value;
                    return i;
                })
                this.menuList.next(list);
            }
        }
    }
}

export class SideMenuItem {
    public id: string = "";
    public key: string = "";
    public selected: boolean = false;
    public icon: string = "";
    public navRoute: string = "";
}

export const MENU_LIST: SideMenuItem[] = [ {
    id: "HOME",
    key: "nav_bar_menu_home",
    selected: false,
    icon: "home",
    navRoute: "/dashboard"
}, {
    id: "PROFILE",
    key: "nav_bar_menu_profile",
    selected: false,
    icon: "person_outline",
    navRoute: "/user/profile"
}, {
    id: "ORDERS",
    key: "nav_bar_menu_orders",
    selected: false,
    icon: "list",
    navRoute: "/user/orders"
}, {
    id: "ADDRESS",
    key: "nav_bar_menu_address",
    selected: false,
    icon: "location_on",
    navRoute: "/user/address"
},  {
    id: "PAYMENT",
    key: "nav_bar_menu_payment",
    selected: false,
    icon: "payment",
    navRoute: "/user/payment"
}];