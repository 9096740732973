import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedServicesModule } from '@web-foodis/shared-services';
import { SharedUiModule } from '@web-foodis/shared-ui';
import { environment } from '../environments/environment';
import { LogoUserAppComponent } from './components/logo-user-app/logo-user-app.component';
import { UserDashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginRedirectComponent } from './pages/login-redirect/login-redirect.component';
import { TopNavBarComponent } from './components/top-nav-bar/top-nav-bar.component';
import { ButtonDeliveryTypeComponent } from './components/button-delivery-type/button-delivery-type.component';
import { AddressEditorComponent } from './components/address-editor/address-editor.component';
import { CommonModule } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { SharedModelsModule } from '@web-foodis/shared-models';
import { FiltersModalComponent } from './pages/dashboard/modals/filters-modal/filters-modal.component';
import { MadeInViennaComponent } from './components/made-in-vienna/made-in-vienna.component';
import { RestaurantPageComponent } from './pages/restaurant-page/restaurant-page.component';
import { MoreRestaurantInfoModalComponent } from './pages/restaurant-page/modals/more-restaurant-info/more-restaurant-info.component';
import { ProductDetailsModalComponent } from './pages/restaurant-page/modals/product-details-modal/product-details-modal.component';
import { OrderBasketComponent } from './components/order-basket/order-basket.component';
import { BottomBasketButtonComponent } from './components/bottom-basket-button/bottom-basket-button.component';
import { CheckoutPageComponent } from './pages/checkout/checkout-page.component';
import { StripeCheckoutComponent } from './pages/stripe-checkout/stripe-checkout.component';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { OrdersListComponent } from './pages/orders-list/orders-list.component';
import { UserItemSideMenuComponent } from './components/user-side-items/user-item-side-menu/user-item-side-menu.component';
import { UserSideMenuComponent } from './components/user-side-items/user-side-menu/user-side-menu.component';
import { UserSectionComponent } from './pages/user-section/user-section.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { AddressPageComponent } from './pages/address-page/address-page.component';
import { UserPaymentPageComponent } from './pages/payment-page/payment-page.component';
import { DeliveryAddressComponent } from './components/delivery-address/delivery-address.component';
import { PickUpAddressComponent } from './components/pick-up-address/pick-up-address.component';
import { VerticalStatusProgressComponent } from './components/vertical-status-progress/vertical-status-progress.component';

@NgModule({
  declarations: [
    AppComponent,
    UserDashboardComponent,
    LogoUserAppComponent,
    LoginRedirectComponent,
    TopNavBarComponent,
    ButtonDeliveryTypeComponent,
    AddressEditorComponent,
    FiltersModalComponent,
    MoreRestaurantInfoModalComponent,
    MadeInViennaComponent,
    RestaurantPageComponent,
    CheckoutPageComponent,
    ProductDetailsModalComponent,
    OrderBasketComponent,
    BottomBasketButtonComponent,
    StripeCheckoutComponent,
    OrderPageComponent,
    OrdersListComponent,
    UserSectionComponent,
    UserItemSideMenuComponent,
    UserSideMenuComponent,
    ProfilePageComponent,
    AddressPageComponent,
    UserPaymentPageComponent,
    DeliveryAddressComponent,
    PickUpAddressComponent,
    VerticalStatusProgressComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    BrowserAnimationsModule,
    SharedServicesModule.forRoot(environment),
    SharedUiModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  providers: [SharedServicesModule, SharedModelsModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
